import Config from 'config/config';
import logger from 'middleware/logger';
import './ErrorBoundaryFallback.sass';

declare interface ErrorBoundaryFallbackProps {
    error: Error;
    resetErrorBoundary: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const { isDevelopment } = Config;

function ErrorBoundaryFallback({ error, resetErrorBoundary }: ErrorBoundaryFallbackProps) {
    return (
        <div className='eb-fallback-container' role="alert">
            <div>
                <h1>Something went wrong!</h1>
                {isDevelopment ? (
                    <pre>{error.message}</pre>
                ) :(
                    <p>Please contact an administrator if this error persists.</p>
                )}
                <button className="btn btn-primary" onClick={resetErrorBoundary}>Try again</button>
            </div>
        </div>
    );
}

const ErrorBoundaryHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
    logger.error(error, info);
};

export { ErrorBoundaryHandler, ErrorBoundaryFallback as default };

