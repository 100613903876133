export const DEVICES_GET_ALL_SUCCESS = 'DEVICES_GET_ALL_SUCCESS';
export const DEVICES_GET_ALL_FAILURE = 'DEVICES_GET_ALL_FAILURE';
export const DEVICES_DATA_IS_LOADING = 'DEVICES_DATA_IS_LOADING';

export const DEVICES_TABLE_GET_ALL_SUCCESS = 'DEVICES_TABLE_GET_ALL_SUCCESS';
export const DEVICES_TABLE_GET_ALL_FAILURE = 'DEVICES_TABLE_GET_ALL_FAILURE';
export const DEVICES_TABLE_DATA_IS_LOADING = 'DEVICES_TABLE_DATA_IS_LOADING';

export const DEVICES_PER_DAY_GET_ALL_SUCCESS = 'DEVICES_PER_DAY_GET_ALL_SUCCESS';
export const DEVICES_PER_DAY_GET_ALL_FAILURE = 'DEVICES_PER_DAY_GET_ALL_FAILURE';
export const DEVICES_PER_DAY_DATA_IS_LOADING = 'DEVICES_PER_DAY_DATA_IS_LOADING';
