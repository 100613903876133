import { useMemo } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import ContentLoaderSkeleton from '@components/skeletons/content-loader-skeleton/ContentLoaderSkeleton';
import Loader from '@components/widgets/loader/Loader';
import { getIsTablet } from '@constants/media';

import './PageSuspense.scss';

interface Props {
    SkeletonBuilder: (props: { parentSize:  { width: number; height: number } }) => JSX.Element;
}

const PageSuspense = ({ SkeletonBuilder }: Props) => {
    const [squareRef, wrapperSize] = useElementSize<HTMLDivElement>();
    const windowSize               = useWindowSize();
    const switchToMobile           = useMemo(() => getIsTablet(), [windowSize.width, windowSize.height]);

    const LoaderNode = useMemo(() => (
        switchToMobile ? (
            <div className="page-skeleton-mobile">
                <Loader />
            </div>
        ) : (
            <ContentLoaderSkeleton
                speed={1.5}
                height='100%'
                width='100%'
                // foregroundColor="#EEF1FA"
                foregroundColor="#FFF0F0"
            >
                <SkeletonBuilder parentSize={wrapperSize} />
            </ContentLoaderSkeleton>
        )
    ), [switchToMobile, wrapperSize]);

    return (
        <div className="page-skeleton" ref={squareRef}>
            {LoaderNode}
        </div>
    );
};

export default PageSuspense;
