import classNames from 'classnames';
import { ComponentProps, useMemo, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import Each from '@components/common/condition/Each';
import Show from '@components/common/condition/Show';
import { getIsMobile } from '@constants/media';

import { TabsViewItem, type TabsViewItemProps } from './';
import './TabsView.scss';

export type TabsViewItemType = Omit<TabsViewItemProps, 'onClick' | 'isActive'>;
interface Props {
    headerIcon?: JSX.Element;
    headerText?: string;

    navItems: TabsViewItemType[];
    navViews: { [id: string]: (props?: ComponentProps<any>) => JSX.Element };

    initialActiveItemId?: string;
    onActiveItemChange: (itemId: string) => void;

    className?: string;
}

const TabsView = ({ navItems, navViews, headerIcon, headerText, className, initialActiveItemId, onActiveItemChange }: Props) => {
    const [activeElementID, setActiveElementID] = useState(initialActiveItemId ?? navItems[0].id);
    const size                                  = useWindowSize();
    const shouldSwitchHeaderLocation            = useMemo(() => getIsMobile(), [size]);

    const handleActiveElementChange = (id) => {
        setActiveElementID(id);
        onActiveItemChange(id);
    };

    const View = navViews[activeElementID];

    return (
        <div className={classNames('tabs-container', { [className]: !!className })}>
            <Show.When isTrue={shouldSwitchHeaderLocation}>
                <TabHeader headerIcon={headerIcon} headerText={headerText} />
            </Show.When>
            <nav>
                <Show.When isTrue={!shouldSwitchHeaderLocation}>
                    <TabHeader headerIcon={headerIcon} headerText={headerText} />
                </Show.When>
                <Show.When isTrue={navItems?.length>0}>
                    <Each of={navItems} render={(item) => (
                        <TabsViewItem
                            {...item}
                            isActive={item.id === activeElementID}
                            onClick={handleActiveElementChange}
                        />
                    )}/>
                </Show.When>
            </nav>
            <div className='tabs-separator'/>
            <div className="tabs-view">
                <View />
            </div>
        </div>
    );
};

interface TabHeaderProps {
    headerIcon?: JSX.Element;
    headerText?: string;
}

const TabHeader = ({ headerIcon, headerText }: TabHeaderProps) => (
    <div className='tabs-header'>
        {headerIcon && headerIcon}
        {headerText && <h5>{headerText}</h5>}
    </div>
);

export default TabsView;
