import React, { useMemo } from 'react';

import CompareStats from 'components/compare-stats/CompareStats';
import { symbolizeNumber } from 'services/conversion/conversion';

interface Props {
    titleImg?: string;
    newTitle: string | React.ReactNode;
    shouldShowPercentage?: boolean;
    percentResult: string | number;
    pastPercentageOrValue?: number;
    number?: string | number;
    switchNumberAndPercentage?: boolean;
    titleBottomMargin?: string;
    compare?: { percentage: number; diff?: number };
    hideDiff?: boolean;
}

const ProgressIndicator = ({
    titleImg,
    newTitle,
    percentResult,
    pastPercentageOrValue,
    number,
    switchNumberAndPercentage,
    titleBottomMargin,
    compare,
    hideDiff,
    shouldShowPercentage = true
}: Props) => {
    const percent = useMemo(() => {
        const num = String(percentResult).split('%')?.[0];
        const res = Number(num);

        return res;
    }, [percentResult]);

    return (
        <div
            className="progress-indicator"
            style={{ marginBottom: titleBottomMargin }}
        >
            <div className="progress-title">
                {titleImg && <img src={titleImg} alt='title img' />}
                <p>{newTitle}</p>
            </div>
            <div className="progress-value">
                {
                    switchNumberAndPercentage ? (
                        <>
                            <h4>{symbolizeNumber(parseInt(number.toString()), 1, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true })}</h4>
                            {shouldShowPercentage && number.toString() && (
                                <small data-testid="progress-number">
                                    ({`${percent}%`})
                                </small>
                            )}
                        </>
                    ) : (
                        <>
                            {shouldShowPercentage && <h4>{`${percent}%`}</h4>}
                            {number.toString() && (
                                <small data-testid="progress-number">
                                        ({symbolizeNumber(parseInt(number.toString()), 1, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true })})
                                </small>
                            )}
                        </>
                    )
                }

                {
                    compare && (
                        <CompareStats
                            percentage={compare.percentage}
                            pastPercentageOrValue={pastPercentageOrValue}
                            diff={compare.diff}
                            hideDiff={hideDiff}
                            percentageStyle={{ fontSize: '0.7rem' }}
                            iconStyle={{ marginTop: '3px' }}
                            diffStyle={{ fontSize: '0.45rem', marginBottom: '2px' }}
                            wrapperStyle={{ marginTop: '-2px', marginLeft: '7px', minWidth: 'unset', width: 'fit-content', maxWidth: 'unset' }}
                        />
                    )
                }
            </div>
        </div>
    );
};

ProgressIndicator.defaultProps = {
    number: 0,
    titleBottomMargin: '',
};

export default ProgressIndicator;
