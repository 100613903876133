import classNames from 'classnames';
import { useState } from 'react';

import PrimaryButton from '@components/common/buttons/primary-button/PrimaryButton';
import Show from '@components/common/condition/Show';
import { InputWithLabel } from '@components/common/inputs';
import { CheckedRounded } from '@components/svg/common';
import { AlertError, AlertSuccess } from '@components/widgets/alert';
import useInputState from '@hooks/useInputState';

import { profileSettingsApi } from '../profileSettings.service';
import './ProfileSettingsPasswordForm.scss';

interface Props {
    onComplete: (status: 'success' | 'error', message: string) => void;
}

const getHasMinCharacters = (string: string, min: number) => string.length >= min;
const getHasUppercase     = (string: string) => !!string.split('').find(
    (char) => char !== char.toLowerCase()
);
const getHasLowercase     = (string: string) => !!string.split('').find(
    (char) => char !== char.toUpperCase()
);

const ProfileSettingsPasswordForm = ({ onComplete }: Props) => {
    const [isLoading, setLoader]                          = useState(false);
    const [currentPassword, onChangeCurrentPassword]      = useInputState('');
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPassword, onChangeNewPassword]              = useInputState('');
    const [newPasswordBlured, setNewPasswordBlured]       = useState(false);
    const [confirmPassword, onChangeConfirmPassword]      = useInputState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [submitStatus, setSubmitStatus]                 = useState<{status: 'success' | 'error'; message: string}>(null);

    const onSubmit         = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoader(true);
        profileSettingsApi.updatePassword({
            currentPassword,
            newPassword,
        }).then((result) => {

            if (!result.success) {
                // onComplete('error', result.error || "Couldn't update password, please try again!");
                setSubmitStatus({
                    status: 'error',
                    message: result.error || "Couldn't update password, please try again!"
                });
                setCurrentPasswordError(result.error === 'Current password invalid!' ? 'INVALID' : null);

                return;
            }

            onComplete('success', 'Your Indepth password has been successfully updated.');
        })
            .finally(() => {
                setLoader(false);
            });
    };

    const hasMinCharacters = getHasMinCharacters(newPassword, 8);
    const hasUppercase     = getHasUppercase(newPassword);
    const hasLowercase     = getHasLowercase(newPassword);
    const passwordsMatch   = newPassword === confirmPassword;

    return (
        <form onSubmit={onSubmit} className='ps-password-form'>
            <InputWithLabel
                error={currentPasswordError}
                showError={currentPasswordError === 'INVALID' ? false:true}
                type="password"
                title='Current password'
                value={currentPassword}
                onChange={onChangeCurrentPassword}
                placeholder='Please enter your current password'
                onFocus={() => setCurrentPasswordError('')}
                onBlur={() => {
                    if (!currentPassword) {
                        setCurrentPasswordError("Field can't be empty!");
                    }
                }}
            />
            <div className="ps-new-password">
                <InputWithLabel
                    // error={(newPasswordBlured && (!hasMinCharacters || !hasUppercase || !hasLowercase)) ? 'error' : ''}
                    // showError={false}
                    type="password"
                    title='New password'
                    placeholder='Please enter your new password'
                    value={newPassword}
                    onBlur={() => setNewPasswordBlured(true)}
                    onFocus={() => setNewPasswordBlured(false)}
                    onChange={onChangeNewPassword}
                />
                <div className="ps-conditions">
                    <ConditionItem blured={newPasswordBlured} checked={hasMinCharacters} title="8 characters min."/>
                    <ConditionItem blured={newPasswordBlured} checked={hasUppercase} title="1 uppercase"/>
                    <ConditionItem blured={newPasswordBlured} checked={hasLowercase} title="1 lowercase"/>
                </div>
            </div>
            <InputWithLabel
                error={confirmPasswordError}
                type="password"
                title='Confirm new password'
                placeholder='Please re-enter your new password'
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                onFocus={() => setConfirmPasswordError('')}
                onBlur={() => {
                    if (newPassword !== confirmPassword) {
                        setConfirmPasswordError('Passwords do not match');
                    }
                }}
            />
            <PrimaryButton
                disabled={!hasMinCharacters || !hasUppercase || !hasLowercase || !passwordsMatch}
                isLoading={isLoading}
                type="submit"
            >
                Change password
            </PrimaryButton>

            <Show.When isTrue={submitStatus?.status === 'success'}>
                <AlertSuccess message={submitStatus?.message}/>
            </Show.When>
            <Show.When isTrue={submitStatus?.status === 'error'}>
                <AlertError message={submitStatus?.message} />
            </Show.When>
        </form>
    );
};

interface ItemProps {
    title: string;
    checked: boolean;
    blured: boolean;
}

const ConditionItem = ({ title, checked, blured }: ItemProps) => (
    <article className={classNames({ checked, blured })}>
        <CheckedRounded />
        <p>{title}</p>
    </article>
);

export default ProfileSettingsPasswordForm;
