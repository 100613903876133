export const LANGUAGES_GET_TABLE_SUCCESS = 'LANGUAGES_GET_TABLE_SUCCESS';
export const LANGUAGES_GET_TABLE_FAILURE = 'LANGUAGES_GET_TABLE_FAILURE';
export const LANGUAGES_GET_TABLE_IS_LOADING = 'LANGUAGES_GET_TABLE_IS_LOADING';

export const LANGUAGES_GET_PER_DAY_SUCCESS = 'LANGUAGES_GET_PER_DAY_SUCCESS';
export const LANGUAGES_GET_PER_DAY_FAILURE = 'LANGUAGES_GET_PER_DAY_FAILURE';
export const LANGUAGES_GET_PER_DAY_IS_LOADING = 'LANGUAGES_GET_PER_DAY_IS_LOADING';

export const LANGUAGES_GET_BY_PAGE_VIEW_SUCCESS = 'LANGUAGES_GET_BY_PAGE_VIEW_SUCCESS';
export const LANGUAGES_GET_BY_PAGE_VIEW_FAILURE = 'LANGUAGES_GET_BY_PAGE_VIEW_FAILURE';
export const LANGUAGES_GET_BY_PAGE_VIEW_IS_LOADING = 'LANGUAGES_GET_BY_PAGE_VIEW_IS_LOADING';
