
import notFoundAnimation from '@assets/lotties/404-no-found.json';
import ReactLottie from '@widgets/react-lottie/ReactLottie';

import './NotFoundAnimation.scss';

const NotFoundAnimation = () => (
    <div className="not-found-animation">
        <ReactLottie
            loop
            autoplay
            animationData={notFoundAnimation}
        />
    </div>
);

export default NotFoundAnimation;
