import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.ticker.lagSmoothing(0);
// gsap.registerPlugin(ScrollTrigger);

// ScrollTrigger.defaults({ scroller: document.body });

export * from 'gsap';
// export { ScrollTrigger };

