import type { CSSProperties } from 'react';

import './Loader.sass';

export declare interface LoaderProps {
    /**
     * The number of loading dots to display(default is 4).
     *
     * Condition: 2 <= nbDots => 5
     */
    nbDots?: number;
    /**
     * The width of each dot in px(default is 15px).
     */
    width?: number;
    /**
     * The height of each dot in px(default is 15px).
     */
    height?: number;

    dotsContainerStyle?: CSSProperties;
}

const Loader = ({ dotsContainerStyle, nbDots, width, height }: LoaderProps) => {
    const numberOfDots = getFinalNumberOfLoadingDots(nbDots);

    return (
        <div className='lw-loader' style={dotsContainerStyle}>
            {
                new Array(numberOfDots)
                    .fill(null)
                    .map((_, index) => (
                        <span key={index} style={{ width: `${width}px`, height: `${height}px` }}>

                        </span>
                    ))
            }
        </div>
    );
};

const getFinalNumberOfLoadingDots = (nbDots) => {
    const isSuperior = nbDots > 5 ? 5 : nbDots;

    return nbDots < 2 ? 2 : isSuperior;
};

Loader.defaultProps = {
    nbDots: 5,
    width: 15,
    height: 15,
};

export default Loader;
