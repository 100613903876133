/* eslint-disable @typescript-eslint/naming-convention */
import { type SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import {
    type SocialMediaSingleActiveProfiles,
    type SocialMediaSingleEngagement,
    type SocialMediaSingleInteractionByPost,
    type SocialMediaSinglePostFormat,
    type SocialMediaSinglePostsList,
    type SocialMediaSingleReach,
    type SocialMediaSingleTopAndWeakPosts
} from '@shared/interfaces/social-media';

import { SocialMediaAPIClient } from '@services/axios/clients/socialMediaAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';

interface SocialMediaSingleParams {
    id: string;
    start_date: string;
    end_date: string;
    compareFrom: string;
    compareTo: string;
    client_id: string;
}

interface HighlightItem {
    total: string;
    percentage: string;
    difference: string;
}

interface PostActivityItem {
    [name: string]: {
        compareDate: string;
        date1: number;
        date2: number;
        difference: number;
        percentage: number;
    };
}

export interface PostActivityThunk {
    [date: string]: PostActivityItem | null;
}

export interface PostInteractionItem {
    [date: string]: {
        compareDate: string;
        date1: number;
        date2: number;
        difference: number;
        percentage: number;
    };
}

export interface PostInteractionThunk {
    comments: PostInteractionItem[];
    'link video clicks': PostInteractionItem[];
    reactions: PostInteractionItem[];
    shares: PostInteractionItem[];
    [name: string]: PostInteractionItem[];
}

interface SocialMediaSingleReturn {
    networkType: SocialMediaTypes;
    profileName: string;
    url: string;
    followers: HighlightItem;
    likes: HighlightItem;
    posts: HighlightItem;

    postInteraction: HighlightItem & { sparklines: string[] };
    postPerDay: HighlightItem & { sparklines: string[] };
    engagementRate: HighlightItem & { sparklines: string[] };
    followerGrowth: HighlightItem & { sparklines: string[] };

    postFormat: SocialMediaSinglePostFormat;
    postActivity: PostActivityThunk;
    interaction: PostInteractionThunk;
    interactionByPost: SocialMediaSingleInteractionByPost;
    topAndWeakPosts: SocialMediaSingleTopAndWeakPosts;
    postList: SocialMediaSinglePostsList;
    engagement: SocialMediaSingleEngagement;
    reach: SocialMediaSingleReach;
}

export const fetchSocialMediaSingle = createAsyncThunkWithClient<SocialMediaSingleReturn, SocialMediaSingleParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getSingle<SocialMediaSingleParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaSingle/all'
});

interface FetchSocialMediaSingleActiveProfilesParams {
    clientId: string;
}

export const fetchSocialMediaSingleActiveProfiles = createAsyncThunkWithClient<SocialMediaSingleActiveProfiles, FetchSocialMediaSingleActiveProfilesParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getActiveProfiles<SocialMediaSingleActiveProfiles>(
        params.clientId,
        config
    ),
    typePrefix: 'socialMediaSingle/getActiveProfiles'
});
