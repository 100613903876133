import { ComparisonColors } from './comparison';

export const CHART_TOOLTIP_STYLE_1 = {
    header: `
        margin-bottom: 10px;
        font-size: .7rem;
        font-weight: 400;
        color: rgba(79, 78, 78, 1);
    `,
    content: `
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8rem;
        margin-bottom: 10px;
    `,
    value: `
        margin-right: 20px;
        font-size: 1.2rem;
    `,
    compare: {
        wrapper: (color) => `
            color: ${color}; 
            font-size: .65rem;
        `,
        remaining: `
            font-size: .6rem;
        `,
    },
    footer: `
        font-weight: 400;
        font-size: .7rem;
        color: rgba(60, 60, 67, 0.6);
        font-family: Poppins;
    `,
};

export const CHART_TOOLTIP_STYLE_2 = {
    contentWrapper: `
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    `,
    header: `
        font-weight: 400;
        font-size: .7rem;
        color: rgba(79, 78, 78, 1);
        margin-bottom: 10px;
    `,
    content: `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.6rem;
        margin-bottom: 3px;
    `,
    contentTitle: `
        color: #8A8A8E;
        font-size: 0.65rem;
        font-weight: 300;
        max-width: 125px;
        width: fit-content;
        min-width: 50px;
        text-align: left;
    `,
    value: `
        margin-right: 5px;
        text-align: right;
    `,
    difference: `
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: right;
        margin-top: 5px;
    `,
    footer: `
        font-weight: 400;
        font-size: 1rem;
        color: #3C3C43;
        opacity: .8;
    `,
    differenceLogo: `
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-bottom: 2px;
    `,
    neutralLogo: `
        height: 15px;
        width: 15px;
        border-radius: 50%;
        color: white;
        display: grid;
        place-items: center;
        background: ${ComparisonColors.gray};
        margin-right: 4px;
        margin-bottom: 2px;
    `,
    squareLegend: (color: string) => `
        margin-right: 5px; 
        height: 10px; 
        width: 10px;
        opacity: 1;
        background: ${color};
    `,
};
