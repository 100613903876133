import { AuthToken } from '@services/auth-token/authToken';
import { ActionProps } from '@shared/interfaces/store/action/actionProps';
import { GetAllAuthorsSIG } from '@store/author/sig/getAllAuthors.sig';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from 'config/config';
import { doesRequestDataHasErrors, handleCatchBlock } from 'services/error-handler/errorHandler';
import { queryBuilder } from 'services/url/url';
import { ActionError } from 'shared/enum/actionError';
import { isInstanceOfStoreDataStatus } from 'shared/enum/storeDataStatus';
import { APIError } from 'shared/interfaces/apiError';
import { GetAuthorArticlesSIG } from 'store/author/sig/getAuthorArticles.sig';
import { GetAuthorHighlightsSIG } from 'store/author/sig/getAuthorHighlights.sig';
import { BaseClient } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

interface AuthorsClientMethodReturnType<R> {
    data: R | null;
    error: string | boolean | APIError;
    success: boolean;
}

class CAuthorsAPIClient extends BaseClient {
    private constructor(enforcer) {
        super();

        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    initConfig(tokenHandler: AuthToken) {
        this.initializeConfig(api.authorsMicro, tokenHandler);
    }

    static get instance(): CAuthorsAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CAuthorsAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    handleRequestErrorChecking(response: AxiosResponse<any>) {
        const error      = doesRequestDataHasErrors(response.data);
        const dataStatus = isInstanceOfStoreDataStatus(error);

        if (!dataStatus && error){
            throw new Error(error);
        }

        return error;
    }

    handleRequestCatchBlock(error: unknown) {
        const result = handleCatchBlock(error, true);

        if (typeof result !== 'string') {
            const data = result?.data ?? {};

            return {
                data: null,
                error: {
                    ...data,
                    message: result?.error?.response?.status === 404 ? ActionError.AuthorNotFound : ''
                } as APIError,
                success: false
            };
        }

        return { data: null, error: result, success: false };
    }

    async getTopAuthors<R>(options: ActionProps, config?: AxiosRequestConfig): Promise<AuthorsClientMethodReturnType<R>> {
        try {
            const url      = queryBuilder(options?.category ? `views/authors/${options?.category}` : 'views/authors', options);
            const response = await this._session.get<R>(url, config);
            const error    = this.handleRequestErrorChecking(response);

            return { data: response.data, error, success: true };
        } catch (error: unknown) {
            return this.handleRequestCatchBlock(error);
        }
    }

    async getHighlights<R>(options: GetAuthorHighlightsSIG, config?: AxiosRequestConfig): Promise<AuthorsClientMethodReturnType<R>> {
        try {
            const url      = queryBuilder('/views', options);
            const response = await this._session.get<R>(url, config);
            const error    = this.handleRequestErrorChecking(response);

            return { data: response.data, error, success: true };
        } catch (error: unknown) {
            return this.handleRequestCatchBlock(error);
        }
    }

    async getArticles<R>(options: GetAuthorArticlesSIG, config?: AxiosRequestConfig): Promise<AuthorsClientMethodReturnType<R>> {
        try {
            const url      = queryBuilder('/views/topContent', options);
            const response = await this._session.get<R>(url, config);
            const error    = this.handleRequestErrorChecking(response);

            return { data: response.data, error, success: true };
        } catch (error: unknown) {
            return this.handleRequestCatchBlock(error);
        }
    }

    async getAllAuthors<R>(options: GetAllAuthorsSIG, config?: AxiosRequestConfig): Promise<AuthorsClientMethodReturnType<R>> {
        try {
            const url      = queryBuilder('/views/authorsMetrics', options);
            const response = await this._session.get<R>(url, config);
            const error    = this.handleRequestErrorChecking(response);

            return { data: response.data, error, success: true };
        } catch (error: unknown) {
            return this.handleRequestCatchBlock(error);
        }
    }
}

export const AuthorsAPIClient = CAuthorsAPIClient.instance;
