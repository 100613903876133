import classNames from 'classnames';

import Loader, { LoaderProps } from '../Loader';
import './SavingLoader.scss';

type Props = LoaderProps & {
    error?: string;
    className?: string;
};

const SavingLoader = ({ error, className, ...loadProps }: Props) => (
    <div className={classNames('saving-loader', { [className]: !!className })}>
        <p>{error ? error : 'saving...'}</p>
        {!error && <Loader {...loadProps} />}
    </div>
);

export default SavingLoader;
