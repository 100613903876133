import classNames from 'classnames';
import { useState } from 'react';

import './ISSettingsCoefWrapper.scss';

interface Item { id: string; value: number; selected: boolean }

interface Props {
    items: Item[];
    onChange: (value: number) => void;
    disabled?: boolean;
}

const ISSettingsCoefWrapper = ({ disabled = false, items, onChange }: Props) => {
    const [selectedItem, setSelectedItem] = useState<Item | null>(items.find(item => item.selected) || null);

    return (
        <div className='iss-coef-wrapper'>
            {items.map(((itemProps) => (
                <ISSettingsCoefItem
                    key={itemProps.id}
                    id={itemProps.id}
                    value={itemProps.value}
                    selected={itemProps.id === selectedItem?.id}
                    onChange={(item) => {
                        if (!disabled) {
                            setSelectedItem(item);
                        }

                        onChange(item.value);
                    }}
                />
            )))}

        </div>
    );
};

interface ItemProps { id: string; value: number; selected: boolean; onChange: (item: Item) => void }
const ISSettingsCoefItem = ({ id, value, selected, onChange }: ItemProps) => (
    <div
        className={classNames('iss-coef-item', { selected })}
        onClick={() => onChange({ id, value, selected })}
    >
        <p>{value}</p>
    </div>
);

export default ISSettingsCoefWrapper;
