import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const Cube = ({ className }: BaseSvgComponentProps) => (
    <svg
        className={className}
        width="44"
        height="49"
        viewBox="0 0 44 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M42.5026 36.9924C42.4224 36.9922 42.343 36.9769 42.2684 36.9473L42.1718 36.9059L25.2893 30.0177C25.1735 29.9707 25.0743 29.8897 25.0044 29.7852C24.9345 29.6806 24.8972 29.5573 24.8972 29.4311V22.8718L17.4678 19.7792C17.3535 19.7313 17.2558 19.6502 17.187 19.5462C17.1182 19.4421 17.0814 19.3198 17.0813 19.1946V0.738717C17.0805 0.634238 17.1054 0.531193 17.1537 0.438865C17.2021 0.346538 17.2724 0.267828 17.3583 0.209829C17.4442 0.15183 17.543 0.116363 17.6458 0.106624C17.7486 0.0968849 17.8522 0.11318 17.9473 0.15404L42.7404 10.4808C42.8557 10.5277 42.9545 10.6085 43.0241 10.7127C43.0936 10.8169 43.1307 10.9398 43.1307 11.0655V36.3607C43.1307 36.4439 43.1144 36.5262 43.0829 36.6029C43.0515 36.6797 43.0053 36.7494 42.9471 36.8081C42.8889 36.8668 42.8199 36.9132 42.7439 36.9449C42.668 36.9765 42.5866 36.9927 42.5044 36.9924H42.5026ZM26.1497 29.0044L41.88 35.4226V11.4904L18.3357 1.69187V18.7697L25.7688 21.8642C25.8831 21.9121 25.9808 21.9932 26.0496 22.0972C26.1184 22.2013 26.1552 22.3236 26.1553 22.4488L26.1497 29.0044Z"
            fill="white"
        />
        <path
            d="M25.5234 48.3758C25.4431 48.3762 25.3634 48.3602 25.2893 48.3288L0.496163 38.2107C0.380218 38.1639 0.280836 38.083 0.210917 37.9784C0.140998 37.8738 0.103771 37.7504 0.104067 37.6241V12.1202C0.104294 12.0163 0.129777 11.914 0.178272 11.8224C0.226767 11.7309 0.296784 11.6527 0.382155 11.595C0.467526 11.5372 0.565629 11.5016 0.667821 11.4912C0.770014 11.4808 0.873158 11.4961 0.968166 11.5355L17.9472 18.6118C18.0621 18.6592 18.1603 18.7401 18.2295 18.8443C18.2986 18.9484 18.3356 19.071 18.3356 19.1965V25.8178L25.7576 28.8465C25.8729 28.8939 25.9716 28.9751 26.0411 29.0795C26.1106 29.184 26.1478 29.3071 26.1478 29.433V47.7516C26.1478 47.9193 26.0821 48.0802 25.9651 48.199C25.848 48.3177 25.6892 48.3847 25.5234 48.3852V48.3758ZM1.35469 37.1974L24.9009 46.8116V29.8579L17.4678 26.8292C17.3524 26.782 17.2535 26.7009 17.184 26.5964C17.1144 26.4918 17.0774 26.3687 17.0776 26.2427V19.6176L1.35469 13.0639V37.1974Z"
            fill="white"
        />
        <path
            d="M0.626309 12.6465C0.491605 12.6472 0.36028 12.6039 0.251939 12.5229C0.143597 12.4419 0.0640493 12.3276 0.0251692 12.1972C-0.0137108 12.0667 -0.00983783 11.927 0.0362101 11.7989C0.0822581 11.6708 0.168011 11.5613 0.280669 11.4866L17.3657 0.1051C17.5039 0.0128584 17.6727 -0.0200468 17.8349 0.0136233C17.9153 0.030295 17.9916 0.0628096 18.0595 0.109311C18.1274 0.155812 18.1856 0.215388 18.2307 0.284639C18.2758 0.35389 18.3071 0.431459 18.3226 0.512917C18.3381 0.594375 18.3376 0.678127 18.3211 0.75939C18.3046 0.840654 18.2725 0.917838 18.2265 0.986536C18.1806 1.05523 18.1217 1.1141 18.0532 1.15977L0.968232 12.5431C0.866537 12.6103 0.747768 12.6462 0.626309 12.6465Z"
            fill="white"
        />
        <path
            d="M25.4194 22.9752C25.2848 22.9755 25.1538 22.9319 25.0457 22.8507C24.9377 22.7696 24.8585 22.6553 24.8198 22.5249C24.7812 22.3946 24.7852 22.255 24.8313 22.1271C24.8774 21.9993 24.9631 21.8899 25.0756 21.8152L42.1587 10.4319C42.2972 10.3476 42.4624 10.3207 42.62 10.3568C42.7776 10.393 42.9152 10.4893 43.0043 10.6257C43.0933 10.7621 43.1267 10.928 43.0976 11.0888C43.0685 11.2496 42.9792 11.3928 42.8481 11.4885L25.7631 22.8718C25.6608 22.939 25.5414 22.9749 25.4194 22.9752Z"
            fill="white"
        />
        <path
            d="M0.626241 38.1524C0.491667 38.1524 0.360678 38.1085 0.252679 38.0273C0.14468 37.946 0.0654165 37.8318 0.0266324 37.7014C-0.0121517 37.571 -0.00839344 37.4315 0.0373504 37.3035C0.0830942 37.1754 0.16839 37.0657 0.280601 36.9905L17.3656 25.6091C17.5041 25.5169 17.6731 25.484 17.8355 25.5179C17.9979 25.5517 18.1404 25.6495 18.2316 25.7896C18.3227 25.9297 18.3552 26.1007 18.3217 26.265C18.2883 26.4293 18.1917 26.5734 18.0532 26.6656L0.968164 38.0471C0.867002 38.1157 0.748003 38.1523 0.626241 38.1524V38.1524Z"
            fill="white"
        />
        <path
            d="M25.4194 48.2704C25.285 48.27 25.1542 48.2259 25.0465 48.1445C24.9389 48.0631 24.8599 47.9488 24.8213 47.8186C24.7828 47.6883 24.7867 47.5489 24.8325 47.4211C24.8783 47.2932 24.9635 47.1837 25.0756 47.1086L42.1587 35.7196C42.2972 35.6353 42.4624 35.6085 42.62 35.6446C42.7776 35.6807 42.9153 35.777 43.0043 35.9134C43.0933 36.0498 43.1267 36.2158 43.0976 36.3765C43.0685 36.5373 42.9792 36.6805 42.8482 36.7762L25.7632 48.1652C25.6613 48.2338 25.5417 48.2704 25.4194 48.2704V48.2704Z"
            fill="white"
        />
    </svg>
);
