import React from 'react';

import { limitNumberWithinRange } from '@services/math/math';
import './Progress.sass';
import ProgressBar from './bar/ProgressBar';
import ProgressIndicator from './indicator/ProgressIndicator';

declare interface ProgressProps {
    title: string | React.ReactNode;
    titleImg?: string;
    shouldShowPercentage?: boolean;
    progressPercentage?: number;
    percentage?: string | number;
    switchNumberAndPercentage?: boolean;
    background?: string;
    barHeight?: string;
    fontSize?: string;
    percentageSize?: string;
    titleBottomMargin?: string;
    number?: string | number;
    pastPercentageOrValue?: number;
    compare?: { percentage: number; diff?: number };
    hideDiff?: boolean;
    isRounded?: boolean;
}

function Progress({
    title,
    titleImg,
    progressPercentage,
    percentage,
    background,
    barHeight,
    fontSize,
    percentageSize,
    titleBottomMargin,
    number,
    pastPercentageOrValue,
    compare,
    switchNumberAndPercentage,
    hideDiff,
    shouldShowPercentage = true,
    isRounded = false
}: ProgressProps) {
    const percentResult = percentage + '%' || 0 + '%';
    const width: string = limitNumberWithinRange(
        Number(progressPercentage ? progressPercentage : percentage),
        0,
        100
    ) + '%';

    return (
        <div
            data-testid="progress-wrapper"
            className="progress-wrapper"
            style={{ fontSize }}
        >
            { number != null && title ? (
                <ProgressIndicator
                    shouldShowPercentage={shouldShowPercentage}
                    titleBottomMargin={titleBottomMargin}
                    titleImg={titleImg}
                    newTitle={title}
                    percentResult={percentResult}
                    pastPercentageOrValue={pastPercentageOrValue}
                    number={number}
                    switchNumberAndPercentage={switchNumberAndPercentage}
                    compare={compare}
                    hideDiff={hideDiff}
                />
            ) : null}
            <ProgressBar
                background={background}
                barHeight={barHeight}
                width={width}
                percentageSize={percentageSize}
                isRounded={isRounded}
            />
        </div>
    );
}

Progress.defaultProps = {
    background: '',
    percentage: null,
    progressPercentage: null,
    number: null,
    barHeight: '',
    fontSize: '',
    percentageSize: '',
    titleBottomMargin: '',
};

export default Progress;
