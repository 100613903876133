import PageFilterReport from '@components/widgets/page-filter/report/PageFilterReport';

import './PageFilter.sass';
import PageFilterCalendar from './calendar/PageFilterCalendar';

declare interface StatFilterProps {
    children?: React.ReactNode;
    disableDownloadReport?: boolean;
}

function PageFilter({ children, disableDownloadReport = false }: StatFilterProps) {

    return (
        <div className="page-filter-controls">
            {children}

            {/* <DownloadAsPDF /> */}
            <PageFilterCalendar />

            {!disableDownloadReport && <PageFilterReport />}
        </div>
    );
}

export default PageFilter;
