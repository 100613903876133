import { SVGAttributes } from 'react';

export const Trash = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_7980_1927)">
            <path d="M3.33594 5.83325H16.6693" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.33594 9.16675V14.1667" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.6641 9.16675V14.1667" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.16406 5.83325L4.9974 15.8333C4.9974 16.2753 5.17299 16.6992 5.48555 17.0118C5.79811 17.3243 6.22204 17.4999 6.66406 17.4999H13.3307C13.7728 17.4999 14.1967 17.3243 14.5092 17.0118C14.8218 16.6992 14.9974 16.2753 14.9974 15.8333L15.8307 5.83325" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_7980_1927">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
