export const sleep = (delayInMilliSeconds: number) => new Promise(resolve => {
    setTimeout(resolve, delayInMilliSeconds);
});

/**
 *
 * @param initialUrl -
 * window.location.origin +
 * window.location.pathname +
 * window.location.search +
 * (window.location.search ? '&' : '?')
 */
export const forceHardReload = (initialUrl?: string) => {
    window.location.href = `${
        initialUrl ?? (window.location.origin +
        window.location.pathname +
        window.location.search +
        (window.location.search ? '&' : '?'))
    }rt=${ Date.now().toString() }${ window.location.hash }`;
};

export const clearCacheExceptCookies= () => {
    if (window?.caches) {
        // Service worker cache should be cleared with caches.delete()
        window.caches.keys().then(function (names) {
            for (const name of names) {
                caches.delete(name);
            }
        });
        localStorage.clear();
    }

    doubleCheckServiceWorkerCache();
};

function doubleCheckServiceWorkerCache() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
    }
}

function urlWithRndQueryParam(url: string, paramName?: string) {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp    = new URLSearchParams(urlQry[1] || '');

    usp.set(paramName || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');

    return ulrArr.join('#');
}

export async function handleHardReload(url: string) {
    const newUrl         = urlWithRndQueryParam(url);

    await fetch(newUrl, {
        headers: {
            Pragma: 'no-cache',
            Expires: '-1',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Cache-Control': 'no-cache',
        },
    });
    window.location.href = url;
    // This is to ensure reload with url's having '#'
    window.location.reload();
}
