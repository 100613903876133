import { getLoginUrl } from '@services/url/url';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import { AuthToken } from 'services/auth-token/authToken';

export declare interface BaseClientAuthConfig {
    withCredentials?: boolean;
    headers?: {
        Authorization?: string;
        [key: string]: string;
    };
}

export class BaseClient {
    protected _session: AxiosInstance;
    protected _config: BaseClientAuthConfig = {};
    protected _baseURL = '';

    protected _initSession() {
        this._session                  = axios.create({ withCredentials: true, baseURL: this._baseURL });

        this._session.interceptors.response.use(
            (response) => response,
            (error: { response: Record<string, any> }) => {
                if (axios.isCancel(error)) {
                    console.log('[interceptors.response]: request canceled');

                    return;
                }

                if (error?.response?.status === 401) {
                    AuthToken.removeToken();

                    location.href = getLoginUrl();
                }

                return Promise.reject(error);
            }
        );
    }

    initializeConfig(apiDomain: string, tokenHandler: AuthToken) {
        if (tokenHandler){
            const user    = tokenHandler.decodedToken;

            this._baseURL = apiDomain.replace('{username}', user?.site.name);
        } else {
            this._baseURL = '';
        }

        this._initSession();
    }

    getEffect<T = any>(url: string, config?: AxiosRequestConfig): [Promise<AxiosResponse<T>>, CancelTokenSource] {
        const source       = axios.CancelToken.source();
        const cancelConfig = { cancelToken: source.token };
        const request      = this._session?.get<T>(url, { ...config, ...cancelConfig, ...this._config });

        return [request, source];
    }

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.get<T>(url, config);
    }

    post<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.post<T>(url, data, config);
    }

    put<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.put<T>(url, data, config);
    }

    patch<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.patch<T>(url, data, config);
    }

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session.delete<T>(url, config);
    }
}
