export const Overview = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_6495_266)">
            <path d="M4 4H10V12H4V4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 16H10V20H4V16Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 12H20V20H14V12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 4H20V8H14V4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_6495_266">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
