import { AxiosInstance, AxiosRequestConfig } from 'axios';
import Config from 'config/config';
import { BaseClient } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

export enum LiveDataTimeTypes {
    LAST_30_MINUTES,
    LAST_30_SECONDS
}

export interface LiveAPIBaseParams {
    clientName: string;
    dataTime: LiveDataTimeTypes;
}

class CLiveAPIClient extends BaseClient {
    private constructor(enforcer) {
        super();

        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._baseURL = api.liveMicro;
            this._config  = {};
            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): CLiveAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CLiveAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    getLive<AxiosReturn = any>({ clientName, dataTime }: LiveAPIBaseParams, config?: AxiosRequestConfig) {
        const endpoint = dataTime === LiveDataTimeTypes.LAST_30_MINUTES ? 'widget30m' : 'widget30s';

        return this.get<AxiosReturn>(`/live/${endpoint}/${clientName}`, { ...this._config, ...config });
    }

    getActivePages<AxiosReturn = any>({ clientName, dataTime }: LiveAPIBaseParams, config?: AxiosRequestConfig) {
        const endpoint = dataTime === LiveDataTimeTypes.LAST_30_MINUTES ? 'pages30m' : 'pages30s';

        return this.get<AxiosReturn>(`/live/${endpoint}/${clientName}`, { ...this._config, ...config });
    }

    getCountries<AxiosReturn = any>({ clientName, dataTime }: LiveAPIBaseParams, config?: AxiosRequestConfig) {
        const endpoint = dataTime === LiveDataTimeTypes.LAST_30_MINUTES ? 'countries30m' : 'countries30s';

        return this.get<AxiosReturn>(`/live/${endpoint}/${clientName}`, { ...this._config, ...config });
    }

    getCirculation<AxiosReturn = any>({ clientName, dataTime }: LiveAPIBaseParams, config?: AxiosRequestConfig) {
        const endpoint = dataTime === LiveDataTimeTypes.LAST_30_MINUTES ? 'circulations30m' : 'circulations30s';

        return this.get<AxiosReturn>(`/live/${endpoint}/${clientName}`, { ...this._config, ...config });
    }
}

export const LiveAPIClient = CLiveAPIClient.instance;
