export const INDEPTH_AND_HEATMAP_GET_ALL = 'INDEPTH_AND_HEATMAP_GET_ALL';
export const INDEPTH_AND_HEATMAP_GET_ALL_FAILURE =
    'INDEPTH_AND_HEATMAP_GET_ALL_FAILURE';

export const INDEPTH_AND_HEATMAP_GET_CURR_STATS =
    'INDEPTH_AND_HEATMAP_GET_CURR_STATS';
export const INDEPTH_AND_HEATMAP_GET_PREV_STATS =
    'INDEPTH_AND_HEATMAP_GET_PREV_STATS';
export const INDEPTH_AND_HEATMAP_GET_STATS_FAILURE =
    'INDEPTH_AND_HEATMAP_GET_STATS_FAILURE';

export const INDEPTH_AND_HEATMAP_IFRAME_ERROR =
    'INDEPTH_AND_HEATMAP_IFRAME_ERROR';
