import type { CategoriesPerDay } from '@shared/interfaces/store/categories/categoriesPerDay';
import type { TooltipParamCompare } from 'shared/interfaces/tooltip/tooltipParamCompare';

import { generateChartXAxis, getCompareXAxis } from 'services/chart/axis';
import { calcPercentageOfCurrentFromPrevious } from 'services/math/math';
import { PeriodOptions } from 'shared/enum/periodOptions';

export declare interface CategoryPerDayLineChartData {
    categoriesNames: string[];
    categoriesPerDay: {
        [key: string]: {
            [key: string]: number;
        };
    };
    pastCategoriesPerDay: {
        [key: string]: {
            [key: string]: number;
        };
    };
    data: {
        [categoryName: string]: {
            compare: TooltipParamCompare;
            name: string;
            value: number;
            xAxis: string;
        } [];
    };
    xAxis: string[];
    tooltipHeaders: string[];
}

export function formatCategoriesDataToLineChart(categories: CategoriesPerDay): CategoryPerDayLineChartData {
    if (!categories || categories?.current?.categories?.length <= 0) {
        return {
            categoriesNames: [],
            categoriesPerDay: null,
            pastCategoriesPerDay: null,
            xAxis: [],
            data: {},
            tooltipHeaders: [],
        };
    }

    const categoriesNames      = categories.current.categories.map((item) => item[0]);
    const categoriesPerDay     = sortCategoriesByDate(categories?.current?.categoriesPerDay);
    const pastCategoriesPerDay = sortCategoriesByDate(categories?.last?.categoriesPerDay);

    const values     = Object.values(categoriesPerDay);
    const pastValues = pastCategoriesPerDay ? Object.values(pastCategoriesPerDay) : [];
    const currAxis   = Object.keys(categoriesPerDay);
    const prevAxis   = pastCategoriesPerDay ? Object.keys(pastCategoriesPerDay) : [];
    const xAxis      = generateChartXAxis(currAxis, PeriodOptions.Day);

    const data = {};

    for (const categoryName of categoriesNames) {
        data[categoryName] =  new Array(xAxis.length).fill(0);
    }

    for (let entryIdx = 0; entryIdx < values.length ; entryIdx++) {
        const value   = values[entryIdx];
        const entries = Object.entries(value);

        entries.map(([categoryName, value]) => {
            const pastItem   = pastValues?.[entryIdx] || [];
            const pastValue  = pastItem?.[categoryName] || 0;
            const diff       = value - pastValue;
            const percentage = calcPercentageOfCurrentFromPrevious(value, pastValue);

            data[categoryName][entryIdx] = {
                xAxis: xAxis[entryIdx],
                value,
                name: categoryName,
                compare: {
                    pastPercentageOrValue: pastValue,
                    percentage,
                    diff,
                }
            };
        });
    }

    const tooltipAxis           = generateChartXAxis(currAxis, PeriodOptions.Day, null);
    const tooltipCompareAxisTmp = getCompareXAxis(currAxis, prevAxis);
    const tooltipCompareAxis    = generateChartXAxis(tooltipCompareAxisTmp, PeriodOptions.Day, null);
    const tooltipHeaders        = tooltipAxis.map((item, index) => `${item} vs ${tooltipCompareAxis[index]}`);

    return {
        categoriesNames,
        categoriesPerDay,
        pastCategoriesPerDay,
        xAxis: xAxis as string[],
        data,
        tooltipHeaders,
    };
}

function sortCategoriesByDate(
    categoriesPerDay: {
        [key: string]: {
            [key: string]: number;
        };
    }
) {
    return categoriesPerDay && Object.keys(categoriesPerDay)?.length ? Object.fromEntries(
        Object.entries(categoriesPerDay).sort(function([date1], [date2]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return new Date(date1) - new Date(date2);
        })
    )  : null;
}
