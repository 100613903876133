import { gsap } from 'gsap';
import React, { useRef } from 'react';

import type { Dimension } from 'shared/interfaces/dimension';

import { useGSAP } from '@gsap/react';
import checkIcon from 'assets/icons/check.png';
import './CheckboxItem.sass';

export declare interface CheckboxItemProps {
    title: string;
    checked: boolean;
    callback: (checked: boolean) => void;
    code?: string;
    primary?: boolean;
    reverseFlex?: boolean;
    removeRadius?: boolean;
    titleStyle?: React.CSSProperties;
    dimension?: string;
    margin?: string;
    fontSize?: string;
    disabled?: boolean;
}

const CheckboxItem        = ({
    title,
    checked,
    callback,
    code,
    primary,
    dimension,
    margin,
    fontSize,
    reverseFlex,
    removeRadius,
    titleStyle,
    disabled = false
}: CheckboxItemProps) => {
    const input     = useRef<HTMLInputElement>();
    const container = useRef<HTMLDivElement>();

    const itemClass: string        = primary ? 'primary' : 'secondary';
    const itemDimension: Dimension = dimension
        ? {
            width: dimension,
            height: dimension,
        }
        : {};
    const itemMargin               = margin ? { marginRight: margin } : {};
    const itemFontSize             = fontSize ? { fontSize } : {};

    const radius          = removeRadius ? { borderRadius: 0 } : {};
    const itemStyle       = {
        ...itemDimension,
        ...itemMargin,
        ...radius,
    };
    const { contextSafe } = useGSAP({ scope: container });

    // /* istanbul ignore next */


    const handleFakeCheckbox = contextSafe(() => {
        input.current.click();
        gsap.timeline()
            .to(container.current, {
                duration: 0.1,
                ease: 'Expo.easeInOut',
            })
            .to(container.current, {
                duration: 0.1,
                ease: 'Expo.easeInOut',
            });
    });

    return (
        <div
            data-code={code}
            className={`ch-menu-item ${disabled ? 'disabled' : ''} ${reverseFlex ? 'flex-reverse' : ''}`}
            ref={container}
            onClick={() => handleFakeCheckbox()}
            role="checkbox-wrapper"
        >
            <input
                role="input"
                id={title}
                ref={input}
                type="checkbox"
                checked={checked}
                onChange={() => callback(!checked)}
                disabled={disabled}
            />
            <div
                style={itemStyle}
                data-testid="checkbox"
                className={
                    'ch-menu-checkbox ' +
                    (checked ? 'active' : '') +
                    ' ' +
                    itemClass
                }
            >
                <img src={checkIcon} alt="checked icon" />
            </div>
            <label
                role="label"
                style={{ ...itemFontSize, ...titleStyle }}
                htmlFor={title}
            >
                {title}
            </label>
        </div>
    );
};

CheckboxItem.defaultProps = {
    title: '',
    checked: false,
    code: '',
    primary: false,
    dimension: '',
    margin: '',
    fontSize: '',
};

export default React.memo(CheckboxItem);
