/* eslint-disable */
import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Funnel, ReferrerPercentages } from 'shared/interfaces/store/funnel';

import {
    VISITOR_BEHAVIOR_DATA_IS_LOADING,
    VISITOR_BEHAVIOR_GET_ALL_FAILURE,
    VISITOR_BEHAVIOR_GET_ALL_PERCENTAGES_FAILURE,

    VISITOR_BEHAVIOR_GET_ALL_PERCENTAGES_SUCCESS,
    VISITOR_BEHAVIOR_GET_ALL_SUCCESS,
    VISITOR_BEHAVIOR_PERCENTAGE_DATA_IS_LOADING
} from './visitorBehavior.types';

export declare interface VisitorBehaviorState {
    funnel: Funnel;
    funnelRequestSource: CancelTokenSource;
    funnelError: string;
    funnelIsLoading: boolean;
    
    percentages: ReferrerPercentages;
    percentagesRequestSource: CancelTokenSource;
    percentagesError: string;
    percentageIsLoading: boolean;
}

const initialState: VisitorBehaviorState = {
    funnel: null,
    funnelRequestSource: null,
    funnelError: null,
    funnelIsLoading: false,
    
    percentages: null,
    percentagesRequestSource: null,
    percentagesError: null,
    percentageIsLoading: false,
};

const visitorBehaviorReducer = function (state: VisitorBehaviorState = initialState, action: ReducerAction): VisitorBehaviorState {
    switch (action.type) {
        case VISITOR_BEHAVIOR_DATA_IS_LOADING:{
            return {
                ...state,
                funnelIsLoading: true,
            };
        }
        
        case VISITOR_BEHAVIOR_GET_ALL_SUCCESS: {
            return {
                ...state,
                funnel: action.payload.data,
                funnelRequestSource: action.payload.requestSource,
                funnelError: null,
                funnelIsLoading: false,
            };
        }

        case VISITOR_BEHAVIOR_GET_ALL_FAILURE: {
            return {
                ...state,
                funnelError: action.payload.message,
                funnelIsLoading: false,
            };
        }

        case VISITOR_BEHAVIOR_GET_ALL_PERCENTAGES_SUCCESS: {
            return {
                ...state,
                percentages: action.payload.data,
                percentagesRequestSource: action.payload.requestSource,
                percentagesError: null,
                percentageIsLoading: false,
            };
        }

        case VISITOR_BEHAVIOR_PERCENTAGE_DATA_IS_LOADING:{
            return {
                ...state,
                percentageIsLoading: true,
            };
        }

        case VISITOR_BEHAVIOR_GET_ALL_PERCENTAGES_FAILURE: {
            return {
                ...state,
                percentagesError: action.payload.message,
                percentageIsLoading: false
            };
        }
        default: {
            return state;
        }
    }
};

export default visitorBehaviorReducer;
