import classNames from 'classnames';

import './InputTarget.scss';

type Props = {
    wrapperClassName?: string;
    targetSymbol?: string;
    targetUnit?: string;
    error?: string;
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type'>;

const InputTarget = ({ targetSymbol, targetUnit, wrapperClassName, className, error, ...rest }: Props) => (
    <div className={classNames('input-target-wrapper', { [wrapperClassName]: !!wrapperClassName })}>
        {targetSymbol && <div>{targetSymbol}</div>}
        <input
            {...rest}
            type={'text'}
            id={rest?.id}
            className={classNames({
                [className]: !!className,
                hasError: !!error
            })}
        />

        {targetUnit && <p>{targetUnit}</p>}
    </div>
);

export default InputTarget;
