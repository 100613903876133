import { useEffect, useState } from 'react';

import { Plus2Sm } from '@components/svg/Plus2Sm';
import Loader from '@components/widgets/loader/Loader';
import Config from 'config/config';
import { useAppSelector } from 'hooks/redux/useRedux';
import axios from 'services/axios/axios';
import './PageFilterReport.sass';

const { api } = Config;

function PageFilterReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady]     = useState(false);
    const [src, setSrc]             = useState('');

    const { username, dateRange, dataIsLoading } = useAppSelector((state) => ({
        username: state?.authenticationReducer?.user?.username,
        dateRange: state.datePickerReducer,
        dataIsLoading: state.viewsReducer.audienceIsLoading,
    }));
    const domain                                 = api.apiDomain.replace('{username}', username);

    const handleClick = () => {
        if (isLoading) { return; }

        setIsLoading(true);

        const { current, previous } = dateRange;
        const [request]             = axios.getEffect(`/reporting?from=${current[0]}&to=${current[1]}&compareFrom=${previous[0]}&compareTo=${previous[1]}`);

        request.then((res) => {
            setIsReady(true);
            setIsLoading(false);
            setSrc(res.data.pdf);
        }).catch(() => {
            setIsLoading(false);
            setIsReady(false);
        });
    };

    useEffect(() => {
        if (!isLoading) {
            setIsReady(false);
        }

    }, [dateRange.current, dateRange.previous]);

    return (
        <div className='filter-report-wrapper'>
            <>
                {
                    !isLoading && isReady && (
                        <>
                            <a className='filter-report-download-pdf --seePdf' href={domain + src} target='_blank'>
                                See report
                            </a>
                        </>
                    )
                }

                {
                    !isLoading && !isReady && (
                        <div onClick={handleClick} className={'filter-report-download-pdf --downloadPdf' + (dataIsLoading ? ' _disabled' : '')}>
                            <span className='svg-wrapper'>
                                <Plus2Sm />
                            </span>
                            <span>
                                Generate report
                            </span>
                        </div>
                    )
                }

                {
                    isLoading && !isReady && (
                        <div className='filter-report-download-pdf --loadingPdf'>
                            <Loader />
                        </div>
                    )
                }
            </>
        </div>
    );
}

export default PageFilterReport;
