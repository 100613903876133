import { createSlice } from '@reduxjs/toolkit';

import { type ImpactScore } from '@shared/interfaces/store/impactScore';
import { type RootState } from '@store/storeConfig';

import { DataStatus } from '@shared/enum/dataStatus';

import {
    fetchImpactScore,
    getImpactScoreMetrics,
    updateImpactScoreMetrics,
    type ImpactScoreMetrics,
    type UpdateImpactScoreMetricsReturnType
} from './impactScore.thunk';

export declare interface ImpactScoreState {
    score: ImpactScore;
    error: string | null;
    status: DataStatus;

    settingsResult: ImpactScoreMetrics;
    settingsResultError: string | null;
    settingsResultStatus: DataStatus;

    settingsUpdateResult: UpdateImpactScoreMetricsReturnType;
    settingsUpdateResultError: string | null;
    settingsUpdateResultStatus: DataStatus;
}

const initialState: ImpactScoreState = {
    score: null,
    error: null,
    status: DataStatus.IDLE,

    settingsResult: null,
    settingsResultError: null,
    settingsResultStatus: DataStatus.IDLE,

    settingsUpdateResult: null,
    settingsUpdateResultError: null,
    settingsUpdateResultStatus: DataStatus.IDLE,
};

export const impactScoreSlice = createSlice({
    name: 'impactScore',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImpactScore.pending, (state) => {
                state.status = DataStatus.LOADING;
            })
            .addCase(fetchImpactScore.fulfilled, (state, action) => {
                state.score  = action.payload;
                state.status = DataStatus.SUCCESS;
            })
            .addCase(fetchImpactScore.rejected, (state, action) => {
                state.error  = action.payload as unknown as string;
                state.status = DataStatus.FAILED;
            })
            // impact score update settings metrics
            .addCase(updateImpactScoreMetrics.pending, (state) => {
                state.settingsUpdateResultStatus = DataStatus.LOADING;
            })
            .addCase(updateImpactScoreMetrics.fulfilled, (state, action) => {
                state.settingsUpdateResult       = action.payload;
                state.settingsUpdateResultStatus = DataStatus.SUCCESS;
            })
            .addCase(updateImpactScoreMetrics.rejected, (state, action) => {
                state.settingsUpdateResultError  = action.payload as unknown as string;
                state.settingsUpdateResultStatus = DataStatus.FAILED;
            })
            // impact score get settings metrics
            .addCase(getImpactScoreMetrics.pending, (state) => {
                state.settingsResultStatus = DataStatus.LOADING;
            })
            .addCase(getImpactScoreMetrics.fulfilled, (state, action) => {
                state.settingsResult       = action.payload;
                state.settingsResultStatus = DataStatus.SUCCESS;
            })
            .addCase(getImpactScoreMetrics.rejected, (state, action) => {
                state.settingsResultError  = action.payload as unknown as string;
                state.settingsResultStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = impactScoreSlice.actions;
export const selectedFromImpactScore = {
    score: (state: RootState) => ({
        score: state.impactScoreReducer.score ?? null,
        error: state.impactScoreReducer.error,
        status: state.impactScoreReducer.status,
    }),
    settingsResult: (state: RootState) => ({
        data: state.impactScoreReducer.settingsResult ?? null,
        error: state.impactScoreReducer.settingsResultError,
        status: state.impactScoreReducer.settingsResultStatus,
    }),
    settingsUpdateResult: (state: RootState) => ({
        data: state.impactScoreReducer.settingsUpdateResult ?? null,
        error: state.impactScoreReducer.settingsUpdateResultError,
        status: state.impactScoreReducer.settingsUpdateResultStatus,
    }),
};
const impactScoreReducer = impactScoreSlice.reducer;

export default impactScoreReducer;
