import { ThemeColors } from 'constants/colors';
import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const KeyboardArrowRightMedium = ({ onClick, style, ...svgProps }: BaseSvgComponentProps) => (
    <svg
        style={style}
        onClick={onClick}
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        {...svgProps}
    >
        <path
            d="M15.395 10.44C15.395 10.24 15.3112 10.04 15.1436 9.87998L8.31308 2.59998C7.97784 2.23998 7.34927 2.19998 6.97212 2.51998C6.59498 2.83998 6.55307 3.43998 6.88831 3.79998L13.174 10.44L6.88831 17.08C6.55307 17.44 6.55308 18.04 6.97212 18.36C7.39117 18.68 7.97784 18.68 8.31308 18.28L15.1436 11.04C15.3112 10.92 15.395 10.68 15.395 10.44Z"
            fill={ThemeColors.themeHighlightColor}
        />
    </svg>
);
