import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';

import Show from '@components/common/condition/Show';
import { Eye } from '@components/svg/common';

import './InputWithLabel.scss';

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: string;
    showError?: boolean;
    wrapperClassName?: string;
    title: string;
    inputPlaceholder?: JSX.Element;
};

const InputWithLabel = ({ type = 'text', error, showError = true, inputPlaceholder, title, wrapperClassName, className, ...rest }: PropsWithChildren<Props>) => {
    const [shouldSee, setShouldSee] = useState(false);

    return (
        <div className={classNames('input-with-label-wrapper', { [wrapperClassName]: !!wrapperClassName })}>
            <label htmlFor={rest?.id}>{title}</label>
            <div className="iwl-inner-wrapper">
                <Show>
                    <Show.When isTrue={!!inputPlaceholder}>
                        {inputPlaceholder}
                    </Show.When>
                    <Show.Else>
                        <input
                            {...rest}
                            type={shouldSee ? 'text' : type}
                            id={rest?.id}
                            className={classNames({
                                [className]: !!className,
                                hasError: !!error
                            })}
                        />

                        <Show.When isTrue={type === 'password'}>
                            <Eye
                                className={classNames({ active: shouldSee })}
                                onClick={() => setShouldSee(!shouldSee)}
                            />
                        </Show.When>
                    </Show.Else>
                </Show>
            </div>
            <Show.When isTrue={!!error && showError}>
                <p className='iwl-error'>{error}</p>
            </Show.When>
        </div>
    );
};

export default InputWithLabel;
