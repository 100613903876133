import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Each, SocialMediaProfileAvatar } from '@components/common';
import { Eye, Trash } from '@components/svg';
import { DefaultAvatar } from '@constants/defaults';

import './SocialMediaProfileActive.scss';

interface Props {
    items: ItemProps[];
}

const SocialMediaProfileActive = ({ items }: Props) => (
    <div className='smpa-items'>
        <Each of={items} render={item => (
            <Item {...item}/>
        )}/>
    </div>
);

interface ItemProps {
    thumbnail: string;
    icon: string;
    name: string;
    url: string;
    onDelete: () => void;
    onView: () => void;
}

const Item = ({ name, url, thumbnail, icon, onDelete, onView }: ItemProps) => {
    const [hasHovered, setHasHovered] = useState(false);

    return (
        <article
            className="smpa-item"
            onMouseEnter={() => setHasHovered(true)}
            onMouseLeave={() => setHasHovered(false)}
        >
            <SocialMediaProfileAvatar
                networkThumbnail={icon}
                thumbnail={isEmpty(thumbnail) ? DefaultAvatar : thumbnail}
                className='smpa-avatar'
            />
            <div className="smpa-details">
                <h5>{name}</h5>
                <a href={url} target='_blank'>{url}</a>
            </div>
            <div className={classNames('smpa-actions', { active: hasHovered })}>
                <Trash className='smpa-delete' onClick={onDelete} />
                <Eye className='smpa-view' onClick={onView}/>
            </div>
        </article>
    );
};

export default SocialMediaProfileActive;
