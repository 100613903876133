import { connect } from 'react-redux';
import { isInSinglePage } from 'services/validation/validation';

import CheckboxItem from './CheckboxItem';

declare interface CheckboxItemWrapperProps {
    pageView: boolean;
    setPageView: (value: boolean) => void;
    bounceRate: boolean;
    setBounceRate: (value: boolean) => void;
    depthRate: boolean;
    setDepthRate: (value: boolean) => void;
    loyalVisitor?: boolean;
    setLoyalVisitor?: (value: boolean) => void;
    session: boolean;
    setSession: (value: boolean) => void;
    uniqueVisitors: boolean;
    setUniqueVisitors: (value: boolean) => void;
    AVGTimeOnSite: boolean;
    setAVGTimeOnSite: (value: boolean) => void;
    AVGTimeOnPage: boolean;
    setAVGTimeOnPage: (value: boolean) => void;
    returningVisitor: boolean;
    setReturningVisitor: (value: boolean) => void;
    sessionPerUser: boolean;
    setSessionPerUser: (value: boolean) => void;
    pagePerSession: boolean;
    setPagePerSession: (value: boolean) => void;
    newVisitor: boolean;
    setNewVisitor: (value: boolean) => void;
    pagesPerVisitor: boolean;
    setPagesPerVisitor: (value: boolean) => void;
    location?: string;
}

const CheckboxItemWrapper = ({
    pageView,
    setPageView,
    bounceRate,
    setBounceRate,
    depthRate,
    setDepthRate,
    // loyalVisitor,
    // setLoyalVisitor,
    session,
    setSession,
    uniqueVisitors,
    setUniqueVisitors,
    AVGTimeOnSite,
    setAVGTimeOnSite,
    AVGTimeOnPage,
    setAVGTimeOnPage,
    returningVisitor,
    setReturningVisitor,
    sessionPerUser,
    setSessionPerUser,
    pagePerSession,
    setPagePerSession,
    newVisitor,
    setNewVisitor,
    pagesPerVisitor,
    setPagesPerVisitor,
    location,
}: CheckboxItemWrapperProps) => (
    <div className="ch-menu-wrapper">
        <CheckboxItem
            title="Pageviews"
            code="pageView"
            checked={pageView}
            callback={setPageView}
        />
        <CheckboxItem
            title="Bounce rate"
            code="bounceRate"
            checked={bounceRate}
            callback={setBounceRate}
        />
        <CheckboxItem
            title="Depth rate"
            code="depthRate"
            checked={depthRate}
            callback={setDepthRate}
        />
        {/* <CheckboxItem
            title="Loyal visitor"
            code="loyalVisitor"
            checked={loyalVisitor}
            callback={setLoyalVisitor}
        /> */}
        <CheckboxItem
            title="Sessions"
            code="session"
            checked={session}
            callback={setSession}
        />
        <CheckboxItem
            title="Visitors"
            code="uniqueVisitors"
            checked={uniqueVisitors}
            callback={setUniqueVisitors}
        />
        {
            !isInSinglePage() ? (
                <CheckboxItem
                    title="Avg time on site"
                    code="AVGTimeOnSite"
                    checked={AVGTimeOnSite}
                    callback={setAVGTimeOnSite}
                    disabled={location === 'content' ? true : false}
                />
            ) : null
        }
        <CheckboxItem
            title="Avg reading time"
            code="AVGTimeOnPage"
            checked={AVGTimeOnPage}
            callback={setAVGTimeOnPage}
        />
        <CheckboxItem
            title="Returning Visitors"
            code="returningVisitor"
            checked={returningVisitor}
            callback={setReturningVisitor}
        />
        <CheckboxItem
            title="Sessions Per Visitor"
            code="sessionPerUser"
            checked={sessionPerUser}
            callback={setSessionPerUser}
        />
        <CheckboxItem
            title="Pages Per Session"
            code="pagePerSession"
            checked={pagePerSession}
            callback={setPagePerSession}
        />
        <CheckboxItem
            title="New Visitors"
            code="newVisitor"
            checked={newVisitor}
            callback={setNewVisitor}
        />
        <CheckboxItem
            title="Pages Per Visitor"
            code="pagesPerVisitor"
            checked={pagesPerVisitor}
            callback={setPagesPerVisitor}
        />
    </div>
);

const mapStateToProps = state => ({
    location: state.location
});

export default connect(mapStateToProps)(CheckboxItemWrapper);
