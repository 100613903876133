import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const Indepth = ({ className }: BaseSvgComponentProps) => (
    <svg className={className} width="226" height="61" viewBox="0 0 226 61" fill="none" >
        <g clipPath="url(#clip0_2493_911)">
            <path d="M56.425 13.126C56.0137 12.752 55.6868 12.2928 55.4665 11.7796C55.2462 11.2664 55.1377 10.7112 55.1484 10.1518C55.1362 9.58726 55.2438 9.02659 55.464 8.50769C55.6843 7.98878 56.012 7.52371 56.425 7.14387C57.2786 6.34174 58.3775 5.94067 59.7216 5.94067C61.0373 5.94067 62.1213 6.34174 62.9736 7.14387C63.3867 7.52371 63.7144 7.98878 63.9346 8.50769C64.1548 9.02659 64.2625 9.58726 64.2502 10.1518C64.2607 10.7109 64.1521 11.2657 63.9318 11.7786C63.7115 12.2915 63.3847 12.7503 62.9736 13.1241C62.1213 13.925 61.0373 14.3254 59.7216 14.3254C58.3762 14.3267 57.2774 13.9269 56.425 13.126ZM63.533 16.9593V42.2733H55.8657V16.9593H63.533Z" fill="white"/>
            <path d="M91.3568 19.5668C93.1073 21.4882 93.9819 24.1271 93.9807 27.4835V42.2733H86.3617V28.5269C86.3617 26.8349 85.9281 25.5189 85.061 24.5789C84.1938 23.6389 83.028 23.1701 81.5637 23.1726C80.0981 23.1726 78.9324 23.6414 78.0664 24.5789C77.2004 25.5164 76.7668 26.8324 76.7656 28.5269V42.2733H69.0928V16.9593H76.7619V20.3169C77.5687 19.1773 78.6514 18.2665 79.9061 17.6718C81.292 17.0054 82.8112 16.6715 84.3455 16.6961C87.2704 16.6898 89.6075 17.6467 91.3568 19.5668Z" fill="white"/>
            <path d="M100.213 23.152C101.166 21.2982 102.62 19.7563 104.406 18.7058C106.241 17.6469 108.324 17.106 110.437 17.1398C112.489 17.1124 114.507 17.6624 116.267 18.7284C118.001 19.7862 119.272 21.1648 120.078 22.8644V8.70239H123.217V42.2734H120.078V36.7387C119.335 38.4632 118.109 39.877 116.401 40.9799C114.692 42.0829 112.69 42.6349 110.393 42.6362C108.277 42.6706 106.192 42.1214 104.361 41.0476C102.576 39.9835 101.129 38.4251 100.191 36.5563C99.1899 34.6212 98.6895 32.3834 98.6895 29.8429C98.6895 27.3024 99.1974 25.0721 100.213 23.152ZM118.868 24.6278C118.115 23.1736 116.973 21.9631 115.572 21.1348C114.176 20.3169 112.589 19.8935 110.976 19.909C109.346 19.8828 107.738 20.2899 106.312 21.0896C104.924 21.889 103.795 23.0789 103.062 24.515C102.269 26.0114 101.873 27.788 101.873 29.8447C101.873 31.8714 102.269 33.6486 103.062 35.1764C103.789 36.6342 104.918 37.8475 106.312 38.6694C107.734 39.4826 109.344 39.8973 110.976 39.8707C112.589 39.8869 114.176 39.4642 115.572 38.6469C116.972 37.8187 118.115 36.609 118.868 35.1557C119.675 33.6442 120.078 31.8896 120.078 29.8917C120.078 27.8939 119.675 26.1393 118.868 24.6278Z" fill="white"/>
            <path d="M152.455 31.1138H132.141C132.23 32.9937 132.678 34.5967 133.486 35.9228C134.248 37.2071 135.351 38.2493 136.669 38.9307C138.003 39.6157 139.48 39.9658 140.975 39.9516C143.008 39.9516 144.719 39.4502 146.109 38.4476C147.478 37.4805 148.457 36.0469 148.867 34.4094H152.193C151.632 36.7725 150.277 38.8651 148.36 40.3295C146.401 41.8573 143.942 42.6212 140.982 42.6212C138.681 42.6212 136.618 42.0998 134.794 41.057C132.976 40.0189 131.49 38.4761 130.511 36.6108C129.48 34.6995 128.965 32.4598 128.965 29.8918C128.965 27.3237 129.473 25.0708 130.489 23.1332C131.445 21.2592 132.928 19.7125 134.75 18.687C136.572 17.6593 138.65 17.1448 140.982 17.1436C143.315 17.1423 145.355 17.6568 147.103 18.687C148.803 19.6652 150.201 21.1024 151.14 22.838C152.089 24.6132 152.576 26.6043 152.552 28.6228C152.547 29.7094 152.515 30.5397 152.455 31.1138ZM148.261 23.81C147.53 22.5417 146.446 21.5186 145.145 20.8678C143.808 20.1973 142.333 19.8553 140.841 19.8695C138.51 19.8695 136.521 20.6259 134.876 22.1387C133.231 23.6515 132.319 25.7821 132.141 28.5306H149.359C149.39 26.6807 149.024 25.1072 148.261 23.81Z" fill="white"/>
            <path d="M165.077 18.7998C166.766 17.6956 168.776 17.1435 171.109 17.1435C173.207 17.1123 175.274 17.6519 177.095 18.7058C178.877 19.7537 180.325 21.2968 181.266 23.1519C182.266 25.0733 182.767 27.3036 182.768 29.8428C182.769 32.3821 182.269 34.6199 181.266 36.5563C180.327 38.4248 178.88 39.983 177.095 41.0475C175.279 42.1172 173.21 42.6664 171.109 42.6361C168.806 42.6361 166.803 42.084 165.099 40.9799C163.396 39.8757 162.157 38.492 161.383 36.8288V57.3752H158.283V17.5026H161.377V22.9545C162.156 21.2876 163.39 19.9027 165.077 18.7998ZM178.395 24.513C177.662 23.0774 176.533 21.8875 175.145 21.0877C173.719 20.2884 172.111 19.8814 170.481 19.9071C168.868 19.8913 167.281 20.3147 165.885 21.1328C164.484 21.9612 163.341 23.1724 162.589 24.6277C161.782 26.1405 161.378 27.8951 161.377 29.8917C161.376 31.8882 161.78 33.6429 162.589 35.1557C163.341 36.6108 164.484 37.822 165.885 38.6506C167.281 39.4681 168.868 39.8909 170.481 39.8744C172.114 39.9005 173.724 39.4859 175.145 38.6731C176.54 37.8489 177.669 36.6338 178.395 35.1745C179.187 33.6467 179.583 31.8694 179.585 29.8428C179.585 27.7861 179.188 26.0095 178.395 24.513Z" fill="white"/>
            <path d="M192.631 20.1797V35.5598C192.631 37.0714 192.916 38.1147 193.484 38.69C194.053 39.2653 195.055 39.5523 196.489 39.5511H199.358V42.2733H195.995C193.783 42.2733 192.14 41.7513 191.063 40.7072C189.986 39.6632 189.448 37.9474 189.448 35.5598V20.1797H186.04V17.5025H189.448V11.2798H192.631V17.5025H199.358V20.1797H192.631Z" fill="white"/>
            <path d="M221.174 18.2358C222.674 19.0465 223.893 20.303 224.666 21.836C225.517 23.454 225.943 25.4274 225.944 27.7561V42.2734H222.848V28.1189C222.848 25.4268 222.184 23.3701 220.854 21.9488C219.525 20.5275 217.709 19.8169 215.406 19.8169C213.044 19.8169 211.16 20.5733 209.755 22.086C208.35 23.5988 207.65 25.8247 207.653 28.7638V42.2828H204.515V8.70239H207.653V21.7232C208.424 20.2479 209.62 19.0452 211.084 18.2753C212.647 17.477 214.372 17.0574 216.124 17.0496C217.875 17.0417 219.604 17.4459 221.174 18.2302V18.2358Z" fill="white"/>
            <path d="M25.5239 30.0646C25.4435 30.065 25.3639 30.0491 25.2897 30.0176L17.485 26.8217C17.3695 26.7744 17.2707 26.6933 17.2011 26.5888C17.1316 26.4843 17.0945 26.3611 17.0947 26.2351V19.1945C17.0951 19.0919 17.1203 18.9909 17.168 18.9003C17.2158 18.8097 17.2847 18.7324 17.3688 18.6749C17.4529 18.6174 17.5496 18.5817 17.6505 18.5706C17.7514 18.5596 17.8534 18.5738 17.9477 18.6118L25.7617 21.866C25.8761 21.9139 25.9738 21.995 26.0426 22.0991C26.1114 22.2032 26.1481 22.3255 26.1483 22.4507V29.433C26.1486 29.5365 26.1236 29.6386 26.0757 29.7301C26.0277 29.8216 25.9582 29.8997 25.8732 29.9575C25.7698 30.0274 25.6482 30.0647 25.5239 30.0646ZM18.3361 25.8159L24.9014 28.4949V22.8718L18.3361 20.1383V25.8159Z" fill="white"/>
            <path d="M42.5033 36.9924C42.4232 36.9922 42.3438 36.9769 42.2691 36.9473L42.1725 36.9059L25.2901 30.0177C25.1743 29.9707 25.075 29.8897 25.0051 29.7852C24.9352 29.6806 24.8979 29.5573 24.898 29.4311V22.8718L17.4686 19.7792C17.3543 19.7313 17.2565 19.6502 17.1877 19.5462C17.119 19.4421 17.0822 19.3198 17.0821 19.1946V0.738717C17.0812 0.634238 17.1061 0.531193 17.1544 0.438865C17.2028 0.346538 17.2731 0.267828 17.359 0.209829C17.4449 0.15183 17.5437 0.116363 17.6465 0.106624C17.7494 0.0968849 17.853 0.11318 17.948 0.15404L42.7411 10.4808C42.8564 10.5277 42.9552 10.6085 43.0248 10.7127C43.0943 10.8169 43.1315 10.9398 43.1314 11.0655V36.3607C43.1314 36.4439 43.1152 36.5262 43.0837 36.6029C43.0522 36.6797 43.006 36.7494 42.9479 36.8081C42.8897 36.8668 42.8206 36.9132 42.7447 36.9449C42.6687 36.9765 42.5873 36.9927 42.5051 36.9924H42.5033ZM26.1504 29.0044L41.8808 35.4226V11.4904L18.3364 1.69187V18.7697L25.7695 21.8642C25.8838 21.9121 25.9815 21.9932 26.0503 22.0972C26.1191 22.2013 26.1559 22.3236 26.156 22.4488L26.1504 29.0044Z" fill="white"/>
            <path d="M25.5239 48.3758C25.4435 48.3762 25.3639 48.3602 25.2897 48.3288L0.496591 38.2107C0.380645 38.1639 0.281264 38.083 0.211345 37.9784C0.141426 37.8738 0.104198 37.7504 0.104494 37.6241V12.1202C0.104721 12.0163 0.130204 11.914 0.178699 11.8224C0.227194 11.7309 0.297211 11.6527 0.382582 11.595C0.467953 11.5372 0.566056 11.5016 0.668249 11.4912C0.770441 11.4808 0.873585 11.4961 0.968593 11.5355L17.9477 18.6118C18.0625 18.6592 18.1607 18.7401 18.2299 18.8443C18.2991 18.9484 18.336 19.071 18.3361 19.1965V25.8178L25.758 28.8465C25.8733 28.8939 25.972 28.9751 26.0415 29.0795C26.111 29.184 26.1482 29.3071 26.1483 29.433V47.7516C26.1483 47.9193 26.0825 48.0802 25.9655 48.199C25.8484 48.3177 25.6896 48.3847 25.5239 48.3852V48.3758ZM1.35511 37.1974L24.9014 46.8116V29.8579L17.4682 26.8292C17.3528 26.782 17.2539 26.7009 17.1844 26.5964C17.1149 26.4918 17.0778 26.3687 17.078 26.2427V19.6176L1.35511 13.0639V37.1974Z" fill="white"/>
            <path d="M0.626736 12.6465C0.492032 12.6472 0.360707 12.6039 0.252366 12.5229C0.144025 12.4419 0.0644766 12.3276 0.0255965 12.1972C-0.0132836 12.0667 -0.00941059 11.927 0.0366374 11.7989C0.0826853 11.6708 0.168439 11.5613 0.281097 11.4866L17.3661 0.1051C17.5043 0.0128584 17.6731 -0.0200468 17.8354 0.0136233C17.9157 0.030295 17.992 0.0628096 18.0599 0.109311C18.1278 0.155812 18.186 0.215388 18.2311 0.284639C18.2763 0.35389 18.3075 0.431459 18.323 0.512917C18.3385 0.594375 18.338 0.678127 18.3215 0.75939C18.3051 0.840654 18.2729 0.917838 18.227 0.986536C18.181 1.05523 18.1221 1.1141 18.0537 1.15977L0.968659 12.5431C0.866964 12.6103 0.748195 12.6462 0.626736 12.6465Z" fill="white"/>
            <path d="M25.4198 22.9752C25.2852 22.9755 25.1542 22.9319 25.0462 22.8507C24.9381 22.7696 24.8589 22.6553 24.8203 22.5249C24.7816 22.3946 24.7856 22.255 24.8317 22.1271C24.8778 21.9993 24.9635 21.8899 25.076 21.8152L42.1592 10.4319C42.2976 10.3476 42.4629 10.3207 42.6204 10.3568C42.778 10.393 42.9157 10.4893 43.0047 10.6257C43.0937 10.7621 43.1272 10.928 43.0981 11.0888C43.069 11.2496 42.9796 11.3928 42.8486 11.4885L25.7636 22.8718C25.6612 22.939 25.5418 22.9749 25.4198 22.9752Z" fill="white"/>
            <path d="M0.62618 38.1524C0.491606 38.1524 0.360617 38.1085 0.252618 38.0273C0.144619 37.946 0.0653555 37.8318 0.0265714 37.7014C-0.0122127 37.571 -0.00845448 37.4315 0.0372894 37.3035C0.0830332 37.1754 0.168329 37.0657 0.28054 36.9905L17.3655 25.6091C17.504 25.5169 17.6731 25.484 17.8355 25.5179C17.9979 25.5517 18.1403 25.6495 18.2315 25.7896C18.3227 25.9297 18.3551 26.1007 18.3216 26.265C18.2882 26.4293 18.1916 26.5734 18.0531 26.6656L0.968103 38.0471C0.866941 38.1157 0.747942 38.1523 0.62618 38.1524Z" fill="white"/>
            <path d="M25.4202 48.2704C25.2858 48.27 25.1551 48.2259 25.0474 48.1445C24.9397 48.0631 24.8608 47.9488 24.8222 47.8186C24.7837 47.6883 24.7876 47.5489 24.8333 47.4211C24.8791 47.2932 24.9644 47.1837 25.0764 47.1086L42.1596 35.7196C42.298 35.6353 42.4633 35.6085 42.6209 35.6446C42.7784 35.6807 42.9161 35.777 43.0051 35.9134C43.0941 36.0498 43.1276 36.2158 43.0985 36.3765C43.0694 36.5373 42.98 36.6805 42.849 36.7762L25.764 48.1652C25.6622 48.2338 25.5426 48.2704 25.4202 48.2704Z" fill="white"/>
            <path d="M170.393 55.1888H166.164L165.387 57.3696H164.053L167.557 47.6181H169.018L172.508 57.3696H171.174L170.393 55.1888ZM170.033 54.1435L168.28 49.1898L166.526 54.1435H170.033Z" fill="#FF6A83"/>
            <path d="M180.004 50.3686C180.579 50.9363 180.866 51.7547 180.866 52.8238V57.3696H179.614V53.0156C179.614 52.2448 179.424 51.6557 179.043 51.2484C178.841 51.0414 178.598 50.8799 178.329 50.7746C178.061 50.6694 177.774 50.6226 177.486 50.6374C177.192 50.623 176.897 50.6719 176.623 50.7807C176.348 50.8896 176.1 51.056 175.894 51.2691C175.5 51.6927 175.303 52.3074 175.303 53.1133V57.3772H174.037V49.6692H175.303V50.7596C175.553 50.365 175.907 50.0481 176.325 49.844C176.769 49.6262 177.257 49.5155 177.75 49.5207C178.675 49.5182 179.427 49.8008 180.004 50.3686Z" fill="#FF6A83"/>
            <path d="M182.939 51.41C183.236 50.834 183.686 50.3539 184.24 50.0245C184.798 49.6937 185.436 49.5234 186.083 49.5319C186.691 49.5162 187.291 49.6669 187.821 49.9681C188.28 50.2255 188.66 50.6052 188.921 51.066V49.6579H190.199V57.3659H188.921V55.9295C188.656 56.4002 188.271 56.7899 187.806 57.0575C187.279 57.3622 186.68 57.5156 186.074 57.5012C185.427 57.509 184.791 57.3339 184.238 56.9955C183.683 56.652 183.233 56.1601 182.937 55.5742C182.614 54.9294 182.451 54.2141 182.465 53.4912C182.448 52.7683 182.611 52.0528 182.939 51.41ZM188.573 51.974C188.357 51.5611 188.032 51.2173 187.633 50.9814C187.235 50.7563 186.788 50.6381 186.332 50.6381C185.877 50.6381 185.429 50.7563 185.031 50.9814C184.636 51.2141 184.313 51.5557 184.102 51.9665C183.861 52.4351 183.742 52.9576 183.755 53.4856C183.742 54.0207 183.861 54.5507 184.102 55.0271C184.314 55.4412 184.635 55.7871 185.031 56.0254C185.426 56.2568 185.876 56.3757 186.332 56.3695C186.788 56.374 187.237 56.2553 187.633 56.0254C188.033 55.7895 188.36 55.4433 188.573 55.0271C188.812 54.5553 188.932 54.0307 188.921 53.5006C188.933 52.9722 188.813 52.4492 188.573 51.9797V51.974Z" fill="#FF6A83"/>
            <path d="M193.607 46.9564V57.3696H192.342V46.9564H193.607Z" fill="#FF6A83"/>
            <path d="M202.328 49.6579L197.738 60.9999H196.437L197.941 57.285L194.867 49.6579H196.272L198.651 55.9051L201.014 49.6579H202.328Z" fill="#FF6A83"/>
            <path d="M205.138 50.7126V55.2584C205.138 55.6344 205.216 55.8995 205.374 56.0536C205.532 56.2078 205.805 56.2867 206.195 56.2867H207.124V57.3696H205.985C205.279 57.3696 204.751 57.2061 204.398 56.8771C204.045 56.5481 203.87 56.0085 203.87 55.2584V50.7126H202.882V49.6579H203.87V47.7159H205.136V49.6579H207.124V50.7126H205.138Z" fill="#FF6A83"/>
            <path d="M208.698 48.152C208.576 48.0299 208.494 47.874 208.46 47.7042C208.427 47.5343 208.444 47.3582 208.51 47.1984C208.575 47.0385 208.687 46.902 208.829 46.8063C208.972 46.7107 209.14 46.6601 209.311 46.6612C209.421 46.6598 209.531 46.6816 209.633 46.7253C209.734 46.769 209.826 46.8336 209.902 46.915C210.058 47.0822 210.145 47.3035 210.145 47.5335C210.145 47.7635 210.058 47.9848 209.902 48.152C209.826 48.2335 209.734 48.2981 209.633 48.3418C209.531 48.3855 209.421 48.4073 209.311 48.4058C209.197 48.4081 209.084 48.3868 208.978 48.3432C208.873 48.2995 208.777 48.2345 208.698 48.152ZM209.922 49.656V57.364H208.657V49.656H209.922Z" fill="#FF6A83"/>
            <path d="M212.079 51.41C212.377 50.8312 212.833 50.3505 213.392 50.0245C213.98 49.6891 214.645 49.519 215.319 49.5319C216.256 49.5319 217.028 49.7613 217.635 50.22C218.25 50.6915 218.677 51.3706 218.839 52.1338H217.475C217.358 51.6852 217.09 51.2921 216.717 51.0228C216.306 50.7398 215.816 50.5969 215.319 50.6148C214.991 50.6024 214.663 50.6633 214.36 50.7932C214.058 50.9232 213.787 51.119 213.567 51.3668C213.121 51.8681 212.899 52.5788 212.9 53.4987C212.901 54.4187 213.124 55.1362 213.567 55.6513C213.786 55.8997 214.057 56.0959 214.36 56.226C214.663 56.356 214.991 56.4165 215.319 56.4033C215.812 56.4218 216.298 56.2838 216.709 56.0085C217.088 55.7359 217.36 55.3363 217.475 54.8805H218.839C218.657 55.6347 218.225 56.3029 217.614 56.7737C216.95 57.2644 216.142 57.5148 215.319 57.4843C214.645 57.4972 213.98 57.3272 213.392 56.9917C212.832 56.6643 212.376 56.1808 212.079 55.5987C211.75 54.9461 211.588 54.2213 211.605 53.4893C211.59 52.7672 211.753 52.0527 212.079 51.41Z" fill="#FF6A83"/>
            <path d="M221.649 57.1929C221.215 57.0148 220.835 56.7235 220.549 56.3488C220.28 55.9886 220.125 55.5548 220.104 55.1042H221.405C221.422 55.2909 221.479 55.4717 221.572 55.634C221.664 55.7963 221.791 55.9363 221.942 56.0442C222.31 56.3037 222.753 56.4326 223.2 56.4108C223.606 56.4338 224.007 56.3201 224.341 56.0874C224.471 55.9954 224.576 55.8732 224.648 55.7311C224.721 55.589 224.758 55.4314 224.758 55.2715C224.763 55.1163 224.724 54.9628 224.645 54.8294C224.566 54.696 224.451 54.5885 224.313 54.5195C223.875 54.3046 223.412 54.1419 222.937 54.0345C222.465 53.9158 222.002 53.7631 221.552 53.5777C221.197 53.4234 220.883 53.1871 220.634 52.8877C220.392 52.5587 220.258 52.1608 220.251 51.7504C220.245 51.3401 220.367 50.9381 220.599 50.6016C220.852 50.2551 221.194 49.9848 221.587 49.8196C222.049 49.6206 222.547 49.5225 223.048 49.5319C223.892 49.5319 224.573 49.7475 225.092 50.1786C225.35 50.3952 225.558 50.666 225.702 50.9721C225.846 51.2782 225.923 51.6124 225.926 51.9515H224.667C224.659 51.7646 224.613 51.5814 224.53 51.4141C224.448 51.2469 224.331 51.0993 224.187 50.9814C223.85 50.7225 223.433 50.5926 223.011 50.6148C222.627 50.5945 222.247 50.6978 221.926 50.91C221.8 50.9945 221.697 51.1097 221.626 51.2451C221.556 51.3804 221.52 51.5316 221.522 51.6845C221.519 51.8001 221.538 51.9153 221.58 52.023C221.622 52.1307 221.685 52.2285 221.766 52.3105C221.941 52.4832 222.15 52.6175 222.379 52.7053C222.714 52.8329 223.055 52.9433 223.401 53.0362C223.855 53.1541 224.3 53.3017 224.735 53.478C225.077 53.6232 225.38 53.8473 225.62 54.1323C225.873 54.4565 226.004 54.8606 225.992 55.2734C225.996 55.6806 225.875 56.079 225.644 56.4127C225.396 56.7642 225.057 57.0394 224.665 57.2079C224.205 57.4069 223.709 57.5049 223.21 57.4956C222.674 57.5041 222.143 57.401 221.649 57.1929Z" fill="#FF6A83"/>
        </g>
        <defs>
            <clipPath id="clip0_2493_911">
                <rect width="226" height="61" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);
