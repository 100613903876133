import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import useToast from '@components/widgets/toast-message/useToast';
import { SocialMediaLogos } from '@constants/socialMedia';
import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import useUpdateEffect from '@hooks/update-effect/useUpdateEffect';
import useGetUserSelectedSite from '@hooks/user/useGetUserSelectedSite';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { DataStatus } from '@shared/enum/dataStatus';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { SocialMediaProfileItem } from '@shared/interfaces/social-media/socialMediaProfiles';
import { resetAddProfile, resetDeleteProfile, selectedFromSocialMedia } from '@store/social-media/social-media-settings/socialMediaSettings.slice';
import { addSocialMediaProfile, deleteSocialMediaProfile, fetchSocialMediaProfiles } from '@store/social-media/social-media-settings/socialMediaSettings.thunk';
import { CancelTokenSource } from 'axios';

import { SocialMediaSettingsOptionLabel } from '../../../features/social-media/settings/add/SocialMediaSettingsAddForm';
import { AddedMedia } from './SocialMediaSettings';

const NetworkLogoMapper = {
    [SocialMediaTypes.FACEBOOK]: SocialMediaLogos.facebook.normal,
    [SocialMediaTypes.INSTAGRAM]: SocialMediaLogos.instagram.normal,
    [SocialMediaTypes.TWITTER]: SocialMediaLogos.twitter.normal,
    [SocialMediaTypes.YOUTUBE]: SocialMediaLogos.youtube.normal,
};

const getSelectOption = (network: SocialMediaTypes) => ({
    label: (
        <SocialMediaSettingsOptionLabel
            image={NetworkLogoMapper[network]}
            title={network}
        />
    ),
    value: network
});

export default function useSocialMediaProfiles({
    setAddedMedia,
    onAddProfileSuccess,
    onDeleteProfileSuccess,
}: {
    setAddedMedia: React.Dispatch<React.SetStateAction<AddedMedia[]>>;
    onAddProfileSuccess?: () => void;
    onDeleteProfileSuccess?: (deletedUrl: string) => void;
}) {
    const dispatch         = useAppDispatch();
    const profiles         = useAppSelector(selectedFromSocialMedia.profiles);
    const addProfile       = useAppSelector(selectedFromSocialMedia.addProfile);
    const deleteProfile    = useAppSelector(selectedFromSocialMedia.deleteProfile);
    const { triggerToast } = useToast();
    const userSite         = useGetUserSelectedSite();

    // get profiles
    const fetchProfiles = (source?: CancelTokenSource) => {
        dispatch(fetchSocialMediaProfiles({
            data: {
                clientId: userSite.clientId
            },
            config: source && source?.token ? {
                cancelToken: source.token
            } : {}
        }));
    };

    useEffect(() => {
        triggerToast('Failed to add new profile!', 'info');

        const source = generateNewCancelTokenSource();

        if (!isEmpty(profiles.data)) {
            return;
        }

        fetchProfiles(source);

        return () => {
            source && source.cancel();
        };
    }, []);

    useUpdateEffect(() => {
        if (profiles?.status === DataStatus.FAILED && profiles?.error) {
            triggerToast(profiles.error, 'error');
        }
    }, [profiles]);

    useEffect(() => {
        if (!profiles || !profiles?.data?.length) {
            return;
        }

        const savedMedia: AddedMedia[] = profiles.data.map((media, idx) => {
            const selectOption = getSelectOption(media.network);

            return {
                id: media.profileId,
                mediaId: idx + 1,
                url: media.url,
                name: media.name,
                network: media.network,
                thumbnail: media.thumbnail,
                defaultUrl: media.url,
                selectedOption: selectOption,
                defaultSelectedOption: selectOption,
                shouldConfirmDelete: true,
                isSelectDisabled: true,
                isActive: media.status,
                isInForm: false,
            } satisfies AddedMedia;
        });

        setAddedMedia(savedMedia);
    }, [profiles.data]);

    // add profile
    const onAddProfile = (profiles: Pick<SocialMediaProfileItem, ('url' | 'network')>[]) => {
        const [client] = window.location.hostname.split('.');

        dispatch(addSocialMediaProfile({ data: {
            profiles: profiles.map(profile => ({
                ...profile,
                network: formatNetwork(profile.network)
            })),
            client,
        } }));
    };

    useUpdateEffect(() => {
        if (addProfile.status === DataStatus.SUCCESS) {
            onAddProfileSuccess?.();
        }

        if (addProfile.status === DataStatus.FAILED) {
            triggerToast(addProfile.error ?? 'Failed to add new profile!', 'error');
        }
    }, [addProfile]);


    // delete profile
    const onDeleteProfile = (url: string) => dispatch(deleteSocialMediaProfile({
        data: {
            url,
            clientId: userSite.clientId
        }
    }));

    useUpdateEffect(() => {
        if (deleteProfile.status === DataStatus.SUCCESS) {
            onDeleteProfileSuccess?.(deleteProfile.url);
        }

        if (deleteProfile.status === DataStatus.FAILED) {
            triggerToast(deleteProfile.error || 'Failed to delete profile!', 'error');
        }
    }, [deleteProfile]);

    return {
        isLoading: profiles.status === DataStatus.LOADING,
        refetchProfiles: fetchProfiles,
        onAddProfile,
        resetProfileAdd: () => {
            dispatch(resetAddProfile());
        },
        isAddProfileLoading: addProfile.status === DataStatus.LOADING,
        onDeleteProfile,
        resetProfileDelete: () => {
            dispatch(resetDeleteProfile());
        },
        isDeleteProfileLoading: deleteProfile.status === DataStatus.LOADING,
    };
}

const formatNetwork = (network: string) => {
    switch (network) {
        case SocialMediaTypes.TWITTER:
            return 'twitter';
        case SocialMediaTypes.FACEBOOK:
            return 'facebook';
        case SocialMediaTypes.INSTAGRAM:
            return 'instagram';
        case SocialMediaTypes.YOUTUBE:
            return 'youtube';
    }
};
