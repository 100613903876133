import decreaseIcon from 'assets/icons/decrease.png';
import increaseIcon from 'assets/icons/increase.png';

export const ComparisonLogos: { decrease: string; increase: string } = {
    decrease: decreaseIcon,
    increase: increaseIcon,
};

export const ComparisonIcons: { decrease: string; increase: string } = {
    decrease: decreaseIcon,
    increase: increaseIcon,
};

export const ComparisonColors: { red: string; green: string; gray: string } = {
    red: '#b22c20',
    green: '#56CABD',
    gray: 'gray',
};
