import Lottie from 'react-lottie';

interface Props {
    width?: number;
    height?: number;
    loop?: boolean;
    autoplay?: boolean;
    stop?: boolean;
    pause?: boolean;
    animationData: Record<string, any>;
}

const ReactLottie = ({ width, height, loop, autoplay, animationData, stop, pause }: Props) => {
    const defaultOptions = {
        loop,
        autoplay,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie
            options={defaultOptions}
            height={height}
            width={width}
            isStopped={stop}
            isPaused={pause}
        />
    );
};

ReactLottie.defaultProps = {
    // width: 400,
    // height: 400,
    loop: true,
    autoplay: true,
    stop: false,
    pause: false,
};

export default ReactLottie;
