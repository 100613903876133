import { addDays, differenceInDays } from 'date-fns';
import moment from 'moment';

import { getSelectedSiteStartDay } from 'services/client-config';
import { PeriodOptions } from 'shared/enum/periodOptions';
import DateInstance from './date';

export const formatPeriod = (period: string | PeriodOptions, reverse = false): string | PeriodOptions => {
    if (!reverse) {
        switch (period as string) {
            case 'Per day':
                return PeriodOptions.Day;
            case 'Per week':
                return PeriodOptions.Week;
            case 'Per month':
                return PeriodOptions.Month;
            default:
                return PeriodOptions.Day;
        }
    }

    switch (period as PeriodOptions) {
        case PeriodOptions.Day:
            return 'Per day';
        case PeriodOptions.Week:
            return 'Per week';
        case PeriodOptions.Month:
            return 'Per month';
        default:
            return 'Per day';
    }
};

export const getWeekStartEnd = (weekNumber: number, year: number): string => (
    moment().day('Monday')
        .week(weekNumber)
        .year(year)
        .format('DD MMM')
    + ' to ' +
    moment().day('Saturday')
        .week(weekNumber)
        .year(year)
        .format('DD MMM')
);

export const getInitialDatePickerDates = () => {
    const startDay       = getSelectedSiteStartDay();
    const clientStartDay = DateInstance.newDate(startDay);

    const endDateTmp = addDays(DateInstance.newDate(), -1);
    let startDateTmp = addDays(endDateTmp, -6);

    if (DateInstance.isDateInRange(clientStartDay, [startDateTmp, endDateTmp])) {
        startDateTmp = clientStartDay;
    }

    const rangeDifference     = differenceInDays(startDateTmp, endDateTmp);
    const compareEndDateTmp   = addDays(startDateTmp, -1);
    const compareStartDateTmp = addDays(compareEndDateTmp, -Math.abs(rangeDifference));

    const endDate          = DateInstance.formatDate({ date: endDateTmp, format: 'yyyy-MM-dd' });
    const startDate        = DateInstance.formatDate({ date: startDateTmp, format: 'yyyy-MM-dd' });
    const compareEndDate   = DateInstance.formatDate({ date: compareEndDateTmp, format: 'yyyy-MM-dd' });
    const compareStartDate = DateInstance.formatDate({ date: compareStartDateTmp, format: 'yyyy-MM-dd' });

    return { startDate, endDate, compareStartDate, compareEndDate };
};

