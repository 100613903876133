import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'shared/interfaces/store/store';

import useDeepCompareEffect from 'hooks/deep-compare-effect/useDeepCompareEffect';

declare interface TrackerError {
    label: string;
    type: string;
    trigger: boolean;
}

function ErrorTracker({ errorTracker, logs }) {
    const [errors, setErrors] = useState<TrackerError[]>([]);

    /* istanbul ignore next */
    useDeepCompareEffect(() => {
        setErrors([]);

        let tracker = errorTracker;

        if (logs) {
            tracker = tracker.filter((item) => {
                if (logs.includes(item.label)) {
                    return item;
                }
            });
        }

        const [hasError] = tracker.filter((item) => item.error);

        if (!hasError) {
            return;
        }

        tracker.forEach((item) => {
            if (item.error) {
                setErrors((prev) => {
                    const newError = {
                        label: `${item.label}: ${item.error}`,
                        type: 'error',
                        trigger: true,
                    };

                    for (const prevItem of prev){
                        if (prevItem?.label === newError.label) {
                            return prev;
                        }
                    }

                    return [...prev, newError];
                });
            }
        });
    }, [errorTracker]);

    /* istanbul ignore next */
    const itemTriggered = (label, index) => {
        setErrors((prev) => {
            let e: TrackerError;
            const next          = prev;

            if (next[index].label === label) {
                e = {
                    ...next[index],
                    trigger: false,
                };

                next[index] = e as TrackerError;
            }

            return next;
        });
    };

    /* istanbul ignore next */
    const resetToast = (label: string) => {
        setErrors(
            (error) =>
                error &&
                error.map((e) => {
                    if (e.label === label) {
                        return {
                            ...e,
                            triggered: false,
                        };
                    } else {
                        return e;
                    }
                }),
        );
    };

    return (
        <div className="dashboard_toast" data-testid="tr-wrapper">
            {/* {errors &&
                errors.map((error, index) => {
                    if (error.trigger) {
                        itemTriggered(error.label, index);

                        return (
                            <Toast
                                key={error.label}
                                toastText={error.label}
                                toastType={error.type}
                                trigger={error.trigger}
                                onReset={resetToast}
                            />
                        );
                    } else {
                        return null;
                    }
                })} */}
        </div>
    );
}

export const mapStateToProps = (state: RootState) => ({
    errorTracker: [
        { label: 'Live Component', error: state.liveReducer.error },
        { label: 'Session per country', error: state.countriesReducer.error },
        { label: 'Session per device', error: state.devicesReducer.error },
        { label: 'Users per hour', error: state.visitorsPerHourReducer.error },
        { label: 'Top content', error: state.topContentReducer.error },
        { label: 'top author by pageviews', error: state.authorReducer.error },
        { label: 'In depth', error: state.depthRateReducer.error },
        { label: 'Visitor behavior', error: state.visitorBehaviorReducer.funnelError },
        { label: 'Browsers', error: state.browsersReducer.error },
        { label: 'In depth and heatmap', error: state.inHeatReducer.error },

        { label: 'Audience', error: state.viewsReducer.error },
        { label: 'Impact score', error: state.impactScoreReducer.error },
        { label: 'Conversion', error: state.conversionsReducer.error },
    ],
});

ErrorTracker.propTypes = {
    logs: PropTypes.array,
};

export default connect(mapStateToProps, null)(ErrorTracker);
