import React, { useState } from 'react';

import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import './Button.sass';

declare interface ButtonProps {
    children: React.ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
    role?: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    style?: React.CSSProperties;
    classes?: string;
    callback: () => void;
}

const Button = ({ children, isActive, isDisabled, role, type, style, classes, callback }: ButtonProps) => {
    const [btnActive, setIsActive] = useState(isActive || false);

    useUpdateEffect(() => setIsActive(isActive), [isActive]);

    return (
        <button
            role={role}
            type={type}
            style={style}
            disabled={isDisabled}
            className={'btn ' + classes + (btnActive ? ' btn-active' : '')}
            onClick={() => {
                setIsActive(prev => !prev);

                callback();
            }}
        >
            {children}
        </button >
    );
};

Button.defaultProps = {
    role: 'button',
    type: 'button',
    children: null,
    isActive: false,
    isDisabled: false,
    classes: 'btn',
    callback: () => null,
};

export default Button;
