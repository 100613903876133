import { useNavigate } from 'react-router-dom';

import Img from '@components/common/img/Img';
import { DefinedRoutes } from '@routes/definedRoutes';

import './AppHeaderSearchResultsItem.sass';

declare interface AppHeaderSearchResultsItemProps {
    image?: string;
    text: string;
    url: string;
}

const AppHeaderSearchResultsItem = ({ image, text, url }: AppHeaderSearchResultsItemProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        const routeUrl     = `${DefinedRoutes.pages.page.path}?pageUrl=${url}`;

        return navigate(routeUrl);
    };

    return (
        <div
            className="app-header-search-results-item"
            data-testid="hs-item"
            onClick={handleClick}
        >
            {image ? <Img src={image} alt={'page image'} /> : null}
            <p>{text}</p>
        </div>
    );
};

AppHeaderSearchResultsItem.defaultProps = {
    image: '',
    text: '',
};

export default AppHeaderSearchResultsItem;
