import React, { useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import PrimaryButton from '@components/common/buttons/primary-button/PrimaryButton';
import Show from '@components/common/condition/Show';
import Config from '@config/config';
import logger from '@middleware/logger';
import { clearCacheExceptCookies, handleHardReload } from '@services/cache/cache';

import './AppRoutesBoundary.sass';

export default function AppRoutesBoundary({ children }: { children: React.ReactNode }) {
    const [isDynamicFetchError, setIsDynamicFetchError] = useState(false);

    return (
        <>
            <ReactErrorBoundary
                FallbackComponent={(props) => (
                    <ErrorBoundaryFallback isDynamicFetchError={isDynamicFetchError} {...props}/>
                )}
                onError={(error, info: { componentStack: string }) => {
                    const shouldReload = error.message.includes(
                        'Failed to fetch dynamically imported module'
                    );

                    setIsDynamicFetchError(shouldReload);

                    // if (shouldReload) {
                    //     clearCacheExceptCookies();
                    //     handleHardReload(window.location.href);

                    //     return;
                    // }

                    ErrorBoundaryHandler(error, info);
                }}
                onReset={() => {
                    if (isDynamicFetchError) {
                        clearCacheExceptCookies();
                        handleHardReload(window.location.href);
                    }
                    // reset state here
                }}
            >
                {children}
            </ReactErrorBoundary>
        </>
    );
}

const ErrorBoundaryHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
    logger.error(error, info);
};

declare interface ErrorBoundaryFallbackProps {
    isDynamicFetchError: boolean;
    error: Error;
    resetErrorBoundary: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const { isDevelopment } = Config;

function ErrorBoundaryFallback({ isDynamicFetchError, error, resetErrorBoundary }: ErrorBoundaryFallbackProps) {
    const showLogs = isDevelopment || window.location.hostname.includes('.inkylab.com');

    return (
        <div className='app-routes-boundary' role="alert">
            <div>
                <Show>
                    <Show.When isTrue={isDynamicFetchError}>
                        <h1>Update</h1>
                        <p>A new version of the application is available, click the reload button update.</p>
                    </Show.When>
                    <Show.When isTrue={showLogs}>
                        <h1>Something went wrong!</h1>
                        <pre>Name: {error.name || 'N/A'}</pre>
                        <pre>Cause: {String(error?.cause) || 'N/A'}</pre>
                        <pre>Stack: {error?.stack || 'N/A'}</pre>
                        <pre>Message: {error.message}</pre>
                    </Show.When>
                    <Show.Else>
                        <h1>Something went wrong!</h1>
                        <p>Please contact an administrator if this error persists.</p>
                    </Show.Else>
                </Show>
                <PrimaryButton onClick={resetErrorBoundary}>
                    {isDynamicFetchError ? 'Reload' : 'Try again'}
                </PrimaryButton>
            </div>
        </div>
    );
}
