import { ReactNode } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

declare interface ContentLoaderProps extends IContentLoaderProps {
    children: ReactNode;
}

const ContentLoaderSkeleton = ({ children, ...rest }: ContentLoaderProps) => (
    <ContentLoader {...rest} >
        {children}
    </ContentLoader>
);

export default ContentLoaderSkeleton;
