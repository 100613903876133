import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { AgePerDay, AgePerGender, Gender, GenderPerDay } from 'shared/interfaces/store/age & gender';

import {
    AGE_DATA_IS_LOADING,
    AGE_GET_AGE_PER_GENDER,
    AGE_GET_AGE_PER_GENDER_FAILURE,
    AGE_PER_DAY_DATA_IS_LOADING,
    AGE_PER_DAY_GET_GENDER_FAILURE,
    AGE_PER_DAY_GET_GENDER_SUCCESS,
    GENDER_DATA_IS_LOADING,
    GENDER_GET_GENDER,
    GENDER_GET_GENDER_FAILURE,
    GENDER_PER_DAY_DATA_IS_LOADING,
    GENDER_PER_DAY_GET_GENDER_FAILURE,
    GENDER_PER_DAY_GET_GENDER_SUCCESS
} from './genderAge.types';

export declare interface GenderAgeState {
    age: AgePerGender;
    ageRequestSource: CancelTokenSource;
    ageIsLoading: boolean;
    ageError: string;

    agePerDay: AgePerDay;
    agePerDayRequestSource: CancelTokenSource;
    agePerDayIsLoading: boolean;
    agePerDayError: string;

    gender: Gender;
    genderRequestSource: CancelTokenSource;
    genderIsLoading: boolean;
    genderError: string;

    genderPerDay: GenderPerDay;
    genderPerDayRequestSource: CancelTokenSource;
    genderPerDayIsLoading: boolean;
    genderPerDayError: string;
}

const initialState: GenderAgeState = {
    age: null,
    ageIsLoading: false,
    ageRequestSource: null,
    ageError: null,

    agePerDay: null,
    agePerDayRequestSource: null,
    agePerDayIsLoading: false,
    agePerDayError: null,

    gender: null,
    genderIsLoading: false,
    genderRequestSource: null,
    genderError: null,

    genderPerDay: null,
    genderPerDayIsLoading: false,
    genderPerDayRequestSource: null,
    genderPerDayError: null,
};

const genderAgeReducer = function (state: GenderAgeState = initialState, action: ReducerAction): GenderAgeState {
    switch (action.type) {
        case AGE_DATA_IS_LOADING: {
            return {
                ...state,
                ageIsLoading: true,
            };
        }

        case AGE_GET_AGE_PER_GENDER: {
            return {
                ...state,
                age: action.payload.data,
                ageRequestSource: action.payload.requestSource,
                ageError: null,
                ageIsLoading: false,
            };
        }

        case AGE_GET_AGE_PER_GENDER_FAILURE:
            return {
                ...state,
                ageError: action.payload.message,
                ageIsLoading: false,
            };

        case AGE_PER_DAY_DATA_IS_LOADING: {
            return {
                ...state,
                agePerDayIsLoading: true,
            };
        }

        case AGE_PER_DAY_GET_GENDER_SUCCESS: {
            return {
                ...state,
                agePerDay: action.payload.data,
                agePerDayRequestSource: action.payload.requestSource,
                agePerDayError: null,
                agePerDayIsLoading: false,
            };
        }

        case AGE_PER_DAY_GET_GENDER_FAILURE:
            return {
                ...state,
                agePerDayError: action.payload.message,
                agePerDayIsLoading: false,
            };

        case GENDER_DATA_IS_LOADING: {
            return {
                ...state,
                genderIsLoading: true,
            };
        }

        case GENDER_GET_GENDER: {
            return {
                ...state,
                gender: action.payload.data,
                genderRequestSource: action.payload.requestSource,
                genderError: null,
                genderIsLoading: false,
            };
        }

        case GENDER_GET_GENDER_FAILURE:
            return {
                ...state,
                genderError: action.payload.message,
                genderIsLoading: false,
            };

        case GENDER_PER_DAY_DATA_IS_LOADING: {
            return {
                ...state,
                genderPerDayIsLoading: true,
            };
        }

        case GENDER_PER_DAY_GET_GENDER_SUCCESS: {
            return {
                ...state,
                genderPerDay: action.payload.data,
                genderPerDayRequestSource: action.payload.requestSource,
                genderPerDayError: null,
                genderPerDayIsLoading: false,
            };
        }

        case GENDER_PER_DAY_GET_GENDER_FAILURE:
            return {
                ...state,
                genderPerDayError: action.payload.message,
                genderPerDayIsLoading: false,
            };

        default: {
            return state;
        }
    }
};

export default genderAgeReducer;
