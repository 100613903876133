
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataStatus } from '@shared/enum/dataStatus';
import { RootState } from '@store/storeConfig';

export declare interface PageDownloadState {
    forcePageLoad: boolean;
    pageLoaded: boolean;
    error: string | null;
    status: DataStatus;
}

const initialState: PageDownloadState = {
    forcePageLoad: false,
    pageLoaded: false,
    error: null,
    status: DataStatus.IDLE,
};

export const pageDownloadSlice = createSlice({
    name: 'pageDownload',
    initialState,
    reducers: {
        clearState: () => initialState,
        setPageDownloadForceLoad: (state, action?: PayloadAction<boolean>) => {
            state.forcePageLoad = action.payload;
        },
        setPageDownloadPageLoad: (state, action?: PayloadAction<boolean>) => {
            state.pageLoaded = action.payload;
        },
        setPageDownloadError: (state, action?: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setPageDownloadStatus: (state, action?: PayloadAction<DataStatus>) => {
            state.status = action.payload;
        },
        setPageDownload: (state, action?: PayloadAction<PageDownloadState>) => {
            state.forcePageLoad = action.payload.forcePageLoad;
            state.pageLoaded    = action.payload.pageLoaded;
            state.error         = action.payload.error;
            state.status        = action.payload.status;
        },
    },
});

export const {
    clearState,
    setPageDownloadForceLoad,
    setPageDownloadPageLoad,
    setPageDownloadError,
    setPageDownloadStatus,
    setPageDownload,
} = pageDownloadSlice.actions;
export const selectFromPageDownload = {
    all: (state: RootState) => ({
        forcePageLoad: state.pageDownloadReducer.forcePageLoad,
        pageLoaded: state.pageDownloadReducer.pageLoaded,
        error: state.pageDownloadReducer.error,
        status: state.pageDownloadReducer.status,
    }),
};
const pageDownloadReducer = pageDownloadSlice.reducer;

export default pageDownloadReducer;
