import classNames from 'classnames';
import { ReactNode } from 'react';

import './TabsViewItem.scss';

export interface TabsViewItemProps {
    title: string | ReactNode;
    id: string;
    isActive: boolean;
    onClick: (id: string) => void;
}

const TabsViewItem = ({ id, title, isActive, onClick }: TabsViewItemProps) => {
    const handleClick = () => onClick?.(id);

    return (
        <button
            onClick={handleClick}
            id={id}
            type="button"
            className={classNames('settings-nav-item', {
                active: isActive
            })}
        >
            {title}
        </button>
    );
};

export default TabsViewItem;
