import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from '@services/axios/axios';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { queryBuilder } from '@services/url/url';
import { ActionError } from '@shared/enum/actionError';
import { AppDispatch, RootState } from '@store/storeConfig';

import { handleAsyncThunkError } from './handleAsyncThunkError';

interface AsyncThunkConfig {
    state?: RootState;
    dispatch?: AppDispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
}

interface WithCreateAsyncThunkOptions {
    endpoint: string;
    typePrefix: string;
}

type WithCreateAsyncThunkReturnType<Returned, ThunkArg> = ReturnType<typeof createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>>;

export function createAsyncThunkWithGet<
    AxiosReturn = any,
    Options = Record<string, any>
>({
    endpoint,
    typePrefix
}: WithCreateAsyncThunkOptions): WithCreateAsyncThunkReturnType<AxiosReturn, Options> {
    const source = generateNewCancelTokenSource();

    // eslint-disable-next-line
    // @ts-ignore
    return createAsyncThunk<AxiosReturn, Options, AsyncThunkConfig>(typePrefix, async (options = {}, thunkApi) => {
        const url    = queryBuilder(endpoint, options);

        try {
            const response = await axiosService.get<AxiosReturn>(url, {
                cancelToken: source.token,
            });

            if (!response || !response?.data) {
                return thunkApi.rejectWithValue(ActionError.NoResponse as string);
            }

            return thunkApi.fulfillWithValue({
                data: response.data,
                source,
            });
        } catch (error: unknown) {
            const message = handleAsyncThunkError(error);

            return thunkApi.rejectWithValue(message);
        }
    });
}
