import type { OverviewMentions } from '@shared/interfaces/store/mentions/overviewMentions';

import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from '@shared/enum/dataStatus';
import { AllDomains } from '@shared/interfaces/store/mentions';
import { AllMentions } from '@shared/interfaces/store/mentions/allMentions';
import { Mentions } from '@shared/interfaces/store/mentions/mentions';
import { TooltipParamDataAsObject } from '@shared/interfaces/tooltip/tooltipParamData';
import { RootState } from '@store/storeConfig';
import { formatFetchMentions } from './mentions.format';
import { blockMentionDomain, deleteMention, fetchAllDomains, fetchAllMentions, fetchMentions, fetchOverviewMentions } from './mentions.thunk';

export declare interface MentionsState {
    overviewMentionsData: OverviewMentions;
    overviewMentionsError: string | null;
    overviewMentionsIsLoading: boolean;

    mentionsData: Mentions<TooltipParamDataAsObject>;
    mentionsError: string | null;
    mentionsStatus: DataStatus;

    allMentionsData: AllMentions;
    allMentionsError: string | null;
    allMentionsStatus: DataStatus;

    deleteMention: any;
    deleteMentionError: string | null;
    deleteMentionStatus: DataStatus;

    blockMentionDomain: any;
    blockMentionDomainError: string | null;
    blockMentionDomainStatus: DataStatus;

    allDomains: AllDomains;
    allDomainsError: string | null;
    allDomainsStatus: DataStatus;
}

const initialState: MentionsState = {
    overviewMentionsData: null,
    overviewMentionsError: null,
    overviewMentionsIsLoading: false,

    mentionsData: null,
    mentionsError: null,
    mentionsStatus: DataStatus.IDLE,

    allMentionsData: null,
    allMentionsError: null,
    allMentionsStatus: DataStatus.IDLE,

    deleteMention: null,
    deleteMentionError: null,
    deleteMentionStatus: DataStatus.IDLE,

    blockMentionDomain: null,
    blockMentionDomainError: null,
    blockMentionDomainStatus: DataStatus.IDLE,

    allDomains: null,
    allDomainsError: null,
    allDomainsStatus: DataStatus.IDLE,
};

export const mentionsSlice = createSlice({
    name: 'mentions',
    initialState,
    reducers: {
        clearState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // overview mentions
            .addCase(fetchOverviewMentions.pending, (state) => {
                state.overviewMentionsIsLoading = true;
            })
            .addCase(fetchOverviewMentions.fulfilled, (state, action) => {
                state.overviewMentionsData      = action.payload;
                state.overviewMentionsIsLoading = false;
                state.overviewMentionsError     = null;
            })
            .addCase(fetchOverviewMentions.rejected, (state, action) => {
                state.overviewMentionsError     = action.payload as unknown as string;
                state.overviewMentionsIsLoading = false;
            })

            // mentions
            .addCase(fetchMentions.pending, (state) => {
                state.mentionsStatus = DataStatus.LOADING;
            })
            .addCase(fetchMentions.fulfilled, (state, action) => {
                state.mentionsData   = formatFetchMentions(action.payload);
                state.mentionsError  = null;
                state.mentionsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchMentions.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.mentionsError  = null;

                    return;
                }

                state.mentionsStatus = DataStatus.FAILED;
                state.mentionsError  = action.payload as unknown as string;
            })

            // all mentions
            .addCase(fetchAllMentions.pending, (state) => {
                state.allMentionsStatus = DataStatus.LOADING;
            })
            .addCase(fetchAllMentions.fulfilled, (state, action) => {
                state.allMentionsData   = action.payload;
                state.allMentionsError  = null;
                state.allMentionsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchAllMentions.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.allMentionsError = null;

                    return;
                }

                state.allMentionsError  = action.payload as unknown as string;
                state.allMentionsStatus = DataStatus.FAILED;
            })

            // delete mention
            .addCase(deleteMention.pending, (state) => {
                state.deleteMentionStatus = DataStatus.LOADING;
            })
            .addCase(deleteMention.fulfilled, (state, action) => {
                state.deleteMention       = action.payload;
                state.deleteMentionStatus = DataStatus.SUCCESS;
                state.deleteMentionError  = null;
            })
            .addCase(deleteMention.rejected, (state, action) => {
                state.deleteMentionError  = action.payload as unknown as string;
                state.deleteMentionStatus = DataStatus.FAILED;
            })

            // block mention domain
            .addCase(blockMentionDomain.pending, (state) => {
                state.blockMentionDomainStatus = DataStatus.LOADING;
            })
            .addCase(blockMentionDomain.fulfilled, (state, action) => {
                state.blockMentionDomain       = action.payload;
                state.blockMentionDomainStatus = DataStatus.SUCCESS;
                state.blockMentionDomainError  = null;
            })
            .addCase(blockMentionDomain.rejected, (state, action) => {
                state.blockMentionDomainError  = action.payload as unknown as string;
                state.blockMentionDomainStatus = DataStatus.FAILED;
            })

            // all domains
            .addCase(fetchAllDomains.pending, (state) => {
                state.allDomainsStatus = DataStatus.LOADING;
            })
            .addCase(fetchAllDomains.fulfilled, (state, action) => {
                state.allDomains       = action.payload;
                state.allDomainsError  = null;
                state.allDomainsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchAllDomains.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.allDomainsError = null;

                    return;
                }

                state.allDomainsError  = action.payload as unknown as string;
                state.allDomainsStatus = DataStatus.FAILED;
            });
    },
});

const {
    clearState,
} = mentionsSlice.actions;

export { clearState };
export const selectFromMentions = {
    overviewMentions: (state: RootState) => ({
        data: state.overviewMentionsReducer.overviewMentionsData,
        error: state.overviewMentionsReducer.overviewMentionsError,
        isLoading: state.overviewMentionsReducer.overviewMentionsIsLoading
    }),
    mentions: (state: RootState) => ({
        data: state.overviewMentionsReducer.mentionsData,
        status: state.overviewMentionsReducer.mentionsStatus,
        error: state.overviewMentionsReducer.mentionsError,
    }),
    allMentions: (state: RootState) => ({
        data: state.overviewMentionsReducer.allMentionsData,
        status: state.overviewMentionsReducer.allMentionsStatus,
        error: state.overviewMentionsReducer.allMentionsError,
    }),
    deleteMention: (state: RootState) => ({
        data: state.overviewMentionsReducer.deleteMention,
        status: state.overviewMentionsReducer.deleteMentionStatus,
        error: state.overviewMentionsReducer.deleteMentionError,
    }),
    blockMentionDomain: (state: RootState) => ({
        data: state.overviewMentionsReducer.blockMentionDomain,
        status: state.overviewMentionsReducer.blockMentionDomainStatus,
        error: state.overviewMentionsReducer.blockMentionDomainError,
    }),
    allDomains: (state: RootState) => ({
        data: state.overviewMentionsReducer.allDomains,
        status: state.overviewMentionsReducer.allDomainsStatus,
        error: state.overviewMentionsReducer.allDomainsError,
    }),
};

const overviewMentionsReducer = mentionsSlice.reducer;

export default overviewMentionsReducer;
