import { CSSProperties, useState } from 'react';

import { PrimaryButton } from '@components/common';
import { Copy, OutlineClose } from '@components/svg';
import { Popup } from '@components/widgets';
import { copyTextToClipboard } from '@services/helper/helper';

import './SocialMediaSubmitFeedback.scss';

interface Props {
    shouldShow: boolean;
    onClose: () => void;
}

const SocialMediaSubmitFeedback = ({ shouldShow, onClose }: Props) => (
    <Popup contentClassName={'smsc-popup'} trigger={shouldShow} onFocusOut={onClose} shouldCloseOnFocusOut>
        <div className='smsc-container'>
            <OutlineClose className='smsc-close' onClick={onClose}/>


            <h2>Add profiles</h2>

            <SocialMediaSubmitFeedbackCopyLink />

            <div className="smsc-actions">
                <PrimaryButton onClick={onClose}>
                    Ok
                </PrimaryButton>
            </div>
        </div>
    </Popup>
);

export const SocialMediaSubmitFeedbackCopyLink = ({ paragraphStyle, style }: { paragraphStyle?: CSSProperties; style?: CSSProperties }) => {
    const [isCopied, setIsCopied] = useState(false);
    const link                    = 'https://www.fanpagekarma.com/permissions?invite=6614558775148544&h=542505354';

    const handleCopy = () => {
        copyTextToClipboard(link);

        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    return (
        <>
            <p className='smsc-copy-desc' style={paragraphStyle}>
                Click on the link bellow or send it to someone who has permissions for profiles you want to add to your account
                and ask them to approve it:
            </p>

            <div className="smsc-copy" style={style}>
                <a
                    href={link}
                    target='_blank'
                >{link}</a>
                {isCopied ? <p>Link copied!</p> : <Copy onClick={handleCopy}/>}
            </div>
        </>
    );
};

export default SocialMediaSubmitFeedback;
