import { Mentions, MentionsChart } from '@shared/interfaces/store/mentions/mentions';
import { MentionsChartPointsListItem } from '@shared/interfaces/store/mentions/overviewMentions';
import { TooltipParamDataAsObject } from '@shared/interfaces/tooltip/tooltipParamData';
import { Entries } from '@shared/types/entries';

function formatMentionsChart(chart: MentionsChartPointsListItem[]): TooltipParamDataAsObject[] {
    return chart?.map((point) => ({
        value: point.CurrentValue,
        compare: {
            percentage: point.Compare.percentage,
            diff: point.Compare.diff,
            pastPercentageOrValue: point.PastValue
        }
    })) ?? [];
}

function formatMentionsCharts(charts: MentionsChart): MentionsChart<TooltipParamDataAsObject> {
    type EntriesData = { [key in keyof typeof charts ]: TooltipParamDataAsObject[] };

    const result = Object.fromEntries(
        Object.entries(charts).map((chartItem) => {
            const [key, chart] = chartItem;

            return [
                key,
                formatMentionsChart(chart)
            ];
        }) as Entries<EntriesData>
    );

    return result as MentionsChart<TooltipParamDataAsObject>;
}

export function formatFetchMentions(mentions: Mentions): Mentions<TooltipParamDataAsObject> {
    const date = (mentions.oldest_date || '').slice(0, 10);

    return {
        ...mentions,
        oldest_date: date,
        chart: formatMentionsCharts(mentions.chart)
    };
}
