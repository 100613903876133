import React from 'react';

import PageHeader from '@components/common/headers/page-header/PageHeader';
import SiteHelmet from '@components/common/site-helmet/SiteHelmet';
import ErrorTracker from 'errors/error-tracker/ErrorTracker';
import './PageWrapper.sass';

declare interface PageWrapperProps {
    headerInfo?: React.ReactNode;
    headerNavigation?: React.ReactNode;
    headerDisableSearch?: boolean;
    disableDownloadReport?: boolean;
    publicationDate?: React.ReactNode;
    wrapperId?: string;
    pageTitle: string;
    filter?: React.ReactNode;
    logs?: string[];
    children: React.ReactNode;
    shouldHideFilters?: boolean;
    ref?: (node?: Element) => void;
}

const PageWrapper = ({
    headerInfo,
    headerNavigation,
    headerDisableSearch,
    disableDownloadReport,
    publicationDate,
    wrapperId,
    filter,
    logs,
    children,
    pageTitle,
    shouldHideFilters,
    ref
}: PageWrapperProps) => (
    <div className={'page-wrapper'} id={wrapperId} ref={ref}>
        <PageHeader
            headerInfo={headerInfo}
            headerNavigation={headerNavigation}
            headerDisableSearch={headerDisableSearch}
            disableDownloadReport={disableDownloadReport}
            publicationDate={publicationDate}
            shouldHideFilters={shouldHideFilters}
        />
        <SiteHelmet title={pageTitle} />
        {filter}

        <div className="widgets-wrapper">
            {children}
        </div>

        <ErrorTracker logs={logs} />
    </div>
);

export default PageWrapper;
