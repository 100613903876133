import axios, { AxiosRequestConfig } from 'axios';

import { PayloadAction } from '@reduxjs/toolkit';
import { isErrorWithMessage } from '@services/error-handler/errorHandler';
import { ActionError } from '@shared/enum/actionError';
import { APIError, instanceOfAPIError } from '@shared/interfaces/apiError';

export function handleAsyncThunkError(error: unknown): string {
    if (axios.isAxiosError(error)) {
        const data       = error?.response?.data as APIError;
        const statusCode = error?.response?.status;

        if (
            instanceOfAPIError(data) ||
            statusCode === 500 ||
            String(statusCode).startsWith('4')
        ) {
            return data?.error || data?.message || ActionError.ServerError;
        }
    }

    if (isErrorWithMessage(error)) {
        return error.message;
    }

    return String(error);
}

export type ThunkPayload = PayloadAction<any, string, {
    arg: {
        data?: string;
        config?: AxiosRequestConfig;
    };
    requestId: string;
    requestStatus: "fulfilled" | "rejected";
}, any>


export function getAsyncThunkErrorMessage(result: ThunkPayload) {
    if(typeof result?.error === 'string') {
        return result.error;
    }

    if(result?.error?.message) {
        return result?.error?.message;
    }

    return result.payload?.message || result.payload?.error || String(result.payload) || 'N/A';
}
