import useSidebar from 'hooks/sidebar/useSidebar';
import './HamburgerMenu.sass';

const HamburgerMenu = () => {
    const sidebar = useSidebar();

    return (
        <div onClick={() => sidebar.toggleStatus(true, 'mobile')} className="hb-menu-wrapper">
            <div className="hb-menu-container">
                <MenuLine />
                <MenuLine />
                <MenuLine />
            </div>
        </div>
    );
};

const MenuLine = () => (
    <div className={'hb-menu-line'}>
        <div></div>
        <div></div>
    </div>
);

export default HamburgerMenu;
