import classNames from 'classnames';
import './SocialMediaProfileAvatar.scss';

interface Props {
    thumbnail: string;
    networkThumbnail?: string;
    className?: string;
}

const SocialMediaProfileAvatar = ({ className, thumbnail, networkThumbnail }: Props) => (
    <div className={classNames('social-media-avatar', { noIcon: !networkThumbnail,[className]: !!className })}>
        <img src={thumbnail} alt="profile thumbnail" />
        {networkThumbnail && <img src={networkThumbnail} alt="media thumbnail" />}
    </div>
);

export default SocialMediaProfileAvatar;
