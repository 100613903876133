import type { CancelTokenStatic } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';

import {
    SITE_LOADER_HIDE,
    SITE_LOADER_TOGGLE
} from './siteLoader.types';

export declare interface SiteLoaderState {
    show: boolean;
    label: string;
    source: CancelTokenStatic | string;
}


const initialState: SiteLoaderState = {
    show: false,
    label: '',
    source: null,
};

const siteLoaderReducer = function (state: SiteLoaderState = initialState, action: ReducerAction): SiteLoaderState {
    switch (action.type) {
        case SITE_LOADER_TOGGLE: {
            return {
                show: action.payload.show,
                label: action.payload.label,
                source: action.payload.source,
            };
        }

        case SITE_LOADER_HIDE: {
            return {
                ...initialState,
                show: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default siteLoaderReducer;
