import type { DatepickerRangeSelection } from 'shared/interfaces/datepicker/datepickerRangeSelection';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { DatePicker, DatePickerItem } from 'shared/interfaces/store/datePicker';

import { RootState } from '@store/storeConfig';
import DateInstance from 'services/date/date';
import { getInitialDatePickerDates } from 'services/date/dateUtils';
import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import {
    DATEPICKER_RESET_DATES,
    DATEPICKER_SET_ACTIVE_SHORTCUT, DATEPICKER_SET_COMPARE_DATE, DATEPICKER_SET_DATE, DATEPICKER_SET_DATES, DATEPICKER_SET_ERROR,
    DATEPICKER_SET_SELECTION_RANGE,
    DATEPICKER_TOGGLE_COMPARE,
    DATEPICKER_TOGGLE_DISABLE
} from './datePicker.types';

export declare interface DatePickerState {
    default: DatePicker;
    current: DatePickerItem;
    previous: DatePickerItem;
    /**
     * self: state changed from the datepicker component.
     * component: state changed from any other component or page.
     * unknown: changer component didn't identify itself.
     */
    updatedFrom?: 'self' | 'component' | 'HOC' | 'unknown';

    rangePickerSelection?: DatepickerRangeSelection;
    activeShortcut?: DatepickerShortcuts;
    compareStatus: boolean;
    disabled: boolean;

    error: null | string;
}

const { startDate, endDate, compareStartDate, compareEndDate } = getInitialDatePickerDates();

const initialState: DatePickerState                            = {
    default: {
        current: [startDate, endDate],
        previous: [compareStartDate, compareEndDate],
    },
    current: [startDate, endDate],
    previous: [compareStartDate, compareEndDate],
    updatedFrom: 'self',
    rangePickerSelection: {
        selection1: {
            startDate: DateInstance.newDate(startDate),
            endDate: DateInstance.newDate(endDate),
            key: 'selection1',
        },
        selection2: {
            startDate: DateInstance.newDate(compareStartDate),
            endDate: DateInstance.newDate(compareEndDate),
            key: 'selection2',
        },
    },
    activeShortcut: DatepickerShortcuts.Last7Days,
    compareStatus: false,
    disabled: false,
    error: null,
};

const datePickerReducer = function (state: DatePickerState = initialState, action: ReducerAction): DatePickerState {
    switch (action.type) {
        case DATEPICKER_SET_ERROR: {
            return {
                ...state,
                error: action.payload,
            };
        }

        case DATEPICKER_TOGGLE_COMPARE: {
            return {
                ...state,
                compareStatus: action.payload || !state.compareStatus,
            };
        }

        case DATEPICKER_SET_ACTIVE_SHORTCUT: {
            return {
                ...state,
                activeShortcut: action.payload,
                error: null,
            };
        }

        case DATEPICKER_SET_SELECTION_RANGE: {
            return {
                ...state,
                rangePickerSelection: action.payload,
                error: null,
            };
        }

        case DATEPICKER_SET_DATES: {
            return {
                ...state,
                current: action.payload.current,
                previous: action.payload.previous,
                rangePickerSelection: {
                    selection1: {
                        startDate: DateInstance.parseDate(action.payload.current[0], 'yyyy-MM-dd', DateInstance.newDate()),
                        endDate: DateInstance.parseDate(action.payload.current[1], 'yyyy-MM-dd', DateInstance.newDate()),
                        key: 'selection1',
                    },
                    selection2: {
                        startDate: DateInstance.parseDate(action.payload.previous[0], 'yyyy-MM-dd', DateInstance.newDate()),
                        endDate: DateInstance.parseDate(action.payload.previous[1], 'yyyy-MM-dd', DateInstance.newDate()),
                        key: 'selection2',
                    },
                },
                updatedFrom: action.payload.updatedFrom,
                error: null,
            };
        }

        case DATEPICKER_SET_DATE: {
            return {
                ...state,
                current: action.payload.date,
                updatedFrom: action.payload.updatedFrom,
                error: null,
            };
        }

        case DATEPICKER_SET_COMPARE_DATE: {
            return {
                ...state,
                previous: action.payload.date,
                updatedFrom: action.payload.updatedFrom,
                error: null,
            };
        }

        case DATEPICKER_TOGGLE_DISABLE: {
            return {
                ...state,
                disabled: action.payload || false
            };
        }

        case DATEPICKER_RESET_DATES: {
            return {
                ...state,
                current: action.payload.current,
                previous: action.payload.previous,
                rangePickerSelection: {
                    selection1: {
                        startDate: DateInstance.newDate(action.payload.current[0]),
                        endDate: DateInstance.newDate(action.payload.current[1]),
                        key: 'selection1',
                    },
                    selection2: {
                        startDate: DateInstance.newDate(action.payload.previous[0]),
                        endDate: DateInstance.newDate(action.payload.previous[1]),
                        key: 'selection2',
                    },
                },
                error: null,
            };
        }

        default: {
            return state;
        }
    }
};

export const selectFromDatepicker = {
    dates: (state: RootState) => ({
        dates: state.datePickerReducer.current,
        pastDates:state.datePickerReducer.previous
    }),
    selection: (state: RootState) => state.datePickerReducer.rangePickerSelection,
    all: (state: RootState) => state.datePickerReducer
};

export default datePickerReducer;
