import { SVGAttributes } from 'react';

export const House = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clipPath="url(#clip0_8015_21012)">
            <path d="M8.99943 1.62438L15.3744 7.99938C15.8207 8.44563 15.5048 9.20851 14.8736 9.20851H14.1653V13.4585C14.1653 14.0221 13.9414 14.5626 13.5429 14.9611C13.1444 15.3596 12.6039 15.5835 12.0403 15.5835H11.332V10.6252C11.332 10.0831 11.1249 9.5616 10.7531 9.16724C10.3812 8.77288 9.87273 8.53551 9.33163 8.50372L9.20697 8.50017H7.7903C7.22672 8.50017 6.68621 8.72406 6.2877 9.12257C5.88918 9.52109 5.6653 10.0616 5.6653 10.6252V15.5835H4.95697C4.39338 15.5835 3.85288 15.3596 3.45437 14.9611C3.05585 14.5626 2.83197 14.0221 2.83197 13.4585V9.20851H2.12363C1.49322 9.20851 1.17659 8.44563 1.62284 7.99938L7.99784 1.62438C8.13067 1.49159 8.31081 1.41699 8.49863 1.41699C8.68646 1.41699 8.86659 1.49159 8.99943 1.62438ZM9.20697 9.91684C9.39483 9.91684 9.575 9.99147 9.70784 10.1243C9.84067 10.2571 9.9153 10.4373 9.9153 10.6252V15.5835H7.08197V10.6252C7.08199 10.4517 7.14569 10.2842 7.26097 10.1546C7.37626 10.0249 7.53512 9.9421 7.70743 9.9218L7.7903 9.91684H9.20697Z" fill="#767CA8"/>
        </g>
        <defs>
            <clipPath id="clip0_8015_21012">
                <rect width="17" height="17" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
