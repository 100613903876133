/**
 *
 * @param currentVal
 * @param previousVal
 * @param digits - default is 1
 * @returns
 */
export const calcPercentageOfCurrentFromPrevious = (currentVal: number, previousVal: number, digits = 1): number => {
    if (previousVal === 0) {
        return 100;
    }

    if (currentVal === 0) {
        return -100;
    }

    if (currentVal && previousVal){
        return parseFloat(((currentVal - previousVal) * 100 / previousVal).toFixed(digits));
    }

    return 0;
};

export const getMaxValue = (array: number[]) => array.reduce((acc, next) => {
    if (acc < next){
        acc = next;
    }

    return acc;
}, 0);

export const toFixed = (value: number, digits = 2) => Number(value.toFixed(digits));

export const sumArray = (array: number[]) => array.reduce((val, acc) => val + acc, 0);

export const limitNumberWithinRange = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
