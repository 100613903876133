import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { ConversionsPerDay } from 'shared/interfaces/store/conversions';

import { CONVERSIONS_DATA_IS_LOADING, CONVERSIONS_GET_ALL, CONVERSIONS_GET_FAILURE } from './conversions.types';

export declare interface ConversionsState {
    conversions: ConversionsPerDay;
    requestSource: CancelTokenSource;
    isLoading: boolean;
    error: string;
}

const initialState: ConversionsState = {
    conversions: null,
    requestSource: null,
    error: null,
    isLoading: false,
};

const conversionsReducer = function (state: ConversionsState = initialState, action: ReducerAction): ConversionsState {
    switch (action.type) {
        case CONVERSIONS_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case CONVERSIONS_GET_ALL: {
            return {
                ...state,
                conversions: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case CONVERSIONS_GET_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default conversionsReducer;
