export declare interface RealtimeSkeletonLayoutSvgElement {
    x: number;
    y: number;
    width: number;
    height: number;
}

export declare interface RealtimeSkeletonLayoutSettings {
    containerWidth: number;
    header1Props: RealtimeSkeletonLayoutSvgElement;
    widget1Props: RealtimeSkeletonLayoutSvgElement;
    widget2Props: RealtimeSkeletonLayoutSvgElement;
    header2Props: RealtimeSkeletonLayoutSvgElement;
    widget3Props: RealtimeSkeletonLayoutSvgElement;
}

export const RealtimeSkeletonLayoutMaxContainerWidth = 1529;
export const RealtimeSkeletonLayoutInitialSettings = {
    containerWidth: RealtimeSkeletonLayoutMaxContainerWidth,
    header1Props: {
        x: 0,
        y: 0,
        width: 200,
        height: 40
    },
    widget1Props: {
        x: 0,
        y: 75,
        width: 430,
        height: 315
    },
    widget2Props: {
        x: 470,
        y: 75,
        width: 1200,
        height: 315
    },
    header2Props: {
        x: 0,
        y: 430,
        width: 250,
        height: 40
    },
    widget3Props: {
        x: 0,
        y: 505,
        width: 1655,
        height: 1000
    },
} satisfies RealtimeSkeletonLayoutSettings;
