import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { TopContent } from 'shared/interfaces/store/topContent';

import {
    TOP_CONTENT_DATA_IS_LOADING,
    TOP_CONTENT_GET_ALL_FAILURE,
    TOP_CONTENT_GET_ALL_SUCCESS
} from './topContent.types';

export declare interface TopContentState {
    content: TopContent;
    error: string;
    isLoading: boolean;
}

const initialState: TopContentState = {
    content: null,
    error: null,
    isLoading: false,
};

const topContentReducer = function (state: TopContentState = initialState, action: ReducerAction): TopContentState {
    switch (action.type) {
        case TOP_CONTENT_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case TOP_CONTENT_GET_ALL_SUCCESS: {
            return {
                content: action.payload,
                error: null,
                isLoading: false,
            };
        }

        case TOP_CONTENT_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default topContentReducer;
