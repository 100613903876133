import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { DepthRate } from 'shared/interfaces/store/depthRate';

import {
    INDEPTH_DATA_IS_LOADING,
    INDEPTH_GET_ALL_FAILURE,
    INDEPTH_PAGE_GET_ALL_SUCCESS
} from './depthRate.types';

export declare interface DepthRateState {
    data: DepthRate;
    error: string;
    isLoading: boolean;
}

const initialState: DepthRateState = {
    data: null,
    error: null,
    isLoading: false,
};

const depthRateReducer = function (state: DepthRateState = initialState, action: ReducerAction): DepthRateState {
    switch (action.type) {
        case INDEPTH_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case INDEPTH_PAGE_GET_ALL_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                error: null,
                isLoading: false,
            };
        }

        case INDEPTH_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default depthRateReducer;
