import * as logger from 'loglevel';

import Config from 'config/config';

if (Config.isDevelopment) {
    logger.setLevel('debug');
} else {
    logger.setLevel('error');
}

export default logger;
