import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ToastContainer.scss';

function IndepthToastContainer() {
    return (
        <ToastContainer
            // stacked
            // transition={Zoom}
            position="bottom-right"
            autoClose={'6000'}
            hideProgressBar

            // newestOnTop={false}
            closeOnClick
            closeButton
            // pauseOnFocusLoss
            // pauseOnHover
            draggable
            limit={5}

            rtl={false}
            theme="light"
            style={{
                background:   'white',
                borderRadius: '5px',
            }}
            containerId={'tlt-container'}
        />
    );
}

export default IndepthToastContainer;
