import {
    INDEPTH_AND_HEATMAP_GET_ALL,
    INDEPTH_AND_HEATMAP_GET_ALL_FAILURE,
    INDEPTH_AND_HEATMAP_GET_CURR_STATS,
    INDEPTH_AND_HEATMAP_GET_PREV_STATS,
    INDEPTH_AND_HEATMAP_GET_STATS_FAILURE,
    INDEPTH_AND_HEATMAP_IFRAME_ERROR,
} from './inheat.types';

const initialState = {
    heatmap: [],
    requestSource: null,
    error: null,
};

const inHeatReducer = function (state = initialState, action) {
    switch (action.type) {
        case INDEPTH_AND_HEATMAP_GET_ALL: {
            return {
                ...state,
                heatmap: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
            };
        }

        case INDEPTH_AND_HEATMAP_GET_ALL_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
            };
        }

        case INDEPTH_AND_HEATMAP_GET_CURR_STATS: {
            return {
                ...state,
                stats: {
                    ...state.stats,
                    curr: action.payload,
                },
                error: null,
            };
        }

        case INDEPTH_AND_HEATMAP_GET_PREV_STATS: {
            return {
                ...state,
                stats: {
                    ...state.stats,
                    prev: action.payload,
                },
            };
        }

        case INDEPTH_AND_HEATMAP_GET_STATS_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
            };
        }

        case INDEPTH_AND_HEATMAP_IFRAME_ERROR: {
            return {
                ...state,
                error: action.payload.message,
            };
        }

        default: {
            return state;
        }
    }
};

export default inHeatReducer;
