import type { ActionError } from 'shared/enum/actionError';
import type { StoreDataStatus } from 'shared/enum/storeDataStatus';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Authors } from 'shared/interfaces/store/authors';

import * as types from './author.types';
import { GetAllAuthorsDTO } from './dto/getAllAuthors.dto';
import type { GetAuthorArticlesDTO } from './dto/getAuthorArticles.dto';
import type { GetAuthorHighlightsDTO } from './dto/getAuthorHighlights.dto';

export declare interface AuthorsState {
    authors: Authors | StoreDataStatus;
    error: string;
    isLoading: boolean;

    highlights: Omit<GetAuthorHighlightsDTO, 'onPageDuration' | 'totalOnPageDuration' | 'visits' | 'totalOnSite'>;
    highlightsError: string | ActionError;
    highlightsIsLoading: boolean;

    articles: GetAuthorArticlesDTO;
    articlesError: string | ActionError;
    articlesIsLoading: boolean;

    allAuthors: GetAllAuthorsDTO;
    allAuthorsError: string | ActionError;
    allAuthorsIsLoading: boolean;
}

const initialState: AuthorsState = {
    authors: null,
    error: null,
    isLoading: false,

    highlights: null,
    highlightsError: null,
    highlightsIsLoading: false,

    articles: null,
    articlesError: null,
    articlesIsLoading: false,

    allAuthors: null,
    allAuthorsError: null,
    allAuthorsIsLoading: false,
};

const authorReducer = function (state: AuthorsState = initialState, action: ReducerAction): AuthorsState {
    switch (action.type) {
        // top authors
        case types.AUTHOR_GET_TOP_AUTHOR_IS_LOADING:
            return { ...state, isLoading: true };

        case types.AUTHOR_GET_TOP_AUTHOR_SUCCESS: {
            return {
                ...state,
                authors: action.payload,
                error: null,
                isLoading: false,
            };
        }

        case types.AUTHOR_GET_TOP_AUTHOR_FAILURE: {
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        }

        // highlights
        case types.AUTHOR_GET_HIGHLIGHTS_IS_LOADING:
            return { ...state, highlightsIsLoading: true };

        case types.AUTHOR_GET_HIGHLIGHTS_SUCCESS: {
            return {
                ...state,
                highlights: action.payload,
                highlightsError: null,
                highlightsIsLoading: false,
            };
        }

        case types.AUTHOR_GET_HIGHLIGHTS_FAILURE: {
            return {
                ...state,
                highlightsError: action.payload,
                highlightsIsLoading: false,
            };
        }

        // articles
        case types.AUTHOR_GET_ARTICLES_IS_LOADING:
            return { ...state, articlesIsLoading: true };

        case types.AUTHOR_GET_ARTICLES_SUCCESS: {
            return {
                ...state,
                articles: action.payload,
                articlesError: null,
                articlesIsLoading: false,
            };
        }

        case types.AUTHOR_GET_ARTICLES_FAILURE: {
            return {
                ...state,
                articlesError: action.payload,
                articlesIsLoading: false,
            };
        }

        // all authors
        case types.AUTHOR_GET_ALL_AUTHORS_IS_LOADING:
            return { ...state, allAuthorsIsLoading: true };

        case types.AUTHOR_GET_ALL_AUTHORS_SUCCESS: {
            return {
                ...state,
                allAuthors: action.payload,
                allAuthorsError: null,
                allAuthorsIsLoading: false,
            };
        }

        case types.AUTHOR_GET_ALL_AUTHORS_FAILURE: {
            return {
                ...state,
                allAuthorsError: action.payload,
                allAuthorsIsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default authorReducer;
