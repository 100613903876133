import classNames from 'classnames';
import '../alert.scss';
import './AlertSuccess.scss';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    message: string;
};

const AlertSuccess = ({ className, message, ...rest }: Props) => (
    <div {...rest} className={classNames('app-alert app-alert-success', { [className]: !!className })}>
        <p>{message}</p>
    </div>
);

export default AlertSuccess;
