import { ActionError, isInstanceOfActionError } from '@shared/enum/actionError';
import { ErrorMessages } from 'constants/error';
import { isEmpty } from 'lodash';

export function getErrorMessage(error: any): string {
    if (isInstanceOfActionError(error)) {
        return ErrorMessages.actionError[error];
    }

    if (typeof error === 'string') {
        return error || ErrorMessages.actionError[ActionError.NoResponse];
    }

    return isEmpty(error?.message) ? ErrorMessages.actionError[ActionError.NoResponse] : error?.message;
}
