import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';

import { ClearableReducers } from './cleaner.action';
import { CLEANER_CLEAN_REDUCERS, CLEANER_CLEAN_STORE } from './cleaner.types';

export declare interface CleanerState {
    error: string | null;
    clean: boolean;
    reducers: ClearableReducers[];
}

const initialState: CleanerState = {
    error: null,
    clean: false,
    reducers: [],
};

const cleanerReducer = function (state: CleanerState = initialState, action: ReducerAction): CleanerState {
    switch (action.type) {
        case CLEANER_CLEAN_STORE: {
            return {
                ...state,
                clean: !state.clean,
            };
        }

        case CLEANER_CLEAN_REDUCERS: {
            const reducers = action.payload?.reducers ?? [];

            return {
                ...state,
                clean: reducers?.length,
                reducers
            };
        }

        default: {
            return state;
        }
    }
};

export default cleanerReducer;
