import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

import type { DatepickerRangeSelection } from 'shared/interfaces/datepicker/datepickerRangeSelection';

import DatePickerControls from '@components/widgets/date-picker/controls/DatePickerControls';
import DatePickerCustomize from '@components/widgets/date-picker/customize/DatePickerCustomize';
import DatePickerRangePicker from '@components/widgets/date-picker/ranger-picker/DatePickerRangePicker';
import ErrorMessage from '@components/widgets/error-message/ErrorMessage';
import { isMobileSm } from 'constants/media';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import DateInstance from 'services/date/date';
import { setDatePickerDates, setError } from 'store/date-picker/datePicker.actions';
import './DatePicker.sass';
import DatePickerMobileControls from './controls/DatePickerMobileControls';

declare interface DatePickerProps {
    onSave: (value: DatepickerRangeSelection) => void;
    onClose: () => void;
}

function DatePicker({ onSave, onClose }: DatePickerProps) {
    const dispatch                                 = useAppDispatch();
    const { selectionState, compareStatus, error } = useAppSelector(state => ({
        selectionState: state.datePickerReducer.rangePickerSelection,
        compareStatus: state.datePickerReducer.compareStatus,
        error: state.datePickerReducer.error,
    }));

    const handleSave = () => {
        if (compareStatus){
            const currDiff = differenceInDays(selectionState.selection1.endDate, selectionState.selection1.startDate);
            const prevDiff = differenceInDays(selectionState.selection2.endDate, selectionState.selection2.startDate);

            if (currDiff !== prevDiff) {
                dispatch(setError('You must choose two date ranges of equal length'));

                return;
            }
        }

        dispatch(
            setDatePickerDates(
                [
                    DateInstance.formatDate({ date: selectionState.selection1.startDate, format: 'yyyy-MM-dd' }),
                    DateInstance.formatDate({ date: selectionState.selection1.endDate, format: 'yyyy-MM-dd' }),
                ],
                [
                    DateInstance.formatDate({ date: selectionState.selection2.startDate, format: 'yyyy-MM-dd' }),
                    DateInstance.formatDate({ date: selectionState.selection2.endDate, format: 'yyyy-MM-dd' }),
                ],
                'self'
            )
        );

        onSave(selectionState);

        return onClose();
    };

    const windowSize   = useWindowSize();
    const ControlsNode = useMemo(() => (
        isMobileSm ? (
            <DatePickerMobileControls
                onCancel={onClose}
                onSave={handleSave}
            />
        ) : (
            <DatePickerControls
                onCancel={onClose}
                onSave={handleSave}
                error={error}
            />
        )
    ), [windowSize, handleSave, onClose, error]);

    return (
        <div className="dp-wrapper">
            <div className="dp-shortcuts">
                <DatePickerCustomize />
            </div>
            <div className="dp-interface">
                <div className="dp-range-picker">
                    <DatePickerRangePicker />
                </div>

                {ControlsNode}
            </div>

            {
                isMobileSm && (
                    <div>
                        {error ? (
                            <ErrorMessage
                                wrapperStyle={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: '1rem',
                                }}
                                message={error}
                            />
                        ) : null}
                    </div>
                )
            }
        </div>
    );
}

export default DatePicker;
