import { getIsTablet } from 'constants/media';
import { RootState } from 'shared/interfaces/store/store';
import { toggleMobileSideNavStatus, toggleSideNavStatus } from 'store/sideBar/sideBar.action';
import { SideBarState } from 'store/sideBar/sideBar.reducer';
import { useAppDispatch, useAppSelector } from '../redux/useRedux';

export default function useSidebar() {
    const state: SideBarState = useAppSelector((state: RootState) => state.sideBarReducer);
    const dispatch            = useAppDispatch();
    const isTablet            = getIsTablet();

    const toggleStatus = (status: boolean, navType: 'mobile' | 'desktop' = 'desktop') => {
        dispatch(
            navType === 'mobile' || isTablet ? toggleMobileSideNavStatus(status) : toggleSideNavStatus(status)
        );
    };

    const resetSideNavStatus = () => {
        dispatch(toggleSideNavStatus(true, true));
    };

    return { state, resetSideNavStatus, toggleStatus, type: (isTablet ? 'mobile' : 'desktop' as 'mobile' | 'desktop') };
}
