import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppNavItem, type AppNavItemProps } from '../';
import './AppNavGroup.scss';

interface AppNavGroupProps {
    isCollapsed: boolean;
    groupName: string;
    groupItems: (Omit<AppNavItemProps, 'isCollapsed'> & { validator?: () => boolean })[];
}

const AppNavGroup = ({ isCollapsed, groupName, groupItems }: AppNavGroupProps) => {
    const location                            = useLocation();
    const [activeDropdown, setActiveDropdown] = useState('');

    // when user navigates to another page, close dropdown
    useEffect(() => {
        const isSelected = groupItems.find(item => item.name === activeDropdown);

        if (isSelected) {
            if (isCollapsed) {
                setActiveDropdown('');

                return;
            }

            const validator = isSelected?.validator ?? (() => false);

            if (!validator()) {
                setActiveDropdown('');
            }
        }
    }, [location, isCollapsed]);

    return (
        <div className={classNames('app-nav-group', { collapsed: isCollapsed })}>
            <p>{groupName}</p>
            {groupItems.map((itemProps, idx) => {
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                const { validator = null, ...rest } = itemProps;

                return (
                    <AppNavItem
                        key={`${itemProps.name}-${idx}`}
                        isCollapsed={isCollapsed}
                        isDropdownActive={activeDropdown === itemProps.name}
                        toggleDropdown={() => setActiveDropdown(activeDropdown === itemProps.name ? '' : itemProps.name)}
                        {...rest}
                    />
                );
            })}
        </div>
    );
};

export default AppNavGroup;
