import { useUpdateGsap } from '@hooks/gsap/useUpdateGsap';
import { Expo, gsap } from '@services/gsap';


export default function useAnimateCalendar({
    datePickerStatus,
    toggleDatePicker,
    wrapperRef,
    datePickerRef
}: {
    datePickerStatus: boolean;
    toggleDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
    wrapperRef: React.MutableRefObject<HTMLDivElement>;
    datePickerRef: React.MutableRefObject<HTMLDivElement>;
}){
    const selector                             = gsap.utils.selector(wrapperRef);

    useUpdateGsap(() => {
        if (datePickerStatus) {
            animateIn(selector);

            if (wrapperRef.current) {
                document.addEventListener('click', (e) => toggleHandler(
                    e,
                    toggleDatePicker,
                    wrapperRef,
                    datePickerRef
                ));
            }

            return;
        }

        animateOut(selector);

        return () => {
            if (document) {
                document.removeEventListener('click', (e) => toggleHandler(
                    e,
                    toggleDatePicker,
                    wrapperRef,
                    datePickerRef
                ));
            }
        };
    }, { dependencies: [datePickerStatus], scope: wrapperRef });
}

const toggleHandler = (e, toggleDatePicker, pickerWrapper, datePicker) => {
    if (
        !pickerWrapper?.current?.contains(e.target) &&
        !document?.querySelector('.filter-dp')?.contains(e.target) &&
        !datePicker?.current?.contains(e.target)
    ) {
        toggleDatePicker(false);
    }
};

function animateIn(selector: gsap.utils.SelectorFunc) {
    gsap.to(selector('.filter-datepicker'), {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        y: 0,
        ease: Expo.easeInOut,
    });
}

function animateOut(selector: gsap.utils.SelectorFunc) {
    gsap.to(selector('.filter-datepicker'), {
        display: 'none',
        opacity: 0,
        y: -50,
        ease: Expo.easeInOut,
    });
}
