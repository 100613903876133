import { isObjectLike } from 'lodash';

export declare interface APIError {
    error?: string;
    timestamp?: string;
    path?: string;
    statusCode: number | [];
    message: string;
}

export function instanceOfAPIError(dataObject: any): dataObject is APIError {
    const isOfType = dataObject && isObjectLike(dataObject) && 'statusCode' in dataObject && 'message' in dataObject && dataObject.statusCode !== 200;

    if (isOfType){
        return {
            ...dataObject,
            message: Array.isArray(dataObject.message) ? dataObject.message.join('|') : dataObject.message
        };
    }

    return isOfType;
}
