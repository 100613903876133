import { ThemeColors } from './colors';
import { getIsMobile, getIsTablet } from './media';

// react-select style
export const CustomSelectStyles = {
    menu: (provided) => ({
        ...provided,
        borderRadius: 10,
        padding: 0,
        zIndex: 68541653165310,
    }),
    option: (provided) => {
        const tmp     = getIsTablet() ? 10 : 10;
        const padding = getIsMobile() ? 10 : tmp;

        return {
            ...provided,
            overflow: 'hidden',
            color: 'black',
            padding,
            margin: 0,
            borderRadius: 0,
            fontSize: '0.6rem',
            backgroundColor: 'white',
            cursor: 'pointer',
            transition: '.3s all ease-in-out',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ':hover': {
                background: '#F5F6FA',
                color: ThemeColors.themeHighlightColor,
            },
        };
    },
    control: () => {
        const pTmp    = getIsTablet ? '0 6px' : '0 10px';
        const padding = getIsMobile ? '0 5px' : pTmp;

        const hTmp   = getIsTablet ? '26px' : '32px';
        const height = getIsMobile ? '21px' : hTmp;

        const wTmp  = getIsTablet ? 90 : 100;
        const width = getIsMobile ? 75 : wTmp;

        return {
            height, // => makes the select smaller
            width,
            backgroundColor: '#EEEEEE',
            borderRadius: 20,
            padding,
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            cursor: 'pointer',
        };
    },
    singleValue: (provided, state) => {
        const opacity    = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,

            transition,
            fontSize: '0.6rem',
        };
    },
};

export const CustomSelectTitleAudioVideo = {
    ...CustomSelectStyles,
    option: (provided) => ({
        ...CustomSelectStyles.option(provided),
        fontSize: '0.65rem',
    }),
    control: () => ({
        ...CustomSelectStyles.control(),
        width: 200,
        background: 'transparent',
        padding: 0,
    }),
    singleValue: (provided, state) => ({
        ...CustomSelectStyles.singleValue(provided, state),
        fontSize: '0.8rem',
    }),
};
