import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import HamburgerMenu from '@components/common/menus/hamburger-menu/HamburgerMenu';
import PageFilter from '@components/widgets/page-filter/PageFilter';
import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { DefinedRoutes } from 'routes/definedRoutes';
import { triggerFocusEvent } from 'services/event/event';
import './PageHeader.sass';

declare interface PageHeaderProps {
    headerInfo?: React.ReactNode;
    headerNavigation?: React.ReactNode;
    headerDisableSearch?: boolean;
    publicationDate?: React.ReactNode;
    shouldHideFilters?: boolean;
    disableDownloadReport?: boolean;
}

const PageHeader = ({ headerInfo, headerNavigation, headerDisableSearch = false, disableDownloadReport, publicationDate, shouldHideFilters = false }: PageHeaderProps) => {
    const [mobileSearchStatus, toggleMobileSearch] = useState(false);
    const { pathname }                             = useLocation();
    const size                                     = useWindowSize();

    useUpdateEffect(() => {
        if (mobileSearchStatus){
            const inputElement: HTMLInputElement = document.querySelector('.header-mobile-search .header-search input');

            triggerFocusEvent(inputElement);
        }
    }, [mobileSearchStatus, size]);

    return (
        <header data-testid="header-container">
            <div className="header-mobile">
                <div className="header-mobile-opt">
                    <div className="header-mobile-left">
                        <HamburgerMenu />

                        {headerInfo ? headerInfo : null}
                    </div>
                    {/* {!shouldHideFilters && (
                        <>
                            <Search
                                className='search-trigger'
                                onClick={() => toggleMobileSearch(true)}
                            />

                            {
                                mobileSearchStatus ? (
                                    <div className="header-mobile-search">
                                        <div className="header-mobile-search-clip" onClick={() => toggleMobileSearch(false)}></div>
                                        <HeaderSearchWrapper />
                                    </div>
                                ) : null
                            }
                        </>
                    )} */}
                </div>

                {!shouldHideFilters && (
                    <div className="header-mobile-filter-wrapper">
                        <div className="header-mobile-filter">
                            <PageFilter disableDownloadReport={disableDownloadReport}/>
                        </div>
                    </div>
                )}

                {
                    pathname === DefinedRoutes.overview.path ? (
                        <p className='header-mobile-welcome'>Here's your analytics for today</p>
                    ) : null
                }

                {headerNavigation && !shouldHideFilters ? (
                    <div className="header-mobile-direction">
                        {headerNavigation}
                    </div>
                ):null}
            </div>


            <div className='main-header'>
                <div className="header-info">
                    {headerInfo ? headerInfo : null}
                    {headerNavigation && !shouldHideFilters ? headerNavigation : null}
                </div>
                {!shouldHideFilters && (
                    <div className='filter-user-wrapper'>
                        {/* {!headerDisableSearch ? <HeaderSearchWrapper /> : null} */}
                        {publicationDate ? publicationDate : null}
                        <PageFilter disableDownloadReport={disableDownloadReport}/>
                    </div>
                )}
            </div>
        </header>
    );
};

export default PageHeader;

