export const createCenteredPopup = ({ url, title, w, h }: { url: string; title: string; w: number; h: number }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop  = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width  = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left       = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top        = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow  = window.open(url, title,
        `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) {newWindow.focus();}

    return newWindow;
};

export const replacePathParams = (path: string, params: Record<string,string>, prefix = ':') => {
    let newPath = path;

    Object.entries(params).forEach(([key, value]) => {
        newPath = newPath.replace(prefix + key, value);
    });

    return newPath;
};

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');

    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top      = '0';
    textArea.style.left     = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg        = successful ? 'successful' : 'unsuccessful';

        console.log(`Fallback: Copying text command was ${  msg}`);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);

        return;
    }

    navigator.clipboard.writeText(text).then(
        function () {
            console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
            console.error('Async: Could not copy text: ', err);
        },
    );
}
