import { handleRequestErrorChecking } from '@services/axios/axiosUtils';
import { AuthAPIClient } from '@services/axios/clients/authAPIClient';
import { handleCatchBlock } from '@services/error-handler/errorHandler';
import { ActionError } from '@shared/enum/actionError';

export const updateProfile = async (dataObj: {
    firstname?: string;
    lastname?: string;
    avatar: File;
}, removeAvatar = false) => {
    let result: { success: boolean; error: string; data: any } = null;

    try {
        const response = await AuthAPIClient.updateProfile({
            ...dataObj,
            removeAvatar,
        });
        const error    = handleRequestErrorChecking(response);

        result = { success: true, error, data: response?.data };
    } catch (error: unknown) {
        const result = handleCatchBlock(error, true);

        if (typeof result !== 'string') {
            return {
                data: null,
                error: result?.error?.response?.status === 404 ? ActionError.AuthorNotFound : '',
                success: false
            };
        }

        return { data: null, error: result as string, success: false };
    }

    return result;
};

export const updatePassword = async (dataObj: {
    currentPassword: string;
    newPassword: string;
}) => {
    let result: { success: boolean; error: string; data: any } = null;

    try {
        const response = await AuthAPIClient.updatePassword(dataObj);
        const error    = handleRequestErrorChecking(response);

        result = { success: true, error, data: response?.data };
    } catch (error: unknown) {
        const result = handleCatchBlock(error, true);

        if (typeof result !== 'string') {
            const error = result?.data?.message === 'password invalid'
                ? 'Current password invalid!'
                : result?.data?.message;

            return {
                data: null,
                error,
                success: false
            };
        }

        return { data: null, error: result as string, success: false };
    }

    return result;
};

export const profileSettingsApi = {
    updateProfile,
    updatePassword,
};
