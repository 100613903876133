const AppNavItemDropdownPortalContainer = () => (
    <div
        id="app-nav-dropdown-portal-container"
        style={{
            position: 'relative',
            width: 'fit-content',
            height: 'fit-content'
        }}
    >

    </div>
);

export default AppNavItemDropdownPortalContainer;
