import ToggleButton from '@components/widgets/toggle-button/ToggleButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import { toggleCompare } from 'store/date-picker/datePicker.actions';

function DatePickerCustomizeCompare() {
    const compareStatus = useAppSelector(state => state.datePickerReducer.compareStatus);
    const dispatch      = useAppDispatch();

    return (
        <div className="dp-compare-toggle">
            <p>Compare with</p>
            <ToggleButton
                defaultValue={compareStatus}
                callback={(status) => dispatch(toggleCompare(status))}
            />
        </div>
    );
}

export default DatePickerCustomizeCompare;
