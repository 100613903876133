import { useGSAP } from '@gsap/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavLink } from 'react-router-dom';

import useSidebar from '@hooks/sidebar/useSidebar';
import { gsap } from '@services/gsap';

import './AppNavItemDropdown.scss';

interface AppNavItemDropdownProps {
    itemWrapperRef: React.MutableRefObject<HTMLDivElement>;
    isDropdownActive: boolean;
    isCollapsed: boolean;
    toggleDropdown: () => void;
    items: AppNavItemDropdownItemProps[];
    navItemId: string;
}

const AppNavItemDropdown = ({ items, navItemId, isCollapsed, itemWrapperRef, isDropdownActive, toggleDropdown }: AppNavItemDropdownProps) => {
    const [portalContainer, setPortalContainer] = useState<Element>(document.getElementById('app-nav-dropdown-portal-container'));

    useEffect(() => setPortalContainer(document.getElementById('app-nav-dropdown-portal-container')), []);

    const sidebar       = useSidebar();
    const container     = useRef<HTMLDivElement>(null);
    const selector      = gsap.utils.selector(container);
    const switchToPopup = !sidebar.state.sideNavStatus;

    // animation
    useGSAP(() => {
        // if expanded
        if (!switchToPopup) {
            const timeline = gsap.timeline();

            timeline
                .from(container.current, {
                    height: 0,
                    opacity: 0,
                    duration: .3
                })
                .from(selector('.app-nav-dropdown-item'), {
                    opacity: 0,
                    x: -100,
                    stagger: .05,
                }, '-=.5');
        } else {
            // if collapsed
            animatePopupIn(container, selector);
        }
    }, { dependencies: [], scope: container });

    // toggle dropdown on click anywhere other than nav
    useGSAP(() => {
        if (!itemWrapperRef.current || !isCollapsed) {
            return;
        }

        const animateOut  = (onComplete: () => void) => animatePopupOut(container, selector, onComplete);
        const handleClick = (e: MouseEvent) => onHandleCloseDropdown(
            e,
            itemWrapperRef,
            toggleDropdown,
            animateOut
        );

        if (isDropdownActive) {

            window.addEventListener('click', handleClick);
        }

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, { dependencies: [toggleDropdown, isDropdownActive, isCollapsed], scope: container });

    return switchToPopup && portalContainer ? createPortal(
        <div
            ref={container}
            className={'app-nav-dropdown-items switchToPopup'}
            style={{ top: (document.getElementById(navItemId).getBoundingClientRect().top + 25) + 'px' }}
        >
            {items.map((props, idx) => (
                <AppNavItemDropdownItem
                    key={`${props.title}-${idx}`}
                    {...props}
                />
            ))}
        </div>,
        portalContainer
    ) : (
        <div
            ref={container}
            className={'app-nav-dropdown-items'}
        >
            {items.map((props, idx) => (
                <AppNavItemDropdownItem
                    key={`${props.title}-${idx}`}
                    {...props}
                />
            ))}
        </div>
    );
};

function onHandleCloseDropdown(
    event: MouseEvent,
    wrapper:  React.MutableRefObject<HTMLDivElement>,
    toggleDropdown: () => void,
    animateOut: (onComplete: () => void) => void
) {
    if (!wrapper.current?.contains(event.target as Node)) {
        animateOut(() => {
            toggleDropdown();
        });
    }
}

function animatePopupIn(container: React.MutableRefObject<HTMLDivElement>, selector: gsap.utils.SelectorFunc) {
    const timeline = gsap.timeline();

    timeline
        .from(container.current, {
            x: -10,
            opacity: 0,
            duration: .4
        })
        .from(selector('.app-nav-dropdown-item'), {
            opacity: 0,
            y: -10,
            stagger: .05,
        }, '-=.3');
}

function animatePopupOut(container: React.MutableRefObject<HTMLDivElement>, selector: gsap.utils.SelectorFunc, onComplete?: () => void) {
    const timeline = gsap.timeline({
        onComplete
    });

    timeline
        .to(container.current, {
            x: -10,
            opacity: 0,
            duration: .4
        })
        .to(selector('.app-nav-dropdown-item'), {
            opacity: 0,
            y: -10,
            stagger: .05,
        }, '-=.3');
}
export interface AppNavItemDropdownItemProps {
    title: string;

    isLink?: boolean;
    path?: string;

    isActive?: boolean;
    onClick?: () => void;
}

const AppNavItemDropdownItem = ({ isLink, path, title, isActive, onClick }: AppNavItemDropdownItemProps) => isLink ? (
    <NavLink
        // activeClassName="active"
        className={({ isActive: isNavActive }) => classNames('app-nav-dropdown-item', { active: isActive || isNavActive })}
        to={path}
    >
        <p>{title}</p>
    </NavLink>
) : (
    <div className={classNames('app-nav-dropdown-item', { active: isActive })} onClick={onClick}>
        <p>{title}</p>
    </div>
);

export default AppNavItemDropdown;
