import { Children } from 'react';

import { isArrayEmpty } from '@services/validation/validation';

interface Props<Item> {
    of: Item[];
    render: (item: Item, idx: number) => JSX.Element;
}

function Each<Item>({ of, render }: Props<Item>) {
    if (!isArrayEmpty(of)) {
        return Children.toArray(
            of.map((item, idx) => render(item, idx))
        );
    }

    return null;
}

export default Each;
