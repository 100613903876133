export const ThemeColors = {
    themeHighlightColor: '#FF6A83',
    themeBackgroundColor: '#1E1F23',

    themeGrayColor: '#798ba5',
    themeGrayLighterColor: '#AFBED2',

    themeStatsColor1: 'rgba(30, 31, 35, 1)',
    themeStatsColor2: 'rgba(80, 121, 228, 1)',
    themeStatsColor3: 'rgba(255, 106, 131, 1)',
    themeStatsColor4: 'rgba(88, 95, 154, 1)',
    themeStatsColor5: 'rgba(159, 186, 255, 1)',
    themeStatsColor6: 'rgba(214, 225, 255, 1)',
    themeStatsColor7: 'rgba(107, 209, 197, 1)',
    themeStatsColor8: 'rgba(255, 159, 161, 1)',
    themeStatsColor9: 'rgba(217, 217, 217, 1)',
    themeStatsColor10: '#7CFFB2',
    themeStatsColor11: 'rgba(255, 223, 224, 1)',
    themeStatsColor12: 'rgba(240, 246, 255, 1)',
    themeStatsColor13: 'rgba(247, 175, 54, 1)',
    themeStatsColor14: 'rgba(255, 222, 166, 1)',
    themeStatsColor15: 'rgba(86, 202, 189, 1)',
    themeStatsColor16: 'rgba(249, 207, 74, 1)',

    green: 'rgba(86, 202, 189, 1)',
    orange: 'rgba(255, 169, 120, 1)',
    red: 'rgba(255, 94, 95, 1)',
    lightRed: '#ffe0dd',

    inputBackground: 'rgba(249, 249, 255, 1)',
    inputBorder: '#E4E7FF',
    inputText: '#767CA8',

    widgetBorder: '#E4E7FF',

    white: '#fff',
    black: '#292929',
};
