export const LayoutSizes = {
    apple: '1440px',
    desktopSm: '1024px',
    tablet: '1024px',
    // tablet: '992px',
    mobileLg: '768px',
    mobile: '700px',
    mobileSm: '480px',
    mobileXs: '400px',
    mobileXxs: '300px',
};

export const isDesktopSm = window.matchMedia(`(max-width: ${LayoutSizes.desktopSm})`).matches;
export const getIsDesktopSm = () => window.matchMedia(`(max-width: ${LayoutSizes.desktopSm})`).matches;
export const isTablet = window.matchMedia(`(max-width: ${LayoutSizes.tablet})`).matches;
export const getIsTablet = () => window.matchMedia(`(max-width: ${LayoutSizes.tablet})`).matches;
export const isMobileLg = window.matchMedia(`(max-width: ${LayoutSizes.mobileLg})`).matches;
export const getIsMobileLg = () => window.matchMedia(`(max-width: ${LayoutSizes.mobileLg})`).matches;
export const isMobile = window.matchMedia(`(max-width: ${LayoutSizes.mobile})`).matches;
export const getIsMobile = () => window.matchMedia(`(max-width: ${LayoutSizes.mobile})`).matches;
export const isMobileSm = window.matchMedia(`(max-width: ${LayoutSizes.mobileSm})`).matches;
export const getIsMobileSm = () => window.matchMedia(`(max-width: ${LayoutSizes.mobileSm})`).matches;
export const isMobileXs = window.matchMedia(`(max-width: ${LayoutSizes.mobileXs})`).matches;
export const getIsMobileXs = () => window.matchMedia(`(max-width: ${LayoutSizes.mobileXs})`).matches;
export const isMobileXxs = window.matchMedia(`(max-width: ${LayoutSizes.mobileXxs})`).matches;
export const getIsMobileXxs = () => window.matchMedia(`(max-width: ${LayoutSizes.mobileXxs})`).matches;

const largeDevices  = window.matchMedia('(min-width: 1366px)').matches;
const mediumDevices = window.matchMedia('(max-width: 1366px)').matches;
const smallDevices  = window.matchMedia('(max-width: 768px)').matches;

export const mediaDevices = {
    large: largeDevices,
    isLarge: () => window.matchMedia('(min-width: 1366px)').matches,
    medium: mediumDevices,
    isMedium: () => window.matchMedia('(max-width: 1366px)').matches,
    small: smallDevices,
    isSmall: () => window.matchMedia('(max-width: 768px)').matches,
};
