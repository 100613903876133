import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { VisitorsPerHour } from 'shared/interfaces/store/visitorsPerHour';

import {
    VISITORS_PER_HOUR_DATA_IS_LOADING,
    VISITORS_PER_HOUR_GET_ALL_FAILURE,
    VISITORS_PER_HOUR_GET_ALL_SUCCESS
} from './visitorsPerHour.types';

export declare interface VisitorsPerHourState {
    usersData: VisitorsPerHour | null;
    requestSource: CancelTokenSource | null;
    error: string | null;
    isLoading: boolean;
}

const initialState: VisitorsPerHourState = {
    usersData: null,
    requestSource: null,
    error: null,
    isLoading: false
};

const visitorsPerHourReducer = function (state: VisitorsPerHourState = initialState, action: ReducerAction): VisitorsPerHourState {
    switch (action.type) {
        case VISITORS_PER_HOUR_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case VISITORS_PER_HOUR_GET_ALL_SUCCESS: {
            return {
                ...state,
                usersData: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case VISITORS_PER_HOUR_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default visitorsPerHourReducer;
