
import { createSlice } from '@reduxjs/toolkit';

import { DataStatus } from '@shared/enum/dataStatus';
import {
    GTArticlesTable,
    GTBalancingScore,
    GTCompareArticles,
    GTCompareAuthors,
    GTOverallScore,
    GTSingleArticle,
    GTUniqueLanguages
} from '@shared/interfaces/store/gender-tracker';
import { RootState } from '@store/storeConfig';
import {
    calculateBalancingScoreGT,
    fetchArticlesTableGT,
    fetchOverallScoreGT,
    fetchSingleArticleGT,
    fetchTopWorstArticlesGT,
    fetchTopWorstAuthorsGT,
    fetchUniqueLanguagesGT
} from './genderTracker.thunk';

export declare interface GenderTrackerState {
    singleArticle: GTSingleArticle;
    singleArticleError: string | null;
    singleArticleStatus: DataStatus;

    topWorstArticles: GTCompareArticles;
    topWorstArticlesError: string | null;
    topWorstArticlesStatus: DataStatus;

    topWorstAuthors: GTCompareAuthors;
    topWorstAuthorsError: string | null;
    topWorstAuthorsStatus: DataStatus;

    overallScore: GTOverallScore;
    overallScoreError: string | null;
    overallScoreStatus: DataStatus;

    uniqueLanguages: GTUniqueLanguages;
    uniqueLanguagesError: string | null;
    uniqueLanguagesStatus: DataStatus;

    articlesTable: GTArticlesTable;
    articlesTableError: string | null;
    articlesTableStatus: DataStatus;

    balanceScore: GTBalancingScore;
    balanceScoreError: string | null;
    balanceScoreStatus: DataStatus;
}

const initialState: GenderTrackerState = {
    singleArticle: null,
    singleArticleError: null,
    singleArticleStatus: DataStatus.IDLE,

    topWorstArticles: null,
    topWorstArticlesError: null,
    topWorstArticlesStatus: DataStatus.IDLE,

    topWorstAuthors: null,
    topWorstAuthorsError: null,
    topWorstAuthorsStatus: DataStatus.IDLE,

    overallScore: null,
    overallScoreError: null,
    overallScoreStatus: DataStatus.IDLE,

    uniqueLanguages: null,
    uniqueLanguagesError: null,
    uniqueLanguagesStatus: DataStatus.IDLE,

    articlesTable: null,
    articlesTableError: null,
    articlesTableStatus: DataStatus.IDLE,

    balanceScore: null,
    balanceScoreError: null,
    balanceScoreStatus: DataStatus.IDLE,
};

export const genderTrackerSlice = createSlice({
    name: 'genderTracker',
    initialState,
    reducers: {
        clearState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // single article
            .addCase(fetchSingleArticleGT.pending, (state) => {
                state.singleArticleStatus = DataStatus.LOADING;
            })
            .addCase(fetchSingleArticleGT.fulfilled, (state, action) => {
                state.singleArticle       = action.payload;
                state.singleArticleStatus = DataStatus.SUCCESS;
                state.singleArticleError  = null;
            })
            .addCase(fetchSingleArticleGT.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.topWorstArticlesError  = null;

                    return;
                }

                state.singleArticleError  = action.payload as unknown as string;
                state.singleArticleStatus = DataStatus.FAILED;
            })

            // top and worst articles
            .addCase(fetchTopWorstArticlesGT.pending, (state) => {
                state.topWorstArticlesStatus = DataStatus.LOADING;
            })
            .addCase(fetchTopWorstArticlesGT.fulfilled, (state, action) => {
                state.topWorstArticles       = action.payload;
                state.topWorstArticlesError  = null;
                state.topWorstArticlesStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchTopWorstArticlesGT.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.topWorstArticlesError  = null;

                    return;
                }

                state.topWorstArticlesStatus = DataStatus.FAILED;
                state.topWorstArticlesError  = action.payload as unknown as string;
            })

            // top and worst authors
            .addCase(fetchTopWorstAuthorsGT.pending, (state) => {
                state.topWorstAuthorsStatus = DataStatus.LOADING;
            })
            .addCase(fetchTopWorstAuthorsGT.fulfilled, (state, action) => {
                state.topWorstAuthors       = action.payload;
                state.topWorstAuthorsError  = null;
                state.topWorstAuthorsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchTopWorstAuthorsGT.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.topWorstAuthorsError = null;

                    return;
                }

                state.topWorstAuthorsError  = action.payload as unknown as string;
                state.topWorstAuthorsStatus = DataStatus.FAILED;
            })

            // overall score
            .addCase(fetchOverallScoreGT.pending, (state) => {
                state.overallScoreStatus = DataStatus.LOADING;
            })
            .addCase(fetchOverallScoreGT.fulfilled, (state, action) => {
                state.overallScore       = action.payload;
                state.overallScoreStatus = DataStatus.SUCCESS;
                state.overallScoreError  = null;
            })
            .addCase(fetchOverallScoreGT.rejected, (state, action) => {
                state.overallScoreError  = action.payload as unknown as string;
                state.overallScoreStatus = DataStatus.FAILED;
            })

            // unique languages
            .addCase(fetchUniqueLanguagesGT.pending, (state) => {
                state.uniqueLanguagesStatus = DataStatus.LOADING;
            })
            .addCase(fetchUniqueLanguagesGT.fulfilled, (state, action) => {
                state.uniqueLanguages       = action.payload;
                state.uniqueLanguagesStatus = DataStatus.SUCCESS;
                state.uniqueLanguagesError  = null;
            })
            .addCase(fetchUniqueLanguagesGT.rejected, (state, action) => {
                state.uniqueLanguagesError  = action.payload as unknown as string;
                state.uniqueLanguagesStatus = DataStatus.FAILED;
            })

            // articles table
            .addCase(fetchArticlesTableGT.pending, (state) => {
                state.articlesTableStatus = DataStatus.LOADING;
            })
            .addCase(fetchArticlesTableGT.fulfilled, (state, action) => {
                state.articlesTable       = action.payload;
                state.articlesTableError  = null;
                state.articlesTableStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchArticlesTableGT.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.articlesTableError = null;

                    return;
                }

                state.articlesTableError  = action.payload as unknown as string;
                state.articlesTableStatus = DataStatus.FAILED;
            })

            // calculate balancing score
            .addCase(calculateBalancingScoreGT.pending, (state) => {
                state.balanceScoreStatus = DataStatus.LOADING;
            })
            .addCase(calculateBalancingScoreGT.fulfilled, (state, action) => {
                state.balanceScore       = action.payload;
                state.balanceScoreError  = null;
                state.balanceScoreStatus = DataStatus.SUCCESS;
            })
            .addCase(calculateBalancingScoreGT.rejected, (state, action) => {
                if (String(action.payload).toLowerCase() === 'cancel') {
                    state.balanceScoreError = null;

                    return;
                }

                state.balanceScoreError  = action.payload as unknown as string;
                state.balanceScoreStatus = DataStatus.FAILED;
            });
    },
});

const {
    clearState,
} = genderTrackerSlice.actions;

export { clearState };
export const selectFromGenderTracker = {
    singleArticle: (state: RootState) => ({
        data: state.genderTrackerReducer.singleArticle,
        error: state.genderTrackerReducer.singleArticleError,
        status: state.genderTrackerReducer.singleArticleStatus,
    }),
    topWorstArticles: (state: RootState) => ({
        data: state.genderTrackerReducer.topWorstArticles,
        error: state.genderTrackerReducer.topWorstArticlesError,
        status: state.genderTrackerReducer.topWorstArticlesStatus,
    }),
    topWorstAuthors: (state: RootState) => ({
        data: state.genderTrackerReducer.topWorstAuthors,
        error: state.genderTrackerReducer.topWorstAuthorsError,
        status: state.genderTrackerReducer.topWorstAuthorsStatus,
    }),
    overallScore: (state: RootState) => ({
        data: state.genderTrackerReducer.overallScore,
        error: state.genderTrackerReducer.overallScoreError,
        status: state.genderTrackerReducer.overallScoreStatus,
    }),
    uniqueLanguages: (state: RootState) => ({
        data: state.genderTrackerReducer.uniqueLanguages,
        error: state.genderTrackerReducer.uniqueLanguagesError,
        status: state.genderTrackerReducer.uniqueLanguagesStatus,
    }),
    articlesTable: (state: RootState) => ({
        data: state.genderTrackerReducer.articlesTable,
        error: state.genderTrackerReducer.articlesTableError,
        status: state.genderTrackerReducer.articlesTableStatus,
    }),
    balanceScore: (state: RootState) => ({
        data: state.genderTrackerReducer.balanceScore,
        error: state.genderTrackerReducer.balanceScoreError,
        status: state.genderTrackerReducer.balanceScoreStatus,
    }),
};

const genderTrackerReducer = genderTrackerSlice.reducer;

export default genderTrackerReducer;
