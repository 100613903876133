import { ActionError } from '@shared/enum/actionError';

export const ApiErrorMessage = 'Something went wrong, please contact an administrator!';
export const ServerErrorMessage = 'Server error, please try again later. If this error persists, contact an administrator!';

export const ErrorMessages = {
    actionError: {
        [ActionError.AuthorNotFound]: 'Author not found!',
        [ActionError.CategoryNotFound]: 'Category not found!',
        [ActionError.UrlNotFound]: 'Url not found!',
        [ActionError.NoResponse]: 'Server error, server did not respond. If this error persists, contact an administrator!',
        [ActionError.ServerError]: 'Server error, please try again later. If this error persists, contact an administrator!',
        [ActionError.NetworkError]: 'Lost internet connection!',
        [ActionError.NoSocialMediaProfile]: 'No social media profile found!',
    }
};
