import { gsap } from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useGSAP } from '@gsap/react';
import classNames from 'classnames';
import './Popup.sass';

declare interface PopupProps {
    contentClassName?: string;
    children?: React.ReactNode;
    trigger?: boolean;
    shouldCloseOnFocusOut?: boolean;
    onFocusOut?: (event?: FocusEvent) => void;
    /**
     * default delay: 0
     */
    animationDelay?: number;
}

function Popup({ contentClassName, children, trigger, shouldCloseOnFocusOut, animationDelay = 0, onFocusOut }: PopupProps) {
    const [container, setContainer] = useState<Element>(document.getElementById('popup-container'));

    useEffect(() => setContainer(document.getElementById('popup-container')), []);

    const popupContent = useRef<HTMLDivElement>();
    const wrapper      = useRef<HTMLDivElement>();
    const selector     = gsap.utils.selector(wrapper);

    useGSAP(() => {
        if (!wrapper.current) {
            return;
        }

        animatePopupIn(selector, animationDelay);

        return () => {
            animatePopupOut(selector);
        };
    }, { dependencies: [trigger], scope: wrapper });

    if (!trigger){
        return null;
    }

    return container ? createPortal(
        <div className='popup-wrapper' ref={wrapper}>
            <div className="popup-clip" onClick={() => shouldCloseOnFocusOut && onFocusOut?.()} />

            <div className={classNames('popup-content', { [contentClassName]: !!contentClassName })} ref={popupContent}>
                {children ? children : 'popup-children'}
            </div>
        </div>,
        container
    ) : null;
}

function animatePopupIn(selector: gsap.utils.SelectorFunc, animationDelay: number){
    const timeline = gsap.timeline();

    timeline
        .to(selector('.popup-clip'), {
            delay: animationDelay,
            opacity: .7,
            duration: .6,
            ease: 'Expo.easeOut'
        })
        .to(selector('.popup-content'), {
            opacity: 1,
            scale: 1,
            duration: .3,
            ease: 'Expo.easeOut'
        }, '-=.5');
}

function animatePopupOut(selector: gsap.utils.SelectorFunc){
    const timeline = gsap.timeline();

    timeline
        .to(selector('.popup-clip'), {
            opacity: 0,
            ease: 'Expo.easeOut'
        })
        .to(selector('.popup-content'), {
            opacity: 0,
            scale: .5,
            ease: 'Expo.easeOut'
        });
}

export default Popup;
