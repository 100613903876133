/* eslint-disable @typescript-eslint/naming-convention */
import { LiveAPIBaseParams, LiveAPIClient } from '@services/axios/clients/liveAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { Live, LiveActivePages, LiveCirculations, LiveCountries } from '@shared/interfaces/store/live';

export interface FetchLiveReturn {
    [key: string]: any;
}
export const fetchLive = createAsyncThunkWithClient<Live, LiveAPIBaseParams>({
    requestFn: (data, config) => LiveAPIClient.getLive(data, config),
    typePrefix: 'live/getLive',
});

export const fetchLiveActivePages = createAsyncThunkWithClient<LiveActivePages, LiveAPIBaseParams>({
    requestFn: (data, config) => LiveAPIClient.getActivePages(data, config),
    typePrefix: 'live/getLiveActivePages',
});

export const fetchLiveCountries = createAsyncThunkWithClient<LiveCountries, LiveAPIBaseParams>({
    requestFn: (data, config) => LiveAPIClient.getCountries(data, config),
    typePrefix: 'live/getLiveCountries',
});

export const fetchLiveCirculations = createAsyncThunkWithClient<LiveCirculations, LiveAPIBaseParams>({
    requestFn: (data, config) => LiveAPIClient.getCirculation(data, config),
    typePrefix: 'live/getLiveCirculations',
});
