import { useGSAP } from '@gsap/react';
import { Expo, gsap } from 'gsap';
import { useRef, useState } from 'react';

import { ThemeColors } from '@constants/colors';
import { isMobileSm } from '@constants/media';
import useUpdateEffect from '@hooks/update-effect/useUpdateEffect';

import './ToggleButton.sass';

interface Props {
    defaultValue?: boolean;
    callback: (value: boolean) => void;
    reverse?: boolean;
    containerStyle?: React.CSSProperties;
    ballStyle?: React.CSSProperties;
    transitionFixInPx?: string;
    isDisabled?: boolean;
}

const ToggleButton = ({ defaultValue, callback, reverse, containerStyle, ballStyle, transitionFixInPx, isDisabled }: Props) => {
    const [active, setActive]               = useState<boolean>(defaultValue);
    const container                         = useRef<HTMLDivElement>();
    const selector: gsap.utils.SelectorFunc = gsap.utils.selector(container);

    useUpdateEffect(() => setActive(defaultValue), [defaultValue]);

    useGSAP(() => {
        let left = !active ? '5px' : isMobileSm ? '27px' : '35px';

        if (transitionFixInPx) {
            left = !active ? '3px' : transitionFixInPx;
        }

        gsap.timeline()
            .to(container.current, {
                duration: 0.3,
                background: active ? ThemeColors.themeHighlightColor : ThemeColors.themeGrayColor,
                ease: Expo.easeInOut,
            })
            .to(
                selector('.tb-ball'),
                {
                    duration: 0.3,
                    left,
                    ease: Expo.easeInOut,
                },
                '-=.3',
            );
    }, { dependencies: [active], scope: container });

    return (
        <div
            ref={container}
            data-testid="tb-wrapper"
            className={'tb-wrapper' + (active && !isDisabled ? ' active' : '') + (isDisabled ? ' disabled' : '')}
            onClick={
                () => setActive((active) => {
                    if (isDisabled){
                        return defaultValue;
                    }

                    callback(!active);

                    return !active;
                })
            }
            style={
                reverse
                    ? { ...containerStyle, transform: 'rotate(180deg)' }
                    : containerStyle
            }
        >
            <div
                style={ballStyle}
                data-testid="tb-ball"
                className="tb-ball"
            ></div>
        </div>
    );
};

ToggleButton.defaultProps = {
    defaultValue: false,
    isDisabled: false,
};

export default ToggleButton;
