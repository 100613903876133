import { lazy, Suspense } from 'react';
import Dropdown from 'react-dropdown';

const NarrowArrowDownSmall    = lazy(() => import('components/svg/arrows/NarrowArrowDownSmall').then(module => ({ default: module.NarrowArrowDownSmall })));

interface Props {
    defaultSelectedNbResults?: number;
    setNbResults: (value: number) => void;
}

export const TablePaginationDropdownOptions = ['5', '10', '15', '20', '25'];
export const TableInitialNumberOfResultsPerPage = 1;

const TablePaginationResultsCount = ({ defaultSelectedNbResults = TableInitialNumberOfResultsPerPage, setNbResults }: Props) => {
    const handleChange = (e) => setNbResults(parseInt(e.label, 10));

    return (
        <div className="cbt-table-res-count">
            <p>Number of results: </p>
            <Suspense fallback={''}>
                <Dropdown
                    options={TablePaginationDropdownOptions}
                    onChange={handleChange}
                    value={TablePaginationDropdownOptions[defaultSelectedNbResults]}
                    placeholder="Select a metric"

                    className='cbt-dp'
                    controlClassName='cbt-dp-control'
                    placeholderClassName='cbt-dp-placeholder'
                    menuClassName='cbt-dp-menu'
                    arrowClassName='cbt-dp-arrow'

                    arrowClosed={<NarrowArrowDownSmall />}
                    arrowOpen={<NarrowArrowDownSmall style={{ transform: 'rotate(180deg)' }} />}
                />
            </Suspense>
        </div>
    );
};

export default TablePaginationResultsCount;
