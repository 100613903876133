import { type Dispatch } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ComponentProps, useEffect, useState } from 'react';

import Popup from '@components/widgets/popup/Popup';
import ErrorPopupContent from '@errors/error-popup-content/ErrorPopupContent';
import { useAppDispatch } from '@hooks/redux/useRedux';
import useGetCurrentUser from '@hooks/user/useGetCurrentUser';
import { AuthToken } from '@services/auth-token/authToken';
import axios from '@services/axios/axios';
import { AuthorsAPIClient } from '@services/axios/clients/authorsAPIClient';
import { CountriesAPIClient } from '@services/axios/clients/countriesAPIClient';
import { setSelectedSiteStartDay } from '@services/client-config';
import { getInitialDatePickerDates } from '@services/date/dateUtils';
import { getShouldShowAuthorFeatures, getShouldShowLanguageFeatures } from '@services/feature-validation/featureValidation';
import { logoutUser, setCurrentUser, setCurrentUserSettings } from '@store/authentication/authentication.actions';
import { setDatePickerDates } from '@store/date-picker/datePicker.actions';

const PRODUCTION_BASE_DOMAIN = 'indepth-analytics';
const LOCAL_BASE_DOMAIN      = 'indepth.local';

const withAuth = (Component: (props: ComponentProps<any>) => JSX.Element) => (props: ComponentProps<any>) => {
    const dispatch            = useAppDispatch();
    const [error, setError]   = useState({ trigger: false, title: '', message: '' });
    const currentUser         = useGetCurrentUser();
    const shouldOverwriteUser = isEmpty(currentUser) || !currentUser?.username || !currentUser?.site;

    useEffect(() => {
        const shouldTokenBeValid = isTokenInCookies();

        if (!shouldTokenBeValid) {
            setError({
                trigger: true,
                title: 'Authentication Error',
                message: 'You\'re not logged in!',
            });

            handleLogout();

            return;
        }

        const tokenHandler     = shouldTokenBeValid;
        const [clientUsername] = window.location.hostname.split('.');

        if (shouldOverwriteUser || clientUsername !== currentUser.site.slug) {
            dispatch(setCurrentUser(tokenHandler));
        }

        const {  site  }   = tokenHandler;
        const clientName   = site.slug;
        const hasAuthors   = getShouldShowAuthorFeatures(clientName);
        const hasLanguages = getShouldShowLanguageFeatures(clientName);

        dispatch(setCurrentUserSettings({
            hasAuthors,
            hasLanguages,
        }));

        /**
         * if someone tries to replace the username with another username, logout!
         */
        if (window.location.hostname.includes(PRODUCTION_BASE_DOMAIN) || window.location.hostname.includes(LOCAL_BASE_DOMAIN)) {
            /**
             * example hostname:
             *  - username.indepth-analytics.com(this is production, on the server)
             *  - username.indepth.local(this is local environment/machine)
             */
            const [clientUsername] = window.location.hostname.split('.');

            if (clientUsername !== site.slug) {
                setError({
                    trigger: true,
                    title: 'Authentication Error',
                    message: `The client named \`${clientUsername}\` is not logged in, redirecting to logout!`,
                });

                handleLogout();
            }
        }

        const selectedSite = tokenHandler.site;
        const startDay     = selectedSite.config.start_day;

        setSelectedSiteStartDay(startDay ? startDay.split('T')?.[0] : startDay);
        initializeDatePicker(dispatch);

        // watch cookies for expiration
        const cookiesWatcher = setInterval(() =>  {
            const token = AuthToken.getToken();

            if (!token) {
                handleLogout();
            }
        }, 30 * 60 * 1000); // run every 30 minutes

        return () => clearInterval(cookiesWatcher);
    }, []);

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <>
            <Popup trigger={error.trigger} shouldCloseOnFocusOut onFocusOut={() => handleLogout()}>
                <ErrorPopupContent
                    title={error.title}
                    message={error.message}
                    callback={handleLogout}
                />
            </Popup>

            {!error.trigger ? <Component {...props} /> : null}
        </>
    );

};

const initializeDatePicker = (dispatch: Dispatch<any>) => {
    const { startDate, endDate, compareStartDate, compareEndDate } = getInitialDatePickerDates();

    dispatch(
        setDatePickerDates(
            [startDate, endDate],
            [compareStartDate, compareEndDate],
            'HOC'
        )
    );
};

export function isTokenInCookies() {
    const token        = AuthToken.getToken();

    if (!token){
        return false;
    }

    const tokenHandler = new AuthToken(token);

    if (!tokenHandler.isValid) {
        return false;
    }

    axios.initializeConfig(tokenHandler);
    AuthorsAPIClient.initConfig(tokenHandler);
    CountriesAPIClient.initConfig(tokenHandler);

    return tokenHandler.decodedToken;
}

export default withAuth;
