
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataStatus } from '@shared/enum/dataStatus';
import { Live, LiveActivePages, LiveCirculations, LiveCountries } from '@shared/interfaces/store/live';
import { RootState } from '@store/storeConfig';
import { fetchLive, fetchLiveActivePages, fetchLiveCirculations, fetchLiveCountries } from './live.thunk';

export declare interface CountryState {
    live: Live;
    liveError: string | null;
    liveStatus: DataStatus;
    liveRefetch: boolean;

    activePages: LiveActivePages;
    activePagesError: string | null;
    activePagesStatus: DataStatus;
    activePagesRefetch: boolean;

    countries: LiveCountries;
    countriesError: string | null;
    countriesStatus: DataStatus;
    countriesRefetch: boolean;

    circulations: LiveCirculations;
    circulationsError: string | null;
    circulationsStatus: DataStatus;
    circulationsRefetch: boolean;
}

const initialState: CountryState = {
    live: null,
    liveError: null,
    liveStatus: DataStatus.IDLE,
    liveRefetch: false,

    activePages: null,
    activePagesError: null,
    activePagesStatus: DataStatus.IDLE,
    activePagesRefetch: false,

    countries: [],
    countriesError: null,
    countriesStatus: DataStatus.IDLE,
    countriesRefetch: false,

    circulations: null,
    circulationsError: null,
    circulationsStatus: DataStatus.IDLE,
    circulationsRefetch: false,
};

const updateLiveDataDefaultOptions = {
    liveRefetch: true,
    activePagesRefetch: true,
    countriesRefetch: true,
    circulationsRefetch: true,
};

export const liveSlice = createSlice({
    name: 'live',
    initialState,
    reducers: {
        clearState: () => initialState,
        updateLiveData: (state, action?: PayloadAction<typeof updateLiveDataDefaultOptions>) => {
            const options = action.payload ?? updateLiveDataDefaultOptions;

            state.liveRefetch         = options.liveRefetch || false;
            state.activePagesRefetch  = options.activePagesRefetch || false;
            state.countriesRefetch    = options.countriesRefetch || false;
            state.circulationsRefetch = options.circulationsRefetch || false;
        }
    },
    extraReducers: (builder) => {
        builder
            // live
            .addCase(fetchLive.pending, (state) => {
                state.liveStatus = DataStatus.LOADING;
            })
            .addCase(fetchLive.fulfilled, (state, action) => {
                state.live        = action.payload;
                state.liveError   = null;
                state.liveStatus  = DataStatus.SUCCESS;
                state.liveRefetch = false;
            })
            .addCase(fetchLive.rejected, (state, action) => {
                state.liveStatus = DataStatus.FAILED;
                state.liveError  = action.payload as unknown as string;
            })
            // active pages
            .addCase(fetchLiveActivePages.pending, (state) => {
                state.activePagesStatus = DataStatus.LOADING;
            })
            .addCase(fetchLiveActivePages.fulfilled, (state, action) => {
                state.activePages        = action.payload;
                state.activePagesError   = null;
                state.activePagesStatus  = DataStatus.SUCCESS;
                state.activePagesRefetch = false;
            })
            .addCase(fetchLiveActivePages.rejected, (state, action) => {
                state.activePagesStatus = DataStatus.FAILED;
                state.activePagesError  = action.payload as unknown as string;
            })
            // countries
            .addCase(fetchLiveCountries.pending, (state) => {
                state.countriesStatus = DataStatus.LOADING;
            })
            .addCase(fetchLiveCountries.fulfilled, (state, action) => {
                state.countries        = action.payload;
                state.countriesError   = null;
                state.countriesStatus  = DataStatus.SUCCESS;
                state.countriesRefetch = false;
            })
            .addCase(fetchLiveCountries.rejected, (state, action) => {
                state.countriesStatus = DataStatus.FAILED;
                state.countriesError  = action.payload as unknown as string;
            })
            // circulation
            .addCase(fetchLiveCirculations.pending, (state) => {
                state.circulationsStatus = DataStatus.LOADING;
            })
            .addCase(fetchLiveCirculations.fulfilled, (state, action) => {
                state.circulations        = action.payload;
                state.circulationsError   = null;
                state.circulationsStatus  = DataStatus.SUCCESS;
                state.circulationsRefetch = false;
            })
            .addCase(fetchLiveCirculations.rejected, (state, action) => {
                state.circulationsStatus = DataStatus.FAILED;
                state.circulationsError  = action.payload as unknown as string;
            })
        ;
    }
});

export const {
    clearState,
    updateLiveData
} = liveSlice.actions;
export const selectFromLive = {
    live: (state: RootState) => ({
        shouldRefetch: state.liveReducer.liveRefetch,
        data: state.liveReducer.live,
        error: state.liveReducer.liveError,
        status: state.liveReducer.liveStatus,
    }),
    activePages: (state: RootState) => ({
        shouldRefetch: state.liveReducer.activePagesRefetch,
        data: state.liveReducer.activePages,
        error: state.liveReducer.activePagesError,
        status: state.liveReducer.activePagesStatus,
    }),
    countries: (state: RootState) => ({
        shouldRefetch: state.liveReducer.countriesRefetch,
        data: state.liveReducer.countries,
        error: state.liveReducer.countriesError,
        status: state.liveReducer.countriesStatus,
    }),
    circulations: (state: RootState) => ({
        shouldRefetch: state.liveReducer.circulationsRefetch,
        data: state.liveReducer.circulations,
        error: state.liveReducer.circulationsError,
        status: state.liveReducer.circulationsStatus,
    }),
};
const liveReducer = liveSlice.reducer;

export default liveReducer;
