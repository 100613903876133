import { addMonths } from 'date-fns';
import { useEffect } from 'react';

import useDeepCompareEffect from 'hooks/deep-compare-effect/useDeepCompareEffect';
import { useAppSelector } from 'hooks/redux/useRedux';
import DateInstance from 'services/date/date';

export default function useDatePickerSecondMonthTitle({ nextAppended }) {
    const selectionState = useAppSelector(state => state.datePickerReducer.rangePickerSelection);

    useDeepCompareEffect(() => {
        if (!nextAppended) {
            nextAppended = true;

            const nextMonth = DateInstance.formatDate({ date: addMonths(selectionState.selection1.startDate, 1), format: 'MMMM yyyy' });

            generateDatePickerHeader(nextMonth);
        }
    }, [selectionState]);

    const getSelected = ({ monthSelect, yearSelect }) => {
        // get month
        const monthIndex  = monthSelect.options.selectedIndex;
        let selectedMonth = monthSelect.options[monthIndex]?.innerHTML;

        selectedMonth = getMonthNumber(selectedMonth);

        // get year
        const yearIndex    = yearSelect.options.selectedIndex;
        const selectedYear = yearSelect.options[yearIndex]?.innerHTML;

        return [selectedMonth, selectedYear];
    };

    function updateTitlePlaceholder(monthSelect, yearSelect) {
        setTimeout(() => {
            const [month, year] = getSelected({ monthSelect, yearSelect });

            const parsed = DateInstance.parseDate(`${month + ' ' + year}`, 'MM yyyy', DateInstance.newDate());
            const result = DateInstance.formatDate({ date: addMonths(parsed, 1), format: 'MMMM yyyy' });

            const elementPlaceholder = document.querySelector('.rdrMonthAndYearNext');

            if (elementPlaceholder){
                elementPlaceholder.innerHTML = result;
            }
        }, 200);
    }

    function handleItemClick(monthSelect, yearSelect) {
        return updateTitlePlaceholder(monthSelect, yearSelect);
    }
    /* istanbul ignore next */
    useEffect(() => {
        const monthSelect = document.querySelector('.rdrMonthPicker select');
        const yearSelect  = document.querySelector('.rdrYearPicker select');

        // prev month
        document
            .querySelector('.rdrPprevButton')
            .addEventListener('click', () => updateTitlePlaceholder(monthSelect, yearSelect));

        // next month
        document
            .querySelector('.rdrNextButton')
            .addEventListener('click', () => updateTitlePlaceholder(monthSelect, yearSelect));

        monthSelect.onchange = () => setTimeout(() => updateTitlePlaceholder(monthSelect, yearSelect), 200);
        yearSelect.onchange  = () => setTimeout(() => updateTitlePlaceholder(monthSelect, yearSelect), 200);

        // shortcut
        const shortcuts = document.querySelectorAll('.dp-shortcut');

        shortcuts.forEach((item) =>
            item.addEventListener('click', () =>
                handleItemClick(monthSelect, yearSelect),
            ),
        );

        return () => {
            const prev = document.querySelector('.rdrPprevButton');

            prev && prev.removeEventListener('click', () => updateTitlePlaceholder(monthSelect, yearSelect));

            const next = document.querySelector('.rdrNextButton');

            next && next.removeEventListener('click', () => updateTitlePlaceholder(monthSelect, yearSelect));

            shortcuts.forEach((item) => item && item.removeEventListener('click', () =>
                handleItemClick(monthSelect, yearSelect)
            ));
        };
    }, []);

    return () => {
        const monthSelect = document.querySelector('.rdrMonthPicker select');
        const yearSelect  = document.querySelector('.rdrYearPicker select');

        updateTitlePlaceholder(monthSelect, yearSelect);
    };
}

const generateDatePickerHeader = (nextMonth) => {
    const wrapper = document.querySelector('.rdrMonthAndYearWrapper');

    if (document.querySelector('.rdrMonthAndYearNext')) {
        document.querySelector('.rdrMonthAndYearNext').remove();
    }

    const el = document.createElement('div');

    el.innerHTML = nextMonth;
    el.setAttribute('class', 'rdrMonthAndYearNext');
    wrapper.appendChild(el);
};

const MONTHS = [ 'January', 'February', 'Mars', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

const getMonthNumber = (monthName) => MONTHS.map((month, index) => {
    if (month === monthName || month.includes(monthName)) {
        return index + 1;
    }
}).filter(Boolean)[0];
