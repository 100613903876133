import type { TopCategories } from '@shared/interfaces/store/categories/topCategories';
import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Audience } from 'shared/interfaces/store/audience';
import type { AudienceByTrafficSource } from 'shared/interfaces/store/audienceByTrafficSource';
import type { ConversionsHighlight } from 'shared/interfaces/store/conversions';
import type { ConversionsTotal } from 'shared/interfaces/store/conversionsTotal';

import {
    AUDIENCE_DATA_IS_LOADING, AUDIENCE_GET_ALL, AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE,
    AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE_FAILURE, AUDIENCE_GET_ALL_FAILURE, TRAFFIC_SOURCE_DATA_IS_LOADING,
    TRAFFIC_SOURCE_GET_ALL,
    TRAFFIC_SOURCE_GET_ALL_FAILURE,
    VIEWS_DATA_IS_LOADING,
    VIEWS_GET_ALL,
    VIEWS_GET_ALL_FAILURE
} from './views.types';

export declare interface ViewsState {
    audienceMetrics: Audience;
    audienceIsLoading: boolean;
    audienceIsError: string | null;
    audienceMetricsTs: AudienceByTrafficSource;

    trafficSourceData: AudienceByTrafficSource;
    trafficSourceError: string;
    trafficSourceIsLoading: boolean;

    categories: TopCategories;

    loyalVisitors: { value: number; pastValue: number; compare: { percentage: number; diff: number } };

    conversionsTotal: ConversionsTotal;

    conversions: ConversionsHighlight;

    requestSource: CancelTokenSource;
    error: string;
    isLoading: boolean;
}

const initialState: ViewsState = {
    audienceMetricsTs: null,

    audienceMetrics: null,
    audienceIsLoading: false,
    audienceIsError: null,

    trafficSourceData: null,
    trafficSourceIsLoading: false,
    trafficSourceError: null,

    categories: null,
    loyalVisitors: null,
    conversionsTotal: null,
    conversions: null,

    isLoading: false,
    requestSource: null,
    error: null,
};

const viewsReducer = function (state: ViewsState = initialState, action: ReducerAction): ViewsState {
    switch (action.type) {
        case AUDIENCE_DATA_IS_LOADING: {
            return {
                ...state,
                audienceIsLoading: true,
                audienceIsError: null,
            };
        }

        case AUDIENCE_GET_ALL: {
            return {
                ...state,
                audienceMetrics: action.payload,
                audienceIsError: null,
                audienceIsLoading: false
            };
        }

        case AUDIENCE_GET_ALL_FAILURE: {
            return {
                ...state,
                audienceIsError: action.payload.message,
                audienceIsLoading: false,
            };
        }

        case VIEWS_DATA_IS_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }

        case VIEWS_GET_ALL: {
            return {
                ...state,
                audienceMetrics: {
                    ...state.audienceMetrics,
                    loyalVisitors: action.payload.data?.loyalVisitors
                },
                categories: action.payload.data.categories,
                loyalVisitors: action.payload.data.loyalVisitors,
                conversionsTotal: action.payload.data.conversionsTotal,
                conversions: action.payload.data.conversions,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false
            };
        }

        case AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE: {
            return {
                ...state,
                audienceMetricsTs: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                audienceIsLoading: false
            };
        }

        case VIEWS_GET_ALL_FAILURE: case AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                audienceIsLoading: false,
                isLoading: false,
                trafficSourceIsLoading: false
            };

        case TRAFFIC_SOURCE_DATA_IS_LOADING: {
            return {
                ...state,
                trafficSourceIsLoading: true
            };
        }

        case TRAFFIC_SOURCE_GET_ALL: {
            return {
                ...state,
                trafficSourceData: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                trafficSourceIsLoading: false
            };
        }

        case TRAFFIC_SOURCE_GET_ALL_FAILURE:
            return {
                ...state,
                trafficSourceError: action.payload.message,
                trafficSourceIsLoading: false
            };

        default: {
            return state;
        }
    }
};

export default viewsReducer;
