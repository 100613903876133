import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

const OutlineClose = ({ ...rest }: BaseSvgComponentProps) => (
    <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        {...rest}
    >
        <path d="M3 3L39 39M3 39L39 3L3 39Z" stroke="#798BA5" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default OutlineClose;
