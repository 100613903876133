import Img from '@components/common/img/Img';
import useGetCurrentUser from '@hooks/user/useGetCurrentUser';

import './AppHeaderAvatar.scss';

const AppHeaderAvatar = () => {
    const user      = useGetCurrentUser();
    const avatar    = user?.avatar || null;
    const firstName = user?.firstname || 'Indepth';
    const lastName  = user?.lastname || 'Analytics';
    const website   = user?.site?.website ?? 'Unknown';

    return (
        <div className='app-header-avatar-wrapper'>
            <div className="app-header-avatar">
                {avatar ? <Img src={avatar} alt="avatar thumb"/> : (
                    <p>{formatName(firstName, lastName)}</p>
                )}
            </div>
            <div className="app-header-avatar-dets">
                <p>{`${firstName} ${lastName}`}</p>
                <p>{website}</p>
            </div>
            {/* <KeyboardArrowDownSmall /> */}
        </div>
    );
};

interface UserDefaultAvatarProps {
    firstName: string;
    lastName: string;
}

export const UserDefaultAvatar = ({ firstName, lastName }: UserDefaultAvatarProps) =>(
    <div className="user-default-avatar">
        <p>{formatName(firstName, lastName)}</p>
    </div>
);

const formatName = (first: string, last: string) => (
    String(first?.[0] || 'I').toUpperCase() + String(last?.[0] || 'A').toUpperCase()
);

export default AppHeaderAvatar;
