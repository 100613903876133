import type { SocialMediaProfiles } from '@shared/interfaces/social-media/socialMediaProfiles';

import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from '@shared/enum/dataStatus';
import { RootState } from '@store/storeConfig';
import { addSocialMediaProfile, deleteSocialMediaProfile, fetchSocialMediaProfiles } from './socialMediaSettings.thunk';

export declare interface SocialMediaState {
    data: SocialMediaProfiles;
    error: string | null;
    status: DataStatus;

    addProfileStatus: DataStatus;
    addProfileError: string | null;

    deleteProfileUrl: string;
    deleteProfileStatus: DataStatus;
    deleteProfileError: string | null;
}

const initialState: SocialMediaState = {
    data: null,
    error: null,
    status: DataStatus.IDLE,

    addProfileStatus: DataStatus.IDLE,
    addProfileError: null,

    deleteProfileUrl: null,
    deleteProfileStatus: DataStatus.IDLE,
    deleteProfileError: null
};

export const socialMediaSettingsSlice = createSlice({
    name: 'socialMediaSettings',
    initialState,
    reducers: {
        clearState: () => initialState,
        resetAddProfile: (state) => {
            state.addProfileStatus = initialState.addProfileStatus;
            state.addProfileError  = initialState.addProfileError;
        },
        resetDeleteProfile: (state) => {
            state.deleteProfileUrl    = initialState.deleteProfileUrl;
            state.deleteProfileStatus = initialState.deleteProfileStatus;
            state.deleteProfileError  = initialState.deleteProfileError;
        }
    },
    extraReducers: (builder) => {
        builder
            // get profiles
            .addCase(fetchSocialMediaProfiles.pending, (state) => {
                state.status = DataStatus.LOADING;
            })
            .addCase(fetchSocialMediaProfiles.fulfilled, (state, action) => {
                state.data   = action.payload;
                state.status = DataStatus.SUCCESS;
            })
            .addCase(fetchSocialMediaProfiles.rejected, (state, action) => {
                state.error  = action.payload as unknown as string;
                state.status = DataStatus.FAILED;
            })
            // add profile
            .addCase(addSocialMediaProfile.pending, (state) => {
                state.addProfileStatus = DataStatus.LOADING;
            })
            .addCase(addSocialMediaProfile.fulfilled, (state) => {
                state.addProfileStatus = DataStatus.SUCCESS;
            })
            .addCase(addSocialMediaProfile.rejected, (state, action) => {
                state.addProfileError  = action.payload as unknown as string;
                state.addProfileStatus = DataStatus.FAILED;
            })
            // delete profile
            .addCase(deleteSocialMediaProfile.pending, (state) => {
                state.deleteProfileStatus = DataStatus.LOADING;
            })
            .addCase(deleteSocialMediaProfile.fulfilled, (state, action) => {
                const url                 = decodeURIComponent(action.meta.arg.data.url);

                state.data                = state.data.filter(profile => profile.url !== url);
                state.deleteProfileUrl    = url;
                state.deleteProfileStatus = DataStatus.SUCCESS;
            })
            .addCase(deleteSocialMediaProfile.rejected, (state, action) => {
                state.deleteProfileError  = action.payload as unknown as string;
                state.deleteProfileStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState,
    resetAddProfile,
    resetDeleteProfile,
} = socialMediaSettingsSlice.actions;
export const selectedFromSocialMedia = {
    profiles: (state: RootState) => ({
        data: state.socialMediaSettingsReducer.data,
        error: state.socialMediaSettingsReducer.error,
        status: state.socialMediaSettingsReducer.status
    }),
    addProfile: (state: RootState) => ({
        error: state.socialMediaSettingsReducer.addProfileError,
        status: state.socialMediaSettingsReducer.addProfileStatus
    }),
    deleteProfile: (state: RootState) => ({
        url: state.socialMediaSettingsReducer.deleteProfileUrl,
        error: state.socialMediaSettingsReducer.deleteProfileError,
        status: state.socialMediaSettingsReducer.deleteProfileStatus
    }),
};

const socialMediaSettingsReducer = socialMediaSettingsSlice.reducer;

export default socialMediaSettingsReducer;
