import { useEffect, useState } from 'react';

import { calcPercentageOfCurrentFromPrevious, toFixed } from 'services/math/math';
import { AudienceSkeletonLayoutInitialSettings, AudienceSkeletonLayoutMaxContainerWidth, AudienceSkeletonLayoutSettings, AudienceSkeletonLayoutSvgElement } from './skeletonConfig';

declare interface Props { parentSize: { width: number; height: number } }

function AudienceSkeletonBuilder({ parentSize  }: Props){
    const [settings, setSettings] = useState<AudienceSkeletonLayoutSettings>(AudienceSkeletonLayoutInitialSettings);

    useEffect(() => {
        if (!parentSize){
            return;
        }

        const containerWidth   = parentSize.width;
        const growthPercentage = toFixed(
            Math.abs(
                calcPercentageOfCurrentFromPrevious(containerWidth, AudienceSkeletonLayoutMaxContainerWidth)
            ) / 100
        );

        const newSettings = Object.fromEntries(
            Object.entries(AudienceSkeletonLayoutInitialSettings).map(([name, data]) => {
                if (name === 'containerWidth'){
                    return [name, containerWidth];
                }

                const propData                = data as AudienceSkeletonLayoutSvgElement;
                const { x, y, width, height } = propData;

                const newX        = x - (x * growthPercentage);
                const newWidth    = width - (width * growthPercentage);
                const newPropData = {
                    x: newX <= 0 ? 0 : newX - (growthPercentage * 30),
                    y,
                    width: newWidth,
                    height
                };

                return [name, newPropData];
            })
        ) as AudienceSkeletonLayoutSettings;

        setSettings(newSettings);
    }, [parentSize]);

    return (
        <>
            <rect id="sk-header1" {...settings?.header1Props} rx="5" ry="5" />
            <rect id="sk-header2" {...settings?.header2Props} rx="5" ry="5" />

            <rect id="sk-datepicker" rx="5" ry="5" {...settings?.datepickerProps} />
            <rect id="sk-report" rx="5" ry="5" {...settings?.reportProps} />

            <rect id="sk-widget1" {...settings?.widget1Props} rx="5" ry="5" />

            <rect id="sk-widget2" {...settings?.widget2Props} rx="5" ry="5" />
            <rect id="sk-widget3" {...settings?.widget3Props} rx="5" ry="5" />
        </>
    );
}

export default AudienceSkeletonBuilder;
