export const Calendar = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" >
        <g clipPath="url(#clip0_6322_4736)">
            <path d="M3 5.75C3 5.35218 3.15804 4.97064 3.43934 4.68934C3.72064 4.40804 4.10218 4.25 4.5 4.25H13.5C13.8978 4.25 14.2794 4.40804 14.5607 4.68934C14.842 4.97064 15 5.35218 15 5.75V14.75C15 15.1478 14.842 15.5294 14.5607 15.8107C14.2794 16.092 13.8978 16.25 13.5 16.25H4.5C4.10218 16.25 3.72064 16.092 3.43934 15.8107C3.15804 15.5294 3 15.1478 3 14.75V5.75Z" stroke="#767CA8" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 2.75V5.75" stroke="#767CA8" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 2.75V5.75" stroke="#767CA8" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 8.75H15" stroke="#767CA8" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_6322_4736">
                <rect width="18" height="18" fill="transparent" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>
);
