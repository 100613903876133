export const Plus2Sm = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
        <g clipPath="url(#clip0_6322_4722)">
            <path d="M8 3.8335V13.1668" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.33203 8.5H12.6654" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_6322_4722">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>
);
