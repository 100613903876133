import type { CountriesTopContent, CountryAllContent, CountryHighlights, TopCountriesSessions, TopCountriesVisits } from '@shared/interfaces/store/country';

import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from '@shared/enum/dataStatus';
import { TopCategoriesItem } from '@shared/interfaces/store/categories/topCategories';
import { CompareDeviceRecord } from '@shared/interfaces/store/depthRate';
import { RootState } from '@store/storeConfig';
import { formatCountryDepthRate, formatCountryTopCategories } from './country.formatter';
import {
    fetchCountriesTopContent,
    fetchCountryDepthRate,
    fetchCountryHighlights,
    fetchCountryTopCategories,
    fetchCountryTopContent,
    fetchTopCountriesSessions,
    fetchTopCountriesVisits
} from './country.thunk';

export declare interface CountryState {
    topCountriesVisits: TopCountriesVisits;
    topCountriesVisitsError: string | null;
    topCountriesVisitsStatus: DataStatus;

    topCountriesSessions: TopCountriesSessions;
    topCountriesSessionsError: string | null;
    topCountriesSessionsStatus: DataStatus;

    countriesTopContent: CountriesTopContent;
    countriesTopContentError: string | null;
    countriesTopContentStatus: DataStatus;

    highlights: CountryHighlights;
    highlightsError: string | null;
    highlightsStatus: DataStatus;

    topContent: CountryAllContent;
    topContentError: string | null;
    topContentStatus: DataStatus;

    topCategories: TopCategoriesItem[];
    topCategoriesError: string | null;
    topCategoriesStatus: DataStatus;

    depthRate: CompareDeviceRecord;
    depthRateError: string | null;
    depthRateStatus: DataStatus;
}

const initialState: CountryState = {
    topCountriesVisits: null,
    topCountriesVisitsError: null,
    topCountriesVisitsStatus: DataStatus.IDLE,

    topCountriesSessions: null,
    topCountriesSessionsError: null,
    topCountriesSessionsStatus: DataStatus.IDLE,

    countriesTopContent: null,
    countriesTopContentError: null,
    countriesTopContentStatus: DataStatus.IDLE,

    highlights: null,
    highlightsError: null,
    highlightsStatus: DataStatus.IDLE,

    topContent: null,
    topContentError: null,
    topContentStatus: DataStatus.IDLE,

    topCategories: [],
    topCategoriesError: null,
    topCategoriesStatus: DataStatus.IDLE,

    depthRate: null,
    depthRateError: null,
    depthRateStatus: DataStatus.IDLE,
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        clearState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            /**
             * Countries extra reducers
             */
            // countries chart
            .addCase(fetchTopCountriesVisits.pending, (state) => {
                state.topCountriesVisitsStatus = DataStatus.LOADING;
            })
            .addCase(fetchTopCountriesVisits.fulfilled, (state, action) => {
                state.topCountriesVisits       = action.payload;
                state.topCountriesVisitsError  = null;
                state.topCountriesVisitsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchTopCountriesVisits.rejected, (state, action) => {
                state.topCountriesVisitsStatus = DataStatus.FAILED;
                state.topCountriesVisitsError  = action.payload as unknown as string;
            })

            // top sessions by country
            .addCase(fetchTopCountriesSessions.pending, (state) => {
                state.topCountriesSessionsStatus = DataStatus.LOADING;
            })
            .addCase(fetchTopCountriesSessions.fulfilled, (state, action) => {
                state.topCountriesSessions       = action.payload;
                state.topCountriesSessionsError  = null;
                state.topCountriesSessionsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchTopCountriesSessions.rejected, (state, action) => {
                state.topCountriesSessionsStatus = DataStatus.FAILED;
                state.topCountriesSessionsError  = action.payload as unknown as string;
            })

            // countries table
            .addCase(fetchCountriesTopContent.pending, (state) => {
                state.countriesTopContentStatus = DataStatus.LOADING;
            })
            .addCase(fetchCountriesTopContent.fulfilled, (state, action) => {
                state.countriesTopContent       = action.payload;
                state.countriesTopContentError  = null;
                state.countriesTopContentStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCountriesTopContent.rejected, (state, action) => {
                state.countriesTopContentStatus = DataStatus.FAILED;
                state.countriesTopContentError  = action.payload as unknown as string;
            })

            /**
             * Single country extra reducers
             */
            // highlights
            .addCase(fetchCountryHighlights.pending, (state) => {
                state.highlightsStatus = DataStatus.LOADING;
            })
            .addCase(fetchCountryHighlights.fulfilled, (state, action) => {
                const metrics          = action.payload;

                state.highlights       = {
                    depthRatePerDay: metrics?.depthRate,
                    onSite: metrics?.onSite,
                    bounceRate: metrics?.bounceRate,
                    views: metrics?.visits,

                    newVisitors: metrics?.newVisitor,
                    sessions: metrics?.sessions,
                    uniqueVisitors: metrics?.visitors,
                };
                state.highlightsError  = null;
                state.highlightsStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCountryHighlights.rejected, (state, action) => {
                state.highlightsStatus = DataStatus.FAILED;
                state.highlightsError  = action.payload as unknown as string;
            })

            // top content
            .addCase(fetchCountryTopContent.pending, (state) => {
                state.topContentStatus = DataStatus.LOADING;
            })
            .addCase(fetchCountryTopContent.fulfilled, (state, action) => {
                state.topContent       = action.payload;
                state.topContentError  = null;
                state.topContentStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCountryTopContent.rejected, (state, action) => {
                state.topContentStatus = DataStatus.FAILED;
                state.topContentError  = action.payload as unknown as string;
            })

            // topCategories
            .addCase(fetchCountryTopCategories.pending, (state) => {
                state.topCategoriesStatus = DataStatus.LOADING;
            })
            .addCase(fetchCountryTopCategories.fulfilled, (state, action) => {
                state.topCategories       = formatCountryTopCategories(action.payload);
                state.topCategoriesError  = null;
                state.topCategoriesStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCountryTopCategories.rejected, (state, action) => {
                state.topCategoriesStatus = DataStatus.FAILED;
                state.topCategoriesError  = action.payload as unknown as string;
            })

            // depthRate
            .addCase(fetchCountryDepthRate.pending, (state) => {
                state.depthRateStatus = DataStatus.LOADING;
            })
            .addCase(fetchCountryDepthRate.fulfilled, (state, action) => {
                state.depthRate       = formatCountryDepthRate(action.payload);
                state.depthRateError  = null;
                state.depthRateStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCountryDepthRate.rejected, (state, action) => {
                state.depthRateStatus = DataStatus.FAILED;
                state.depthRateError  = action.payload as unknown as string;
            })
        ;
    }
});

export const selectFromCountry = {
    topCountriesVisits: (state: RootState) => ({
        data: state.countryReducer.topCountriesVisits,
        error: state.countryReducer.topCountriesVisitsError,
        status: state.countryReducer.topCountriesVisitsStatus,
    }),
    topCountriesSessions: (state: RootState) => ({
        data: state.countryReducer.topCountriesSessions,
        error: state.countryReducer.topCountriesSessionsError,
        status: state.countryReducer.topCountriesSessionsStatus,
    }),
    countriesTopContent: (state: RootState) => ({
        data: state.countryReducer.countriesTopContent,
        error: state.countryReducer.countriesTopContentError,
        status: state.countryReducer.countriesTopContentStatus,
    }),

    
    highlights: (state: RootState) => ({
        highlights: state.countryReducer.highlights,
        error: state.countryReducer.highlightsError,
        status: state.countryReducer.highlightsStatus,
    }),
    topContent: (state: RootState) => ({
        topContent: state.countryReducer.topContent,
        error: state.countryReducer.topContentError,
        status: state.countryReducer.topContentStatus,
    }),
    topCategories: (state: RootState) => ({
        data: state.countryReducer.topCategories,
        error: state.countryReducer.topCategoriesError,
        status: state.countryReducer.topCategoriesStatus,
    }),
    depthRate: (state: RootState) => ({
        data: state.countryReducer.depthRate,
        error: state.countryReducer.depthRateError,
        status: state.countryReducer.depthRateStatus,
    }),
};
const countryReducer = countrySlice.reducer;

export default countryReducer;
