export const isInstanceOfActionError = (value: string) => {
    const isTrue = Object.values<any>(ActionError).includes(value);

    return isTrue;
};

export enum ActionError {
    AuthorNotFound = 'AUTHOR_NOT_FOUND',
    CategoryNotFound = 'CATEGORY_NOT_FOUND',
    UrlNotFound = 'URL_NOT_FOUND',
    ServerError = 'SERVER_ERROR',
    NetworkError = 'NETWORK_ERROR',
    NoResponse = 'NO_RESPONSE',
    NoSocialMediaProfile = 'NO_SOCIAL_MEDIA_PROFILE',
}
