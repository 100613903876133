import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { SinglePageMeta } from 'shared/interfaces/store/singlePageMeta';

import {
    SINGLE_META_GET,
    SINGLE_META_GET_ALL,
    SINGLE_META_GET_ALL_FAILURE,
    SINGLE_META_GET_DATA_IS_LOADING,
    SINGLE_META_GET_FAILURE,
    SINGLE_META_RESET_SEARCH
} from './singleMeta.types';

export declare interface SingleMetaState {
    meta: SinglePageMeta;
    search: SinglePageMeta[];
    requestSource: CancelTokenSource;
    error: string;
    isLoading: boolean;
}

const initialState: SingleMetaState = {
    meta: null,
    search: null,
    requestSource: null,
    error: null,
    isLoading: false,
};

const singleMetaReducer = function (state: SingleMetaState = initialState, action: ReducerAction): SingleMetaState {
    switch (action.type) {
        case SINGLE_META_GET_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case SINGLE_META_GET: {
            return {
                ...state,
                meta: action.payload.data?.[0],
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case SINGLE_META_GET_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
            };
        }

        case SINGLE_META_RESET_SEARCH: {
            return {
                ...state,
                search: null,
                isLoading: false,
                error: null,
            };
        }

        case SINGLE_META_GET_ALL: {
            return {
                ...state,
                search: action.payload.data.slice(0, 100),
                requestSource: action.payload.requestSource,
                isLoading: false,
                error: null,
            };
        }

        case SINGLE_META_GET_ALL_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
            };
        }


        default: {
            return state;
        }
    }
};

export default singleMetaReducer;
