/**
 *
 * Triggers a focus event on a specified element. Created specifically for `inputs` and
 * `textareas`.
 *
 * @param {HTMLElement} element the HTML element
 */
export const triggerFocusEvent = (element: HTMLElement): void => {
    const eventType = 'onfocusin' in element ? 'focusin' : 'focus';
    const bubbles   = 'onfocusin' in element;
    let event: Event;

    if ('createEvent' in document) {
        event = document.createEvent('Event');
        event.initEvent(eventType, bubbles, true);
    } else if ('Event' in window) {
        event = new Event(eventType, { bubbles, cancelable: true });
    }

    element.focus();
    element.dispatchEvent(event);
};

export const onWindowResize = (callback: (e: UIEvent) => void) => {
    document.addEventListener('resize', callback);
};

export function scrollToView(id: string, yDelta?: number) {
    const element = document.getElementById(id);
    const y       = element.getBoundingClientRect().top + window.scrollY;

    window.scroll({ top: y - (yDelta || 120), behavior: 'smooth' });
}
