import classNames from 'classnames';
import React from 'react';
import './SocialMediaAddButton.scss';

type SocialMediaAddButtonProps = React.PropsWithChildren<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>;

const SocialMediaAddButton = ({ children, type, className, ...rest }: SocialMediaAddButtonProps) => (
    <button
        className={classNames('socials-add-button', {
            [className]: !!className
        })}
        type={type ?? 'button'}
        {...rest}
    >
        {children ?? 'children'}
    </button>
);

export default SocialMediaAddButton;
