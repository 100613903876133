import classNames from 'classnames';

import { Img } from '@components/common';
import { SocialMediaLogos } from '@constants/socialMedia';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import './SocialMediaProfileAvatarSM.scss';

interface Props {
    thumbnail: string;
    networkType: SocialMediaTypes;
    className?: string;
}

const NetworkTypeToLogoMapper = {
    [SocialMediaTypes.FACEBOOK]: SocialMediaLogos.facebook.filled,
    [SocialMediaTypes.INSTAGRAM]: SocialMediaLogos.instagram.normal,
    [SocialMediaTypes.TWITTER]: SocialMediaLogos.twitter.filled,
    [SocialMediaTypes.YOUTUBE]: SocialMediaLogos.youtube.normal,
};

const SocialMediaProfileAvatarSM = ({ className, thumbnail, networkType }: Props) => (
    <div className={classNames('smp-head-avatar', { [className]: !!className })}>
        <Img className="smp-head-thumb" src={thumbnail} alt={`${networkType} thumbnail`} />
        <Img className="smp-head-network" src={NetworkTypeToLogoMapper?.[networkType]} alt={`${networkType} logo`} />
    </div>
);

export default SocialMediaProfileAvatarSM;
