import OutlineClose from 'components/svg/OutlineClose';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import { useCallback } from 'react';
import DateInstance from 'services/date/date';
import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import { setActiveShortcut, setRangeSelection } from 'store/date-picker/datePicker.actions';
import './DatePickerMobileControls.sass';

declare interface DatePickerMobileControlsProps {
    onSave: () => void;
    onCancel: () => void;
}

function DatePickerMobileControls({ onSave, onCancel }: DatePickerMobileControlsProps) {
    const dispatch                                                 = useAppDispatch();
    const { startDate, endDate, compareStartDate, compareEndDate, activeShortcut } = useAppSelector(state => ({
        startDate: state.datePickerReducer.current[0],
        endDate: state.datePickerReducer.current[1],
        compareStartDate: state.datePickerReducer.previous[0],
        compareEndDate: state.datePickerReducer.previous[1],
        activeShortcut: state.datePickerReducer.activeShortcut
    }));

    const handleSave   = useCallback(() => onSave(), [onSave]);
    const handleCancel = useCallback(() => {
        dispatch(
            setRangeSelection({
                selection1: {
                    startDate: DateInstance.newDate(startDate),
                    endDate: DateInstance.newDate(endDate),
                    key: 'selection1',
                },
                selection2: {
                    startDate: DateInstance.newDate(compareStartDate),
                    endDate: DateInstance.newDate(compareEndDate),
                    key: 'selection2',
                },
            })
        );

        dispatch(setActiveShortcut(DatepickerShortcuts.Last7Days));

        onCancel();
    }, [onCancel, startDate, endDate, compareStartDate, compareEndDate]);

    return (
        <div className="dp-mobile-controls" data-testid="dp-controls">
            <OutlineClose
                className='dp-mobile-close'
                onClick={() => handleCancel()}
            />

            <h4>{activeShortcut !== DatepickerShortcuts.None ? activeShortcut : ''}</h4>

            <div className="dp-mobile-save" onClick={() => handleSave()}>
                Set Date
            </div>
        </div>
    );
}

export default DatePickerMobileControls;
