import LoaderWrapper from '@components/widgets/loader/loader-wrapper/LoaderWrapper';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store/storeConfig';

const StoreProvider = ({ children }) => (
    <Provider store={store}>
        {/*
            Why use <PersistGate /> ??
            => Delay the rendering of our app’s UI until the persisted data is available in the Redux store
        */}
        <PersistGate loading={<LoaderWrapper />} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
);

export default StoreProvider;
