import { useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import type { RootState } from 'shared/interfaces/store/store';

import PrimaryButton from '@common/buttons/primary-button/PrimaryButton';
import SecondaryButton from '@common/buttons/secondary-button/SecondaryButton';
import { getIsTablet } from '@constants/media';
import PageWrapper from '@pages/base/PageWrapper';
import { DefinedRoutes } from '@routes/definedRoutes';

import './NotFound.sass';
import NotFoundAnimation from './animation/NotFoundAnimation';

function NotFound({ isAuthenticated }: { isAuthenticated: boolean }) {
    const size           = useWindowSize();
    const switchToMobile = useMemo(() => getIsTablet(), [size.width, size.height]);

    const navigate = useNavigate();
    const goBack   = () => navigate(-1);
    const goHome   = () => isAuthenticated === true ? navigate(DefinedRoutes.overview.path) : navigate('/');

    return (
        <PageWrapper
            pageTitle={'404'}
            headerInfo={
                switchToMobile ? (
                    <h1>404 not found</h1>
                ) : (
                    <>
                        <h1>404 not found</h1>
                        <p>The page you're looking for doesn't exist.</p>
                    </>
                )
            }
            shouldHideFilters
        >
            <div className="error_wrapper">
                <article className="error_contain">
                    <NotFoundAnimation />
                    <div className="error_options">
                        <SecondaryButton onClick={goBack}>
                            Go back
                        </SecondaryButton>
                        <PrimaryButton onClick={goHome}>
                            Go home
                        </PrimaryButton>
                    </div>
                </article>
            </div>
        </PageWrapper>
    );
}

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.authenticationReducer.isAuthenticated
});

export default connect(mapStateToProps)(NotFound);
