import { PrimaryButton, SecondaryButton } from '@components/common/buttons';
import { OutlineClose } from '@components/svg';
import { Popup } from '@components/widgets';

import './SocialMediaConfirmDelete.scss';

interface SocialMediaConfirmDeleteProps {
    shouldShow: boolean;
    onConfirm: () => void;
    onClose: () => void;
    isLoading?: boolean;
}

const SocialMediaConfirmDelete = ({
    shouldShow,
    onConfirm,
    onClose,
    isLoading = false
}: SocialMediaConfirmDeleteProps) => (
    <Popup contentClassName={'smcd-popup'} trigger={shouldShow} onFocusOut={onClose} shouldCloseOnFocusOut>
        <div className='social-media-confirm-delete'>
            <OutlineClose onClick={onClose}/>

            <h1>Confirm delete!</h1>
            <p>Are you sure you want to delete this social media platform?</p>

            <div>
                <SecondaryButton onClick={onClose}>
                    Cancel
                </SecondaryButton>
                <PrimaryButton isLoading={isLoading} onClick={onConfirm}>
                    Confirm
                </PrimaryButton>
            </div>
        </div>
    </Popup>
);

export default SocialMediaConfirmDelete;
