import { useEffect, useState } from 'react';

import { calcPercentageOfCurrentFromPrevious, toFixed } from 'services/math/math';
import { RealtimeSkeletonLayoutInitialSettings, RealtimeSkeletonLayoutMaxContainerWidth, RealtimeSkeletonLayoutSettings, RealtimeSkeletonLayoutSvgElement } from './skeletonConfig';

declare interface PageLoaderSkeletonLayoutBuilderProps { parentSize: { width: number; height: number } }

function RealtimeSkeletonBuilder({ parentSize  }: PageLoaderSkeletonLayoutBuilderProps){
    const [settings, setSettings] = useState<RealtimeSkeletonLayoutSettings>(RealtimeSkeletonLayoutInitialSettings);

    useEffect(() => {
        if (!parentSize){
            return;
        }

        const containerWidth   = parentSize.width;
        const growthPercentage = toFixed(
            Math.abs(
                calcPercentageOfCurrentFromPrevious(containerWidth, RealtimeSkeletonLayoutMaxContainerWidth)
            ) / 100
        );

        const newSettings = Object.fromEntries(
            Object.entries(RealtimeSkeletonLayoutInitialSettings).map(([name, data]) => {
                if (name === 'containerWidth'){
                    return [name, containerWidth];
                }

                const propData                = data as RealtimeSkeletonLayoutSvgElement;
                const { x, y, width, height } = propData;

                const newX        = x - (x * growthPercentage);
                const newWidth    = width - (width * growthPercentage);
                const newPropData = {
                    x: newX <= 0 ? 0 : newX - (growthPercentage * 30),
                    y,
                    width: newWidth,
                    height
                };

                return [name, newPropData];
            })
        ) as RealtimeSkeletonLayoutSettings;

        setSettings(newSettings);
    }, [parentSize]);

    return (
        <>
            <rect id="sk-header1" {...settings?.header1Props} rx="5" ry="5" />

            <rect id="sk-widget1" {...settings?.widget1Props} rx="5" ry="5" />
            <rect id="sk-widget2" {...settings?.widget2Props} rx="5" ry="5" />

            <rect id="sk-widget2" {...settings?.header2Props} rx="5" ry="5" />
            <rect id="sk-widget3" {...settings?.widget3Props} rx="5" ry="5" />
        </>
    );
}

export default RealtimeSkeletonBuilder;
