import React, { useEffect } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ThemeColors } from 'constants/colors';

export declare interface ToastMessageProps {
    toastType: 'success' | 'warn' | 'error';
    toastText: string;
    trigger: boolean;
}

const options: ToastOptions = {
    // autoClose: false,
    theme: 'light',
    // eslint-disable-next-line
    // @ts-ignore
    icon: AlertIcon,
    draggable: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    position: 'top-right',
    style: {
        borderRadius: '5px',
    },
    progressStyle: {
        background: ThemeColors.themeHighlightColor,
    },
};

const ToastMessage = ({ toastType, toastText, trigger }: ToastMessageProps) => {
    let toastId: any = null;

    useEffect(() => {
        if (trigger) {
            if (!toast.isActive(toastId)) {
                toastId = triggerToast(toastType, toastText);
            }
        }
    }, [trigger]);

    return null;
};

const triggerToast = (toastType: string, toastText: string): React.ReactText => {
    switch (toastType) {
        case 'success': {
            return toast.success(toastText, options);
        }

        case 'warn': {
            return toast.warn(toastText, options);
        }

        case 'error': {
            return toast.error(toastText, options);
        }

        default: {
            return toast(toastText, options);
        }
    }
};

export default ToastMessage;
