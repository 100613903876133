import DatePickerCustomizeCompare from './compare/DatePickerCustomizeCompare';
import DatePickerCustomizeInputForm from './input-form/DatePickerCustomizeInputForm';
import DatePickerCustomizeShortcuts from './shortcut/DatePickerCustomizeShortcuts';

function DatePickerCustomize() {

    return (
        <>
            <p className="dp-shortcuts-title">Customize</p>
            <DatePickerCustomizeInputForm />

            <DatePickerCustomizeShortcuts />

            <DatePickerCustomizeCompare />
        </>
    );
}

export default DatePickerCustomize;
