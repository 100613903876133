export const Logout = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_6322_7486)">
            <path d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 12H21L18 9" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 15L21 12" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_6322_7486">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);
