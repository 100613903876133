/* eslint-disable @typescript-eslint/naming-convention */
import { CountriesAPIClient } from '@services/axios/clients/countriesAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { DeviceOptions } from '@shared/enum/deviceOptions';
import { Languages } from '@shared/enum/languages';
import { ActionProps } from '@shared/interfaces/store/action/actionProps';
import { CountriesTopContent, TopCountriesSessions, TopCountriesVisits } from '@shared/interfaces/store/country';
import { CountryHighlightsItem } from '@shared/interfaces/store/country/countryHighlights';

/**
 * countries actions below
 */

export const fetchTopCountriesVisits = createAsyncThunkWithClient<TopCountriesVisits, Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'>>({
    requestFn: (data, config) => CountriesAPIClient.getCountriesChart(data, config),
    typePrefix: 'country/topCountriesVisits',
});

type FetchTopSessionsByCountrySig = Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'> & { limit: number; pageUrl?: string };
export const fetchTopCountriesSessions = createAsyncThunkWithClient<TopCountriesSessions, FetchTopSessionsByCountrySig>({
    requestFn: (data, config) => CountriesAPIClient.getTopSessionsByCountry(data, config),
    typePrefix: 'country/topCountriesSessions',
});

export enum CountriesTopContentSortOptions {
    Country = 'countryName',
    PageViews = 'visits',
    UniqueVisitors = 'visitors',
    Sessions = 'sessions',
    ReadingTime = 'onSite',
    BounceRate = 'depthRate',
    DepthRate = 'bounceRate'
}
type FetchCountriesTopContentSig = Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'> & {
    limit: number;
    page: number;
    filter: CountriesTopContentSortOptions;
    order: 'DESC' | 'ASC';
    search: string;
};
export const fetchCountriesTopContent = createAsyncThunkWithClient<CountriesTopContent, FetchCountriesTopContentSig>({
    requestFn: (data, config) => CountriesAPIClient.getCountriesTopContent(data, config),
    typePrefix: 'country/countriesTopContent',
});



/**
 * single country actions below
 */

type CountryBaseSig = Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'> & {
    country: string;
};
type FetchCountryHighlightsSig = Pick<ActionProps, 'per'> & CountryBaseSig;
interface FetchCountryHighlightsReturn {
    bounceRate: CountryHighlightsItem;
    depthRate: CountryHighlightsItem;
    newVisitor: CountryHighlightsItem;
    onSite: CountryHighlightsItem;
    sessions: CountryHighlightsItem;
    visitors: CountryHighlightsItem;
    visits: CountryHighlightsItem;
}

export const fetchCountryHighlights = createAsyncThunkWithClient<FetchCountryHighlightsReturn, FetchCountryHighlightsSig>({
    requestFn: (data, config) => CountriesAPIClient.getHighlights(data, config),
    typePrefix: 'country/getHighlights',
});

export enum CountryTopContentSortOptions {
    PublicationDate = 'publicationDate',
    PageViews = 'pageViews',
    UniqueVisitors = 'uniqueVisitors',
    Sessions = 'sessions',
    ReadingTime = 'onPage',
    BounceRate = 'bounceRate',
    DepthRate = 'depthRate'
}

type FetchCountryTopContentSig =  CountryBaseSig & {
    page: number;
    limit: number;
    filter?: CountryTopContentSortOptions;
    order?: 'ASC' | 'DESC';
    search?: string;
    language?: Languages;
};
export const fetchCountryTopContent = createAsyncThunkWithClient<any, FetchCountryTopContentSig>({
    requestFn: (data, config) => CountriesAPIClient.getTopContent(data, config),
    typePrefix: 'country/getTopContent',
});

type FetchCountryDepthRateSig = CountryBaseSig & { device: Omit<DeviceOptions, DeviceOptions.Tablet> };
export interface FetchCountryDepthRateReturnDepthItem {
    visits: number;
    percentageOfVistorsByTotalVisitors: number;
    diff: number;
}
export interface FetchCountryDepthRateReturn {
    '0': FetchCountryDepthRateReturnDepthItem;
    '25': FetchCountryDepthRateReturnDepthItem;
    '50': FetchCountryDepthRateReturnDepthItem;
    '75': FetchCountryDepthRateReturnDepthItem;
    '100': FetchCountryDepthRateReturnDepthItem;
}
export const fetchCountryDepthRate = createAsyncThunkWithClient<FetchCountryDepthRateReturn, FetchCountryDepthRateSig>({
    requestFn: (data, config) => CountriesAPIClient.getDepthRate(data, config),
    typePrefix: 'country/getDepthRate',
});

export interface FetchCountryTopCategoriesReturnItem {
    category: string;
    visits: number;
    compare: {
        diff: number;
        percentage: number;
    };
}
export const fetchCountryTopCategories = createAsyncThunkWithClient<FetchCountryTopCategoriesReturnItem[], CountryBaseSig>({
    requestFn: (data, config) => CountriesAPIClient.getTopCategories(data, config),
    typePrefix: 'country/topCategories',
});
