import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { SingleSearch } from 'shared/interfaces/store/singleSearch';

import {
    SINGLE_SEARCH_DATA_IS_LOADING,
    SINGLE_SEARCH_GET_ALL_FAILURE,
    SINGLE_SEARCH_GET_ALL_SUCCESS
} from './singleSearch.types';

export declare interface SingleSearchState {
    result: SingleSearch;
    requestSource: CancelTokenSource;
    error: string;
    isLoading: boolean;
}

const initialState: SingleSearchState = {
    result: null,
    requestSource: null,
    error: null,
    isLoading: true
};

const singleSearchReducer = function (state: SingleSearchState = initialState, action: ReducerAction): SingleSearchState {
    switch (action.type) {
        case SINGLE_SEARCH_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case SINGLE_SEARCH_GET_ALL_SUCCESS: {
            return {
                result: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case SINGLE_SEARCH_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default singleSearchReducer;
