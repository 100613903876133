export const DATEPICKER_SET_DATES = 'DATEPICKER_SET_DATES';
export const DATEPICKER_RESET_DATES = 'DATEPICKER_RESET_DATES';

export const DATEPICKER_SET_DATE = 'DATEPICKER_SET_DATE';
export const DATEPICKER_SET_COMPARE_DATE = 'DATEPICKER_SET_COMPARE_DATE';

export const DATEPICKER_SET_SELECTION_RANGE = 'DATEPICKER_SET_SELECTION_RANGE';

export const DATEPICKER_SET_ACTIVE_SHORTCUT = 'DATEPICKER_SET_ACTIVE_SHORTCUT';

export const DATEPICKER_TOGGLE_COMPARE = 'DATEPICKER_TOGGLE_COMPARE';

export const DATEPICKER_TOGGLE_DISABLE = 'DATEPICKER_TOGGLE_DISABLE';

export const DATEPICKER_SET_ERROR = 'DATEPICKER_SET_ERROR';
