import { useState } from 'react';

import HeaderButton from '@components/common/buttons/header-button/HeaderButton';
import InputFile from '@components/common/inputs/file/InputFile';
import InputSelect from '@components/common/inputs/select/InputSelect';
import InputTextArea from '@components/common/inputs/textarea/InputTextArea';
import OutlineClose from '@components/svg/OutlineClose';
import { BugReport as BugReportIcon } from '@components/svg/header';
import { Apple, Windows } from '@components/svg/system';
import useInputState from '@hooks/useInputState';
import { Pages } from '@shared/enum/pages';

import FormButton from '@components/common/buttons/form-button/FormButton';
import Popup from '../popup/Popup';
import './BugReport.scss';

interface SelectOption {
    readonly value: string;
    readonly label: Pages;
}
const pages = Object.values(Pages);

const dropdownOptions: SelectOption[] = [
    ...pages.map((page, idx) => ({
        label: page,
        value: String(idx+1)
    }))
];

const BugReport       = () => {
    const [selectedPage, setSelectedPage] = useState<Pages>(null);
    const [isPopupOpen, setIsPopupOpen]   = useState(false);
    const [description, onChange]         = useInputState('');

    const close = () => setIsPopupOpen(false);
    const open  = () => setIsPopupOpen(true);

    const submitReport = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return (
        <div>
            <HeaderButton onClick={open}>
                <BugReportIcon />
            </HeaderButton>

            <Popup
                contentClassName={'bug-report-popup'}
                trigger={isPopupOpen}
                onFocusOut={close}
                shouldCloseOnFocusOut
            >
                <header>
                    <h2>Report bug</h2>
                    <div className="bug-report-close">
                        <OutlineClose onClick={close} />
                    </div>
                </header>
                <form onSubmit={submitReport}>
                    <InputSelect
                        label='page'

                        dropdownOptions={dropdownOptions}
                        isDropdownDisabled={false}
                        onFocus={() => null}
                        onChange={(selectedOption: SelectOption) => setSelectedPage(selectedOption.label)}
                    />
                    <InputFile text='Add a screenshot of the bug' />
                    <InputTextArea onChange={onChange} value={description} placeholder='Bug description' />
                    <InputFile
                        text='Add a screenshot of the console'
                        children={<SystemShortcuts />}
                    />
                    <FormButton type="submit">
                        Send report
                    </FormButton>
                </form>
            </Popup>
        </div>
    );
};

const SystemShortcuts = () => (
    <div className="system-shortcuts">
        <Windows />
        <p>Ctrl + Shift + c</p>
        <Apple/>
        <p>Ctrl + Shift + c</p>
    </div>
);

export default BugReport;
