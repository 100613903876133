import React from 'react';

import './NoData.sass';

declare interface NoDataProps {
    message: string;
    hideHeader?: boolean;
    customStyle?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    messageStyle?: React.CSSProperties;
    header?: string;
}

const NoData = ({ hideHeader = true, customStyle, headerStyle, messageStyle, message, header }: NoDataProps) => (
    <article
        data-testid="nd-wrapper"
        style={customStyle}
        className="ndata-wrapper"
    >
        {!hideHeader && (
            <h1 data-testid="nd-header" style={headerStyle}>
                {header || 'Oops!'}
            </h1>
        )}
        <p style={messageStyle}>{message}</p>
    </article>
);

NoData.defaultProps = {
    hideHeader: true,
    customStyle: {},
    headerStyle: {},
    messageStyle: {},
    header: '',
};

export default NoData;
