import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import Config from 'config/config';
import { getLoginUrl } from 'services/url/url';
import { AuthToken } from '../auth-token/authToken';

/**
 * @todo change axios request abortion with the following method since the `CancelToken` method is deprecated
 * more information here => https://axios-http.com/docs/cancellation
 * test(url: string, config?) {
 *    const controller = new AbortController();
 *    const request = this._session.get(url, { ...config, signal: controller.signal});
 *    return [request, controller];
 * }
 */

declare interface AuthConfig {
    withCredentials?: boolean;
    headers?: {
        Authorization?: string;
        [key: string]: string;
    };
}

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

class Axios {
    private _session: AxiosInstance;
    private _config: AuthConfig = {};
    private _baseURL = '';

    private constructor(enforcer) {
        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): Axios & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new Axios(singletonEnforcer);
        }

        return this[singleton];
    }

    private _initSession() {
        this._session                  = axios.create({ withCredentials: true, baseURL: this._baseURL });

        this._session.interceptors.response.use(
            (response) => response,
            (error: { response: Record<string, any> }) => {
                if (axios.isCancel(error)) {
                    console.log('[interceptors.response]: request canceled');

                    return;
                }

                if (error.response.status === 401) {
                    AuthToken.removeToken();

                    location.href = getLoginUrl();
                }

                return Promise.reject(error);
            }
        );
    }

    initializeConfig(tokenHandler: AuthToken) {
        if (tokenHandler){
            const user    = tokenHandler.decodedToken;

            this._baseURL = api.apiDomain.replace('{username}', user?.site.name);
        } else {
            this._baseURL = '';
        }

        this._initSession();
    }

    getEffect<T = any>(url: string, config?: AxiosRequestConfig): [Promise<AxiosResponse<T>>, CancelTokenSource] {
        const source  = axios.CancelToken.source();
        const request = this._session.get<T>(url, {
            ...this._config,
            cancelToken: source.token,
            ...config,
        });

        return [request, source];
    }

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.get<T>(url, { ...this._config, ...config });
    }

    getWithoutSession<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios?.get<T>(url, config);
    }

    post<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.post<T>(url, data, { ...this._config, ...config });
    }

    put<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.put<T>(url, data, { ...this._config, ...config });
    }

    patch<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.patch<T>(url, data, { ...this._config, ...config });
    }

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session.delete<T>(url, { ...this._config, ...config });
    }
}

export default Axios.instance;

