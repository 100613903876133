import Button from '@components/common/buttons/button/Button';
import alertIcon from 'assets/icons/alert.svg';
import './ErrorPopupContent.sass';

declare interface ErrorPopupContentProps {
    title: string;
    message: string;
    callback?: () => void;
}

function ErrorPopupContent({ title, message, callback }: ErrorPopupContentProps) {
    return (
        <div className="epc-error">
            <img className="epc-logo" src={alertIcon}/>
            <h2 className="epc-header">{title}</h2>
            <p className="epc-message">{message}</p>
            <Button classes='btn btn-primary' callback={callback}>Go back</Button>
        </div>
    );
}

export default ErrorPopupContent;
