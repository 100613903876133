import { createAsyncThunk } from '@reduxjs/toolkit';
// import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ActionError } from '@shared/enum/actionError';

import { AppDispatch, RootState } from '@store/storeConfig';
import { handleAsyncThunkError } from './handleAsyncThunkError';

interface AsyncThunkConfig {
    state?: RootState;
    dispatch?: AppDispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
}

interface CreateAsyncThunkOptions<DataOptions, ConfigOptions, AxiosReturn> {
    requestFn: (data?: DataOptions, config?: ConfigOptions) => Promise<AxiosResponse<AxiosReturn>>;
    typePrefix: string;
    addDataCheck?: (
        data: AxiosReturn,
        // actions: Pick<GetThunkAPI<AsyncThunkConfig>, ('fulfillWithValue' | 'rejectWithValue')>
        actions: {
            rejectWithValue: <RejectedValue>(value: RejectedValue) => unknown;
            fulfillWithValue: <FulfilledValue>(value: FulfilledValue) => unknown;
        }
    ) => void;
}

export type CreateAsyncThunkReturnType<Returned, ThunkArg> = ReturnType<typeof createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>>;

export function createAsyncThunkWithClient<AxiosReturn = any, DataOptions = Record<string, any>, ConfigOptions = AxiosRequestConfig>(
    {
        requestFn,
        typePrefix,
        addDataCheck,
    }: CreateAsyncThunkOptions<DataOptions, ConfigOptions, AxiosReturn>
): CreateAsyncThunkReturnType<AxiosReturn, { data?: DataOptions; config?: ConfigOptions }> {
    return createAsyncThunk<AxiosReturn, { data?: DataOptions; config?: ConfigOptions }, AsyncThunkConfig>(
        typePrefix, async (options, thunkApi) => {
            try {
                const response = await requestFn(options?.data, options?.config);

                if (!response || !response?.data) {
                    return thunkApi.rejectWithValue(ActionError.NoResponse as string);
                }

                const { data } = response;

                if (addDataCheck && typeof addDataCheck == 'function') {
                    addDataCheck?.(data, {
                        rejectWithValue:  thunkApi.rejectWithValue,
                        fulfillWithValue: thunkApi.fulfillWithValue,
                    });
                }

                return thunkApi.fulfillWithValue(data);
            } catch (error: unknown) {
                const message = handleAsyncThunkError(error);

                return thunkApi.rejectWithValue(message);
            }
        },
    );
}
