import isEqual from 'lodash/fp/isEqual';
import { useEffect, useRef } from 'react';

export default function useDeepCompareEffect(callback: (...args: any[]) => void, dependencies: any[]) {
    const currentDependencies = useRef<typeof dependencies>();

    if (!isEqual(currentDependencies.current, dependencies)) {
        currentDependencies.current = dependencies;
    }

    useEffect(callback, [...currentDependencies.current]);
}
