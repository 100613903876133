import type { SinglePageMetaProps } from 'shared/interfaces/store/action/actionProps';
import type { AppDispatch } from 'shared/interfaces/store/store';

import axios from 'services/axios/axios';
import { doesRequestDataHasErrors } from 'services/error-handler/errorHandler';
import { queryBuilder } from 'services/url/url';
import {
    SINGLE_META_GET,
    SINGLE_META_GET_ALL,
    SINGLE_META_GET_ALL_FAILURE,
    SINGLE_META_GET_DATA_IS_LOADING,
    SINGLE_META_GET_FAILURE,
    SINGLE_META_RESET_SEARCH
} from './singleMeta.types';

export const resetSearch = () => (dispatch) => dispatch({ type: SINGLE_META_RESET_SEARCH });

export const getAllSingleMeta = ({ pageUrl, from, to, search }: SinglePageMetaProps) =>
    async (dispatch: AppDispatch) => {
        dispatch({ type: SINGLE_META_GET_DATA_IS_LOADING });

        try {
            const url                             = queryBuilder('views/meta', {
                pageUrl,
                from,
                to,
                search,
            });
            const [requestPromise, requestSource] = axios.getEffect(url);
            const { data }                        = await requestPromise;

            const error = doesRequestDataHasErrors(data);

            if (error){
                throw new Error(error);
            }

            dispatch({
                type: search ? SINGLE_META_GET_ALL : SINGLE_META_GET,
                payload: { data, requestSource },
            });
        } catch (error) {
            dispatch({
                type: search ? SINGLE_META_GET_ALL_FAILURE : SINGLE_META_GET_FAILURE,
                payload: error,
            });
        }
    };
