import { SVGAttributes } from 'react';

export const Eye = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_7980_1940)">
            <path d="M8.33594 9.99992C8.33594 10.4419 8.51153 10.8659 8.82409 11.1784C9.13665 11.491 9.56058 11.6666 10.0026 11.6666C10.4446 11.6666 10.8686 11.491 11.1811 11.1784C11.4937 10.8659 11.6693 10.4419 11.6693 9.99992C11.6693 9.55789 11.4937 9.13397 11.1811 8.82141C10.8686 8.50885 10.4446 8.33325 10.0026 8.33325C9.56058 8.33325 9.13665 8.50885 8.82409 8.82141C8.51153 9.13397 8.33594 9.55789 8.33594 9.99992Z" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z" stroke="#767CA8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_7980_1940">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
