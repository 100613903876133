import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Location } from 'shared/interfaces/store/location/location';
import type { LocationTable } from 'shared/interfaces/store/location/locationTable';

import {
    COUNTRY_DATA_IS_LOADING,
    COUNTRY_GET_ALL_FAILURE,
    COUNTRY_GET_ALL_SUCCESS,
    COUNTRY_TABLE_DATA_IS_LOADING,
    COUNTRY_TABLE_GET_ALL_FAILURE,
    COUNTRY_TABLE_GET_ALL_SUCCESS
} from './countries.types';

export declare interface CountriesState {
    countries: Location | null;
    error: string;
    isLoading: boolean;
    countriesTable: LocationTable;
    countriesTableRequestSource: CancelTokenSource;
    countriesTableError: string;
    countriesTableIsLoading: boolean;
}

const initialState: CountriesState = {
    countries: null,
    error: null,
    isLoading: false,

    countriesTable: null,
    countriesTableRequestSource: null,
    countriesTableError: null,
    countriesTableIsLoading: false,
};

const countriesReducer = function (state: CountriesState = initialState, action: ReducerAction): CountriesState {
    switch (action.type) {
        case COUNTRY_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case COUNTRY_GET_ALL_SUCCESS: {
            return {
                ...state,
                countries: action.payload,
                error: null,
                isLoading: false,
            };
        }

        case COUNTRY_GET_ALL_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
            };
        }

        case COUNTRY_TABLE_DATA_IS_LOADING: {
            return {
                ...state,
                countriesTableIsLoading: true,
            };
        }

        case COUNTRY_TABLE_GET_ALL_SUCCESS: {
            return {
                ...state,
                countriesTable: action.payload.data,
                countriesTableRequestSource: action.payload.requestSource,
                countriesTableError: null,
                countriesTableIsLoading: false,
            };
        }

        case COUNTRY_TABLE_GET_ALL_FAILURE: {
            return {
                ...state,
                countriesTableError: action.payload.message,
                countriesTableIsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default countriesReducer;
