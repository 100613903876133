import { useState } from 'react';

import PrimaryButton from '@components/common/buttons/primary-button/PrimaryButton';
import Show from '@components/common/condition/Show';
import { InputWithLabel } from '@components/common/inputs';
import { AlertError, AlertSuccess } from '@components/widgets/alert';
import { useAppDispatch } from '@hooks/redux/useRedux';
import useInputState from '@hooks/useInputState';
import useGetCurrentUser from '@hooks/user/useGetCurrentUser';
import { updateCurrentUser } from '@store/authentication/authentication.actions';
import { AuthenticatedUser } from '@store/authentication/authentication.reducer';

import './ProfileSettings.scss';
import ProfileSettingsEditAvatar from './edit-avatar/ProfileSettingsEditAvatar';
import ProfileSettingsPasswordForm from './password-form/ProfileSettingsPasswordForm';
import { profileSettingsApi } from './profileSettings.service';

const ProfileSettings = () => {
    const user                                                = useGetCurrentUser();
    const [isLoading, setLoader]                              = useState(false);
    const [avatar, setAvatar]                                 = useState<File>(null);
    const [avatarUrl, setAvatarUrl]                           = useState<string>(user?.avatar || null);
    const [avatarRemoved, setAvatarRemoved]                   = useState(false);
    const [email]                                             = useInputState(user?.email || '');
    const [firstName, onChangeFirstName]                      = useInputState(user?.firstname || '');
    const [lastName, onChangeLastName]                        = useInputState(user?.lastname || '');
    const [error, setError]                                   = useState({ email: '', firstName: '', lastName: '' });
    const [shouldShowPasswordForm, setShouldShowPasswordForm] = useState(false);
    const [changeProfileStatus, setChangeProfileStatus]       = useState<{status: 'success' | 'error'; message: string}>(null);
    const [changePasswordStatus, setChangePasswordStatus]     = useState<{status: 'success' | 'error'; message: string}>(null);
    const dispatch                                            = useAppDispatch();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (Object.values(error).find(error => !!error)) {
            return;
        }

        setChangeProfileStatus(null);

        setLoader(true);

        const payload: {firstname?: string; lastname?: string} = {};

        if (!user?.firstname || firstName !== user?.firstname) {
            payload.firstname = firstName;
        }

        if (!user?.lastname || lastName !== user?.lastname) {
            payload.lastname = lastName;
        }

        profileSettingsApi.updateProfile({
            ...payload,
            avatar,
        }, avatarRemoved).then((result) => {

            if (!result.success) {
                setChangeProfileStatus({ status: 'error', message: result.error || "Couldn't update profile, please try again!" });

                return;
            }

            setChangeProfileStatus({ status: 'success', message: 'Your Indepth profile has been successfully updated.' });

            const payload = {
                ...(user || {}),
                firstname: firstName,
                lastname: lastName,
                avatar: avatarUrl,
            } as AuthenticatedUser;

            dispatch(updateCurrentUser(payload));
        })
            .finally(() => {
                setLoader(false);
            });
    };


    const noFieldChanged = (
        firstName === user?.firstname &&
        lastName === user?.lastname &&
        avatarUrl === user?.avatar
    );
    const isDisabled     = !!Object.values(error).find(error => !!error);

    return (
        <div className='profile-settings'>
            <h2>Account details</h2>

            <form onSubmit={onSubmit}>
                <InputWithLabel
                    title='Avatar/Picture'
                    inputPlaceholder={(
                        <ProfileSettingsEditAvatar
                            firstName={firstName}
                            lastName={lastName}
                            avatar={avatar}
                            setAvatar={(file) => {
                                if (file) {
                                    setAvatarRemoved(false);
                                }

                                setAvatar(file);
                            }}
                            onUrlChange={setAvatarUrl}
                            onDeleteAvatar={() => {
                                setAvatarUrl(null);
                                setAvatarRemoved(true);
                            }}
                        />
                    )}
                />
                <InputWithLabel
                    error={error.email}
                    title='Email address'
                    type="email"
                    value={email}
                    disabled
                    // onChange={onChangeEmail}
                    // onBlur={() => {
                    //     if (!email) {
                    //         setError((prev) => ({ ...prev, email: "Field can't be empty!" }));
                    //     }
                    // }}
                    // onFocus={() => setError((prev) => ({ ...prev, email: '' }))}
                    placeholder='Enter email address'
                />
                <InputWithLabel
                    error={error.firstName}
                    title='First name'
                    value={firstName}
                    onChange={onChangeFirstName}
                    onBlur={() => {
                        if (!firstName) {
                            setError((prev) => ({ ...prev, firstName: "Field can't be empty!" }));
                        }
                    }}
                    onFocus={() => setError((prev) => ({ ...prev, firstName: '' }))}
                    placeholder='Enter first name'
                />
                <InputWithLabel
                    error={error.lastName}
                    title='Last name'
                    value={lastName}
                    onChange={onChangeLastName}
                    onBlur={() => {
                        if (!lastName) {
                            setError((prev) => ({ ...prev, lastName: "Field can't be empty!" }));
                        }
                    }}
                    onFocus={() => setError((prev) => ({ ...prev, lastName: '' }))}
                    placeholder='Enter last name'
                />
                <PrimaryButton disabled={isDisabled || noFieldChanged || (!lastName || !firstName)} isLoading={isLoading} type="submit">
                    Save changes
                </PrimaryButton>

                <Show.When isTrue={changeProfileStatus?.status === 'success'}>
                    <AlertSuccess message={changeProfileStatus?.message}/>
                </Show.When>
                <Show.When isTrue={changeProfileStatus?.status === 'error'}>
                    <AlertError message={changeProfileStatus?.message} />
                </Show.When>
            </form>
            <div className='ps-separator' />

            <h2>Password</h2>
            <Show>
                <Show.When isTrue={!shouldShowPasswordForm}>
                    <button className='ps-show-form' type='button' onClick={() => setShouldShowPasswordForm(true)}>
                        Change password
                    </button>
                    <Show.When isTrue={changePasswordStatus?.status === 'success'}>
                        <AlertSuccess message={changePasswordStatus?.message} />
                    </Show.When>
                    <Show.When isTrue={changePasswordStatus?.status === 'error'}>
                        <AlertError message={changePasswordStatus?.message}/>
                    </Show.When>
                </Show.When>
                <Show.Else>
                    <ProfileSettingsPasswordForm
                        onComplete={(status, message) => {
                            setChangePasswordStatus({ status, message });
                            setShouldShowPasswordForm(false);
                        }}
                    />
                </Show.Else>
            </Show>
        </div>
    );
};


export default ProfileSettings;
