import { Helmet } from 'react-helmet';

// any SEO related tags will be inside the <Helmet></Helmet> tag
// alongside the title.
// https://github.com/nfl/react-helmet

const BasePageTitle = 'indepth |';

const SiteHelmet = ({ title }: { title: string }) => (
    <div data-testid="site-helmet">
        <Helmet>
            <title>{BasePageTitle + ' ' + title}</title>
        </Helmet>
    </div>
);

SiteHelmet.defaultProps = {
    title: BasePageTitle+'dashboard',
};

export default SiteHelmet;
