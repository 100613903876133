import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import ErrorBoundaryFallback, { ErrorBoundaryHandler } from './ErrorBoundaryFallback';

function ErrorBoundary({ children }: { children: React.ReactNode }) {
    return (
        <>
            <ReactErrorBoundary
                FallbackComponent={ErrorBoundaryFallback}
                onError={ErrorBoundaryHandler}
                onReset={() => {
                // reset the state of your app so the error doesn't happen again
                }}
            >
                {children}
            </ReactErrorBoundary>
        </>
    );
}

export default ErrorBoundary;
