import { SVGAttributes } from 'react';

export const CheckedRounded = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_7965_1545)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8C1.5 9.72391 2.18482 11.3772 3.40381 12.5962C4.62279 13.8152 6.27609 14.5 8 14.5C9.72391 14.5 11.3772 13.8152 12.5962 12.5962C13.8152 11.3772 14.5 9.72391 14.5 8C14.5 6.27609 13.8152 4.62279 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5ZM0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8Z" fill="#56CABD"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4659 4.91407C11.5429 4.97562 11.6071 5.05174 11.6546 5.13809C11.7022 5.22444 11.7323 5.31933 11.7431 5.41731C11.754 5.5153 11.7455 5.61447 11.718 5.70914C11.6905 5.80382 11.6446 5.89215 11.5829 5.96907L7.58292 10.9691C7.51696 11.0514 7.43439 11.1189 7.34059 11.1671C7.24679 11.2153 7.14387 11.2432 7.03855 11.249C6.93323 11.2547 6.82788 11.2382 6.72938 11.2005C6.63088 11.1628 6.54145 11.1047 6.46692 11.0301L4.46692 9.03007C4.33444 8.8879 4.26231 8.69985 4.26574 8.50555C4.26917 8.31125 4.34788 8.12586 4.48529 7.98845C4.62271 7.85104 4.80809 7.77232 5.00239 7.7689C5.1967 7.76547 5.38474 7.83759 5.52692 7.97007L6.93492 9.37707L10.4109 5.03107C10.4725 4.95405 10.5486 4.88992 10.6349 4.84235C10.7213 4.79478 10.8162 4.7647 10.9142 4.75384C11.0121 4.74297 11.1113 4.75153 11.206 4.77902C11.3007 4.80652 11.389 4.85241 11.4659 4.91407Z" fill="#56CABD"/>
        </g>
        <defs>
            <clipPath id="clip0_7965_1545">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
