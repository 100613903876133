export declare interface DefaultPageSkeletonBuilderSvgElement {
    x: number;
    y: number;
    width: number;
    height: number;
}

export declare interface DefaultPageSkeletonBuilderSettings {
    containerWidth: number;
    headProps: DefaultPageSkeletonBuilderSvgElement;
    datepickerProps: DefaultPageSkeletonBuilderSvgElement;
    reportProps: DefaultPageSkeletonBuilderSvgElement;
    widget1Props: DefaultPageSkeletonBuilderSvgElement;
    widget2Props: DefaultPageSkeletonBuilderSvgElement;
    widget3Props: DefaultPageSkeletonBuilderSvgElement;
    widget4Props: DefaultPageSkeletonBuilderSvgElement;
    widget5Props: DefaultPageSkeletonBuilderSvgElement;
    widget6Props: DefaultPageSkeletonBuilderSvgElement;
    widget7Props: DefaultPageSkeletonBuilderSvgElement;
    widget8Props: DefaultPageSkeletonBuilderSvgElement;
}

export const DefaultPageSkeletonBuilderMaxContainerWidth = 1529;
export const DefaultPageSkeletonBuilderInitialSettings = {
    containerWidth: DefaultPageSkeletonBuilderMaxContainerWidth,
    headProps: {
        x: 0,
        y: 0,
        width: 260,
        height: 60
    },
    datepickerProps: {
        x: 1235,
        y: 0,
        width: 200,
        height: 60
    },
    reportProps: {
        x: 1455,
        y: 0,
        width: 200,
        height: 60
    },
    widget1Props: {
        x: 0,
        y: 80,
        width: 400,
        height: 160
    },
    widget2Props: {
        x: 420,
        y: 80,
        width: 400,
        height: 160
    },
    widget3Props: {
        x: 420 + 420,
        y: 80,
        width: 400,
        height: 160
    },
    widget4Props: {
        x: 420 + 420 + 420,
        y: 80,
        width: 400,
        height: 160
    },
    widget5Props: {
        x: 0,
        y: 260,
        width: 1240,
        height: 400
    },
    widget6Props: {
        x: 1260,
        y: 260,
        width: 400,
        height: 400
    },
    widget7Props: {
        x: 0,
        y: 260 + 400 + 20,
        width: 1240,
        height: 400
    },
    widget8Props: {
        x: 1260,
        y: 260 + 400 + 20,
        width: 400,
        height: 400
    },
} satisfies DefaultPageSkeletonBuilderSettings;
