/* eslint-disable @typescript-eslint/naming-convention */
import { SocialMediaAPIClient } from '@services/axios/clients/socialMediaAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { SocialMediaProfiles } from '@shared/interfaces/social-media';

export const fetchSocialMediaProfiles = createAsyncThunkWithClient<SocialMediaProfiles, { clientId: string }>({
    requestFn: ({ clientId }) => SocialMediaAPIClient.getProfiles(clientId),
    typePrefix: 'socialMediaSettings/getProfiles'
});

interface AddSocialMediaProfileOptions {
    profiles: {
        url: string;
        network: string;
    }[];
    client: string;
}

export const addSocialMediaProfile = createAsyncThunkWithClient<any, AddSocialMediaProfileOptions, undefined>({
    requestFn: (options) => SocialMediaAPIClient.addProfile(options),
    typePrefix: 'socialMediaSettings/addProfile'
});

interface DeleteSocialMediaProfileOptions { url: string; clientId: string }

export const deleteSocialMediaProfile = createAsyncThunkWithClient<any, DeleteSocialMediaProfileOptions, undefined>({
    requestFn: (data) => SocialMediaAPIClient.deleteProfile(data),
    typePrefix: 'socialMediaSettings/deleteProfile'
});
