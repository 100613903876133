import classNames from 'classnames';
import '../alert.scss';
import './AlertError.scss';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    message: string;
};

const AlertError = ({ className, message, ...rest }: Props) => (
    <div {...rest} className={classNames('app-alert app-alert-error', { [className]: !!className })}>
        <p>{message}</p>
    </div>
);

export default AlertError;
