import { SocialMediaAPIClient } from '@services/axios/clients/socialMediaAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { ActionError } from '@shared/enum/actionError';
import { isEmpty, isObjectLike } from 'lodash';

export interface SocialMediaOverviewEngagement {
    engagement: {
        difference: string;
        percentage: string;
        total: string;
        totalCompared: string;
        totalEngagementPerDay: {
            [date: string]: {
                comparedDate: string;
                date1: string;
                date2: string;
                difference: string;
                percentage: string;
            };
        };
    };
    reach: {
        difference: string;
        percentage: string;
        total: string;
        totalCompared: string;
        totalorganicReachPerDay: {
            days: {
                [date: string]: {
                    comparedDate: string;
                    date1: string;
                    date2: string;
                    difference: string;
                    percentage: string;
                };
            };
            total: number;
        };
        totalpaidReachPerDay: {
            days: {
                [date: string]: {
                    comparedDate: string;
                    date1: string;
                    date2: string;
                    difference: string;
                    percentage: string;
                };
            };
            total: number;
        };
    };
}

export interface SocialMediaOverviewProfile {
    networkType: string;
    profileName: string;
    engagement: {
        days: {
            [date: string]: {
                comparedDate: string;
                date1: string;
                date2: string;
                difference: string;
                percentage: string;
            };
        };
        total: number;
    };
    precedentEngagement: {
        days: {
            [date: string]: string;
        };
        total: number;
    };
    reach: {
        days: {
            [date: string]: string;
        };
        total: number;
    };
    precedentReach: {
        days: {
            [date: string]: string;
        };
        total: number;
    };
    interaction: {
        days: {
            [date: string]: string;
        };
        total: number;
    };
    precedentInteraction: {
        days: {
            [date: string]: string;
        };
        total: number;
    };
    totalFollowers: string;
    totalPrecedentFollowers: string;
    totalFollowersProgression: string;
    totalPosts: number;
    postsPerDay: number;
}
export interface SocialMediaOverviewProfiles {
    [profileId: string]: SocialMediaOverviewProfile;
}

export type SocialMediaOverviewReturn = SocialMediaOverviewEngagement & SocialMediaOverviewProfiles;

interface SocialMediaOverviewParams {
    start_date: string;
    end_date: string;
    compareFrom: string;
    compareTo: string;
    client_id: string;
}

export const fetchSocialMediaOverview = createAsyncThunkWithClient<SocialMediaOverviewReturn, SocialMediaOverviewParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getOverview<SocialMediaOverviewParams>(
        params,
        config
    ),
    typePrefix: 'socialMediaOverview/all'
});

export interface SocialMediaOverviewEngagementReturn {
    sparklines: Record<string, string>;
    total: string;
    percentage: string;
}

function instanceOfSocialMediaOverviewEngagementReturn(dataObject: any): dataObject is SocialMediaOverviewEngagementReturn {
    return (
        dataObject &&
        isObjectLike(dataObject) &&
        !Array.isArray(dataObject) &&
        'sparklines' in dataObject &&
        'total' in dataObject &&
        'percentage' in dataObject
    );
}

export const fetchSocialMediaOverviewEngagement = createAsyncThunkWithClient<SocialMediaOverviewEngagementReturn, SocialMediaOverviewParams>({
    requestFn: (params, config) => SocialMediaAPIClient.getOverviewEngagement<SocialMediaOverviewParams>(
        params,
        config
    ),
    addDataCheck: (data) => {
        if (isEmpty(data) || !instanceOfSocialMediaOverviewEngagementReturn(data)) {
            throw new Error(ActionError.NoSocialMediaProfile);
        }

        return data;
    },
    typePrefix: 'socialMediaOverview/engagement'
});
