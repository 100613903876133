import type { UseContentByCategoryTableSort } from '@features/content';
import type { ActionProps, TableActionProps } from '@shared/interfaces/store/action/actionProps';
import type { CategoriesPerDay } from '@shared/interfaces/store/categories/categoriesPerDay';
import type { CategoriesTable } from '@shared/interfaces/store/categories/categoriesTable';

import axiosService from '@services/axios/axios';
import { createAsyncThunkWithGet } from '@services/redux';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { queryBuilder } from '@services/url/url';

export const fetchTopCategories = createAsyncThunkWithGet<any, ActionProps>({
    typePrefix: 'categories/fetchTopCategories',
    endpoint: '/views/getTopCategories',
});

export const fetchCategoriesPerDay = createAsyncThunkWithClient<CategoriesPerDay, ActionProps>({
    requestFn(options, config) {
        const url = queryBuilder('/views/getCategoriesPerDay', options);

        return axiosService.get<any>(url, config);
    },
    typePrefix: 'categories/fetchCategoriesPerDay',
});

export const fetchCategoriesTable = createAsyncThunkWithClient<CategoriesTable, TableActionProps<UseContentByCategoryTableSort>>({
    requestFn(options, config) {
        const url = queryBuilder('/views/getAllContentCategories', {
            ...options,
            searchByCategory: options?.search ?? '',
        });

        return axiosService.get<any>(url, config);
    },
    typePrefix: 'categories/fetchCategoriesTable',
});
