export const BROWSERS_GET_ALL_SUCCESS = 'BROWSERS_GET_ALL_SUCCESS';
export const BROWSERS_GET_ALL_FAILURE = 'BROWSERS_GET_ALL_FAILURE';
export const BROWSERS_DATA_IS_LOADING = 'BROWSERS_DATA_IS_LOADING';

export const BROWSERS_PER_DAY_GET_ALL_SUCCESS = 'BROWSERS_PER_DAY_GET_ALL_SUCCESS';
export const BROWSERS_PER_DAY_GET_ALL_FAILURE = 'BROWSERS_PER_DAY_GET_ALL_FAILURE';
export const BROWSERS_PER_DAY_DATA_IS_LOADING = 'BROWSERS_PER_DAY_DATA_IS_LOADING';

export const BROWSERS_TABLE_GET_ALL_SUCCESS = 'BROWSERS_TABLE_GET_ALL_SUCCESS';
export const BROWSERS_TABLE_GET_ALL_FAILURE = 'BROWSERS_TABLE_GET_ALL_FAILURE';
export const BROWSERS_TABLE_DATA_IS_LOADING = 'BROWSERS_TABLE_DATA_IS_LOADING';
