export const AGE_GET_AGE_PER_GENDER = 'AGE_GET_AGE_PER_GENDER';
export const AGE_GET_AGE_PER_GENDER_FAILURE = 'AGE_GET_AGE_PER_GENDER_FAILURE';
export const AGE_DATA_IS_LOADING = 'AGE_DATA_IS_LOADING';

export const AGE_PER_DAY_GET_GENDER_SUCCESS = 'AGE_PER_DAY_GET_GENDER_SUCCESS';
export const AGE_PER_DAY_GET_GENDER_FAILURE = 'AGE_PER_DAY_GET_GENDER_FAILURE';
export const AGE_PER_DAY_DATA_IS_LOADING = 'AGE_PER_DAY_DATA_IS_LOADING';

export const GENDER_GET_GENDER = 'GENDER_GET_GENDER';
export const GENDER_GET_GENDER_FAILURE = 'GENDER_GET_GENDER_FAILURE';
export const GENDER_DATA_IS_LOADING = 'GENDER_DATA_IS_LOADING';

export const GENDER_PER_DAY_GET_GENDER_SUCCESS = 'GENDER_PER_DAY_GET_GENDER_SUCCESS';
export const GENDER_PER_DAY_GET_GENDER_FAILURE = 'GENDER_PER_DAY_GET_GENDER_FAILURE';
export const GENDER_PER_DAY_DATA_IS_LOADING = 'GENDER_PER_DAY_DATA_IS_LOADING';
