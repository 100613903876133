import { useCallback } from 'react';

import PrimaryButton from '@components/common/buttons/primary-button/PrimaryButton';
import SecondaryButton from '@components/common/buttons/secondary-button/SecondaryButton';
import ErrorMessage from '@components/widgets/error-message/ErrorMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import DateInstance from 'services/date/date';
import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import { setActiveShortcut, setRangeSelection } from 'store/date-picker/datePicker.actions';

declare interface DatePickerControlsProps {
    onSave: () => void;
    onCancel: () => void;
    error?: string;
}

function DatePickerControls({ onSave, onCancel, error }: DatePickerControlsProps) {
    const dispatch                                                 = useAppDispatch();
    const { startDate, endDate, compareStartDate, compareEndDate } = useAppSelector(state => ({
        startDate: state.datePickerReducer.current[0],
        endDate: state.datePickerReducer.current[1],
        compareStartDate: state.datePickerReducer.previous[0],
        compareEndDate: state.datePickerReducer.previous[1],
    }));

    const handleSave   = useCallback(() => onSave(), [onSave]);
    const handleCancel = useCallback(() => {
        dispatch(
            setRangeSelection({
                selection1: {
                    startDate: DateInstance.newDate(startDate),
                    endDate: DateInstance.newDate(endDate),
                    key: 'selection1',
                },
                selection2: {
                    startDate: DateInstance.newDate(compareStartDate),
                    endDate: DateInstance.newDate(compareEndDate),
                    key: 'selection2',
                },
            })
        );

        dispatch(setActiveShortcut(DatepickerShortcuts.Last7Days));

        onCancel();
    }, [onCancel, startDate, endDate, compareStartDate, compareEndDate]);

    return (
        <div className="dp-controls" data-testid="dp-controls">
            <div>
                {error ? (
                    <ErrorMessage message={error} />
                ) : null}
            </div>
            <div className="dp-buttons">
                <SecondaryButton
                    role="btn-cancel"
                    onClick={() => handleCancel()}
                >
                    Cancel
                </SecondaryButton>

                <PrimaryButton
                    role="btn-save"
                    onClick={() => handleSave()}
                >
                    Set Date
                </PrimaryButton>
            </div>
        </div>
    );
}

export default DatePickerControls;
