import { ThemeColors } from '@constants/colors';
import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const Search = ({ onClick, className }: BaseSvgComponentProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 22 22"
        fill="none"
        onClick={onClick}
        className={className}
    >
        <path
            d="m21.792 20.803-5.68-5.59c1.487-1.615 2.401-3.752 2.401-6.104C18.513 4.078 14.368 0 9.256 0S0 4.078 0 9.109c0 5.03 4.144 9.109 9.256 9.109a9.309 9.309 0 0 0 5.826-2.035l5.702 5.612a.72.72 0 0 0 1.007 0 .693.693 0 0 0 0-.992ZM9.256 16.816c-4.325 0-7.831-3.45-7.831-7.707S4.93 1.4 9.256 1.4c4.326 0 7.832 3.451 7.832 7.708 0 4.256-3.506 7.707-7.832 7.707Z"
            fill={ThemeColors.inputText}
        />
    </svg>
);
