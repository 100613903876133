import Loader, { LoaderProps } from '@components/widgets/loader/Loader';
import { CSSProperties } from 'react';
import './LoaderWrapper.sass';

type LoaderWrapperProps = LoaderProps & {
    wrapperStyle?: CSSProperties;
};

const LoaderWrapper = ({ wrapperStyle, dotsContainerStyle, nbDots, height, width }: LoaderWrapperProps) => (
    <div className='lw-wrapper' style={wrapperStyle}>
        <div className="lw-container">
            <div className="lw-clip"></div>

            <div className="lw-loader-container">
                <Loader
                    dotsContainerStyle={dotsContainerStyle}
                    nbDots={nbDots}
                    height={height}
                    width={width}
                />
            </div>
        </div>
    </div>
);

LoaderWrapper.defaultProps = {
    ...Loader.defaultProps,
    wrapperStyle: {}
};

export default LoaderWrapper;
