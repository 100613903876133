/* eslint-disable @typescript-eslint/naming-convention */
import classNames from 'classnames';

import Loader from '@components/widgets/loader/Loader';
import '../secondary-button/SecondaryButton.scss';
import './PrimaryButton.scss';

declare interface PrimaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    isLoading?: boolean;
    isInactive?: boolean;
    children: React.ReactNode;
    className?: string;
}

const PrimaryButton = ({ isLoading = false, isInactive = false, type = 'button', children, className, ...rest }: PrimaryButtonProps) => (
    <button
        {...rest}
        type={type}
        className={classNames({
            [className]: !!className,
            'app-primary-button': !isInactive,
            'app-secondary-button': isInactive,
            'btn-is-loading': isLoading
        })}
        disabled={rest?.disabled || isLoading}
    >
        {isLoading ? <Loader nbDots={3} /> : children}
    </button>
);

export default PrimaryButton;
