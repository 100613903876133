export declare interface AudienceSkeletonLayoutSvgElement {
    x: number;
    y: number;
    width: number;
    height: number;
}

export declare interface AudienceSkeletonLayoutSettings {
    containerWidth: number;
    header1Props: AudienceSkeletonLayoutSvgElement;
    header2Props: AudienceSkeletonLayoutSvgElement;
    datepickerProps: AudienceSkeletonLayoutSvgElement;
    reportProps: AudienceSkeletonLayoutSvgElement;
    widget1Props: AudienceSkeletonLayoutSvgElement;
    widget2Props: AudienceSkeletonLayoutSvgElement;
    widget3Props: AudienceSkeletonLayoutSvgElement;
}

export const AudienceSkeletonLayoutMaxContainerWidth = 1529;
export const AudienceSkeletonLayoutInitialSettings = {
    containerWidth: AudienceSkeletonLayoutMaxContainerWidth,
    header1Props: {
        x: 0,
        y: 0,
        width: 120,
        height: 40
    },
    header2Props: {
        x: 150,
        y: 0,
        width: 150,
        height: 40
    },
    datepickerProps: {
        x: 1255,
        y: 0,
        width: 200,
        height: 60
    },
    reportProps: {
        x: 1472,
        y: 0,
        width: 185,
        height: 60
    },
    widget1Props: {
        x: 0,
        y: 115,
        width: 1655,
        height: 635
    },
    widget2Props: {
        x: 0,
        y: 772,
        width: 650,
        height: 315
    },
    widget3Props: {
        x: 700,
        y: 772,
        width: 1655,
        height: 1000
    },
} satisfies AudienceSkeletonLayoutSettings;
