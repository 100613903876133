import useQuery from '@hooks/query/useQuery';
import { clearCacheExceptCookies, handleHardReload } from '@services/cache/cache';
import { ComponentProps, useEffect } from 'react';

export default function withClearCache(Component: (props: ComponentProps<any>) => JSX.Element) {
    return (props: ComponentProps<any>) => {
        const query            = useQuery();
        const shouldClearCache = query.get('r');


        useEffect(() => {
            if (shouldClearCache && shouldClearCache === 'true') {
                clearCacheExceptCookies();

                let stringToReplace = 'r=true&';

                if (!window.location.search.includes(stringToReplace)) {
                    stringToReplace = '?r=true';
                }

                const search     = window.location.search.replace(stringToReplace, '');
                const initialUrl = (window.location.origin + window.location.pathname + search + (search ? '&' : '?'));

                // forceHardReload(initialUrl);
                handleHardReload(initialUrl);
            }
        }, []);

        return <Component {...props} />;
    };
}
