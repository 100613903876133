import axios, { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useUpdateEffect from '@hooks/update-effect/useUpdateEffect';
import { cleanStore } from '@store/cleaner/cleaner.action';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { AppDispatch, RootState } from '@store/storeConfig';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch= useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useClearStoreOnURLChange = () => {
    const dispatch     = useAppDispatch();
    const { pathname } = useLocation();

    useUpdateEffect(() => {
        dispatch(cleanStore());
    }, [pathname]);
};

export function useDispatchFetch<ParamOptions = Record<string, any>, ConfigOptions = AxiosRequestConfig>(
    dependencies: unknown[],
    // fetcher: (options: { data?: ParamOptions; config?: ConfigOptions }) => CreateAsyncThunkReturnType<AxiosReturn, { data?: ParamOptions; config?: ConfigOptions }>,
    fetcher: (options: { data?: ParamOptions; config?: ConfigOptions }) => any,
    params: ParamOptions,
    config?: ConfigOptions,
) {
    const { dates, pastDates } = useAppSelector(selectFromDatepicker.dates);
    const dispatch             = useAppDispatch();

    useEffect(() => {
        const source = axios.CancelToken.source();

        dispatch(fetcher({
            data: params,
            config: {
                cancelToken: source.token,
                ...config,
            }
        }));

        return () => source.cancel();
    }, [dates, pastDates, ...dependencies]);
}
