import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import type { DatepickerRangeSelection } from 'shared/interfaces/datepicker/datepickerRangeSelection';
import type { DatePicker, DatePickerItem } from 'shared/interfaces/store/datePicker';
import type { DatePickerState } from './datePicker.reducer';

import {
    DATEPICKER_RESET_DATES,
    DATEPICKER_SET_ACTIVE_SHORTCUT, DATEPICKER_SET_COMPARE_DATE, DATEPICKER_SET_DATE, DATEPICKER_SET_DATES, DATEPICKER_SET_ERROR,
    DATEPICKER_SET_SELECTION_RANGE,
    DATEPICKER_TOGGLE_COMPARE,
    DATEPICKER_TOGGLE_DISABLE
} from './datePicker.types';

export const setDatePickerDates = (
    current: DatePickerItem,
    previous: DatePickerItem,
    updatedFrom?: DatePickerState['updatedFrom']
) => (dispatch) =>
    dispatch({
        type: DATEPICKER_SET_DATES,
        payload: { current, previous, updatedFrom: updatedFrom || 'unknown' },
    });

export const resetDatePickerDates = ({ current, previous }: DatePicker) => (dispatch) =>
    dispatch({
        type: DATEPICKER_RESET_DATES,
        payload: { current, previous },
    });

export const setCurrentDate = (date: DatePickerItem, updatedFrom?: DatePickerState['updatedFrom']) =>
    (dispatch) =>
        dispatch({
            type: DATEPICKER_SET_DATE,
            payload: { date, updatedFrom: updatedFrom || 'unknown' },
        });

export const setCompareDate = (date: DatePickerItem, updatedFrom?: DatePickerState['updatedFrom']) =>
    (dispatch) =>
        dispatch({
            type: DATEPICKER_SET_COMPARE_DATE,
            payload: { date, updatedFrom: updatedFrom || 'unknown' },
        });

export const setRangeSelection = (selection: DatepickerRangeSelection) => (dispatch) =>
    dispatch({
        type: DATEPICKER_SET_SELECTION_RANGE,
        payload: selection,
    });

export const setActiveShortcut = (shortcut: DatepickerShortcuts) => (dispatch) =>
    dispatch({
        type: DATEPICKER_SET_ACTIVE_SHORTCUT,
        payload: shortcut,
    });

export const toggleCompare = (status?: boolean) => (dispatch) =>
    dispatch({
        type: DATEPICKER_TOGGLE_COMPARE,
        payload: status
    });

export const toggleDatepickerDisable = (disabled = false) => (dispatch) =>
    dispatch({
        type: DATEPICKER_TOGGLE_DISABLE,
        payload: disabled
    });

export const setError = (error: string | null) => (dispatch) =>
    dispatch({
        type: DATEPICKER_SET_ERROR,
        payload: error,
    });
