import { ThemeColors } from 'constants/colors';
import { useEffect, useState } from 'react';

declare interface DatePickerCustomizeInputProps{
    value?: string;
    placeholder?: string;
    id?: string;
    btnType?: 'primary' | 'secondary';
    ref?: React.LegacyRef<HTMLInputElement>;
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

function DatePickerCustomizeInput ({ value, placeholder, id, btnType, ref, onBlur }: DatePickerCustomizeInputProps) {
    const color                       = btnType === 'primary' ? '#798BA5' : ThemeColors.themeHighlightColor;
    const style                       = { color: '#515151', border: '1px solid ' + color };
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => setInputValue(value), [value]);

    return (
        <input
            ref={ref}
            role="input"
            style={style}
            id={id}
            value={inputValue}
            onBlur={onBlur}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            type="text"
            className="dp-input"
        />
    );
}

export default DatePickerCustomizeInput;
