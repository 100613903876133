import { isEmpty } from 'lodash';

import { type SinglePageMeta } from '@shared/interfaces/store/singlePageMeta';

import { AppHeaderSearchResultsItem } from '../../';
import './AppHeaderSearchResults.sass';

declare interface AppHeaderSearchResultsProps {
    items: SinglePageMeta[];
    waitingString?: React.ReactNode;
}

const UnknownUrls = ['about:srcdoc'];

const AppHeaderSearchResults = ({ items, waitingString }: AppHeaderSearchResultsProps) => (
    <div className="app-header-search-results" data-testid="hs-wrapper">
        {!isEmpty(items) ? (
            <div className="app-header-search-results-items">
                {items.map((item, index) => {
                    if (!UnknownUrls.includes(item.pageUrl)) {
                        return (
                            <AppHeaderSearchResultsItem
                                key={index}
                                image={item.featuredImage}
                                text={item.pageTitle}
                                url={item.pageUrl}
                            />
                        );
                    }
                })}
            </div>
        ) : (
            <div className="app-header-search-results-alert">
                {waitingString ? waitingString : <p>No result!</p>}
            </div>
        )}
    </div>
);

export default AppHeaderSearchResults;
