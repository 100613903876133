import { createSlice } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';

import { DataStatus } from '@shared/enum/dataStatus';
import { CategoriesPerDay } from '@shared/interfaces/store/categories/categoriesPerDay';
import { CategoriesTable } from '@shared/interfaces/store/categories/categoriesTable';
import { TopCategories } from '@shared/interfaces/store/categories/topCategories';
import { RootState } from '@store/storeConfig';

import { fetchCategoriesPerDay, fetchCategoriesTable, fetchTopCategories } from './categories.thunk';
import { CategoryPerDayLineChartData, formatCategoriesDataToLineChart } from './formatter';

export interface CategoriesSliceState {
    topCategories: TopCategories;
    topCategoriesStatus: DataStatus;
    topCategoriesError: string;
    topCategoriesSource: CancelTokenSource;

    categoriesPerDay: CategoriesPerDay;
    categoriesPerDayChart: CategoryPerDayLineChartData;
    categoriesPerDayStatus: DataStatus;
    categoriesPerDayError: string;

    categoriesTable: CategoriesTable;
    categoriesTableStatus: DataStatus;
    categoriesTableError: string;
}

const initialState: CategoriesSliceState = {
    topCategories: null,
    topCategoriesStatus: DataStatus.IDLE,
    topCategoriesError: null,
    topCategoriesSource: null,

    categoriesPerDay: null,
    categoriesPerDayChart: null,
    categoriesPerDayStatus: DataStatus.IDLE,
    categoriesPerDayError: null,

    categoriesTable: null,
    categoriesTableStatus: DataStatus.IDLE,
    categoriesTableError: null,
};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // top categories
            .addCase(fetchTopCategories.pending, (state) => {
                state.topCategoriesStatus = DataStatus.LOADING;
            })
            .addCase(fetchTopCategories.fulfilled, (state, action) => {
                state.topCategories       = action.payload.data;
                state.topCategoriesSource = action.payload.source;
                state.topCategoriesStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchTopCategories.rejected, (state, action) => {
                state.topCategoriesError  = action.error.message;
                state.topCategoriesStatus = DataStatus.FAILED;
            })
            // categories per day
            .addCase(fetchCategoriesPerDay.pending, (state) => {
                state.categoriesPerDayStatus = DataStatus.LOADING;
            })
            .addCase(fetchCategoriesPerDay.fulfilled, (state, action) => {
                state.categoriesPerDay       = action.payload;
                state.categoriesPerDayChart  = formatCategoriesDataToLineChart(action.payload);
                state.categoriesPerDayStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCategoriesPerDay.rejected, (state, action) => {
                state.categoriesPerDayError  = action.error.message;
                state.categoriesPerDayStatus = DataStatus.FAILED;
            })
            // categories table
            .addCase(fetchCategoriesTable.pending, (state) => {
                state.categoriesTableStatus = DataStatus.LOADING;
            })
            .addCase(fetchCategoriesTable.fulfilled, (state, action) => {
                state.categoriesTable       = action.payload;
                state.categoriesTableStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchCategoriesTable.rejected, (state, action) => {
                state.categoriesTableError  = action.error.message;
                state.categoriesTableStatus = DataStatus.FAILED;
            });
    }
});

export const selectTopCategories = (state: RootState) => ({
    topCategories: state.categoriesReducer.topCategories,
    status: state.categoriesReducer.topCategoriesStatus,
    error: state.categoriesReducer.topCategoriesError,
    source: state.categoriesReducer.topCategoriesSource,
});

export const selectCategoriesPerDay = (state: RootState) => ({
    categoriesPerDay: state.categoriesReducer.categoriesPerDay,
    chart: state.categoriesReducer.categoriesPerDayChart,
    status: state.categoriesReducer.categoriesPerDayStatus,
    error: state.categoriesReducer.categoriesPerDayError,
});

export const selectCategoriesTable = (state: RootState) => ({
    categoriesTable: state.categoriesReducer.categoriesTable,
    status: state.categoriesReducer.categoriesTableStatus,
    error: state.categoriesReducer.categoriesTableError,
});

const categoriesReducer = categoriesSlice.reducer;

export default categoriesReducer;
