import { useEffect, useState } from 'react';

import { calcPercentageOfCurrentFromPrevious, toFixed } from 'services/math/math';
// import { widthDetector } from 'services/width-detector/widthDetector';
import {
    DefaultPageSkeletonBuilderInitialSettings,
    DefaultPageSkeletonBuilderMaxContainerWidth,
    DefaultPageSkeletonBuilderSettings,
    DefaultPageSkeletonBuilderSvgElement
} from './skeletonConfig';

declare interface Props { parentSize: { width: number; height: number } }

function DefaultPageSkeletonBuilder({ parentSize  }: Props){
    // const deviceWidth = Number(widthDetector.getCurrentDevice());
    const [settings, setSettings] = useState<DefaultPageSkeletonBuilderSettings>(DefaultPageSkeletonBuilderInitialSettings);

    useEffect(() => {
        if (!parentSize){
            return;
        }

        const containerWidth   = parentSize.width;
        const growthPercentage = toFixed(Math.abs(calcPercentageOfCurrentFromPrevious(containerWidth, DefaultPageSkeletonBuilderMaxContainerWidth)) / 100);

        const newSettings = Object.fromEntries(
            Object.entries(DefaultPageSkeletonBuilderInitialSettings).map(([name, data]) => {
                if (name === 'containerWidth'){
                    return [name, containerWidth];
                }

                const propData                = data as DefaultPageSkeletonBuilderSvgElement;
                const { x, y, width, height } = propData;

                const newX        = x - (x * growthPercentage);
                const newWidth    = width - (width * growthPercentage);
                const newPropData = {
                    x: newX <= 0 ? 0 : newX - (growthPercentage * 30),
                    y,
                    width: newWidth,
                    height
                };

                return [name, newPropData];
            })
        ) as DefaultPageSkeletonBuilderSettings;

        setSettings(newSettings);
    }, [parentSize]);

    return (
        <>
            {/* header & filters */}
            <rect id="sk-head" rx="5" r="5" {...settings?.headProps} />

            <rect id="sk-datepicker" rx="5" ry="5" {...settings?.datepickerProps} />
            <rect id="sk-report" rx="5" ry="5" {...settings?.reportProps} />

            {/* widgets */}
            <rect id="sk-widget1" {...settings?.widget1Props} rx="5" ry="5" />
            <rect id="sk-widget2" {...settings?.widget2Props} rx="5" ry="5" />
            <rect id="sk-widget3" {...settings?.widget3Props} rx="5" ry="5" />
            <rect id="sk-widget4" {...settings?.widget4Props} rx="5" ry="5" />

            <rect id="sk-widget5" {...settings?.widget5Props} rx="5" ry="5" />
            <rect id="sk-widget6" {...settings?.widget6Props} rx="5" ry="5" />

            <rect id="sk-widget7" {...settings?.widget7Props} rx="5" ry="5" />
            <rect id="sk-widget8" {...settings?.widget8Props} rx="5" ry="5" />
        </>
    );
}

export default DefaultPageSkeletonBuilder;
