export const parse = {
    int: (nb: string) => parseInt(nb),
    float: (nb: string) => parseFloat(nb),
    /**
     * If int parse integer, otherwise parse float and toFix the number to the `nbDecimal`(default=1).
     */
    intFloat: (nb, nbDecimal = 1) => {
        if (nb !== 0 && !nb) {return;}

        nb = typeof nb === 'string' ? Number(nb) : nb;

        const val    = nb.toFixed(nbDecimal);
        const isZero = val.split('.')[1] === 0;

        return isZero ? parseInt(val) : Number(val);
    },
    /**
     * Parse an array values to int
     */
    arrayToInt: (array: any[]) => array.map((x) => parseInt(x)),
    /**
     * Parse an array elements using `parse.intFloat`
     */
    arrayToIntFloat: (array: any[]) => array.map((x) => parse.intFloat(x)),
};

/**
 *
 * Converts milliseconds to seconds.
 *
 * @param {Number} ms milliseconds
 * @param {Number} digits number of digits after the decimal point.
 * @returns {Number}
 */
export const msToSeconds = (ms: number, digits = 1): number => {
    const seconds = ms / 1000;

    return parse.intFloat(seconds, digits);
};

interface SecondsToMMSS {
    seconds: number;
    afterSeconds?: string;
    afterMinutes?: string;
    stringStartsWith?: string;
    stringEndsWith?: string;
    secondSeparator?: string;
    digits?: number;
    roundDigits?: boolean;
    removeSpaces?: boolean;
}

/**
 *
 * Converts a given seconds into a MM SS(minutes seconds) format.
 *
 * @param {Object} options
 * @param {Number} options.seconds seconds to convert.
 * @param {?String} options.afterSeconds unit after seconds(default='s').
 * @param {?String} options.afterMinutes unit after minutes(default='m').
 * @param {?String} options.stringEndsWith what the returned string ends with(default='').
 * @param {?String} options.secondSeparator separator between seconds and minute(default='and').
 * @param {?String} options.digits how many digits after decimal point(default=1).
 * @param {?String} options.roundDigits whether to round digits after decimal point(default=false).
 * @param {?String} options.removeSpaces whether to remove all spaces(default=false).
 * @returns {String} formatted string.
 */
export const convertSecondsToMMSS = ({
    seconds,
    afterSeconds,
    afterMinutes,
    stringStartsWith,
    stringEndsWith,
    secondSeparator,
    digits = 1,
    roundDigits = false,
    removeSpaces = false,
}: SecondsToMMSS): string => {
    const opts = {
        afterSeconds: afterSeconds || 's',
        afterMinutes: afterMinutes || 'm',
        stringStartsWith: stringStartsWith || '',
        stringEndsWith: stringEndsWith || '',
        secondSeparator: secondSeparator != null && secondSeparator !== undefined ? secondSeparator : 'and',
        space: removeSpaces ? '' : ' '
    };

    let minutes = Math.floor(seconds / 60);

    seconds %= 60;
    minutes %= 60;

    if (roundDigits) {
        seconds = Math.round((seconds));
        minutes = Math.round((minutes));
    } else {
        seconds = Number((seconds).toFixed(digits));
        minutes = Number((minutes).toFixed(digits));
    }

    if (minutes === 0) {
        return `${opts.stringStartsWith + String(seconds) + opts.afterSeconds + opts.space + opts.stringEndsWith}`;
    }
    else if (seconds === 0) {
        return `${opts.stringStartsWith + String(minutes) + opts.afterMinutes + opts.space + opts.stringEndsWith}`;
    }

    return `${opts.stringStartsWith + String(minutes) + opts.afterMinutes+ opts.space + opts.secondSeparator + opts.space + String(seconds) + opts.afterSeconds + opts.stringEndsWith}`;
};

export const nullToZero = (x) => (x == null ? 0 : x);

export const undefinedToZero = (x) => (x === undefined ? 0 : x);

export const undefinedToFalse = (x) => (x === undefined ? false : x);

declare interface SymbolizeNumberOptions{
    convertToComma?: boolean;
    shouldRound?: boolean;
    ignoreFirstSixDigits?: boolean;
}

/**
 *
 * Converts a long number to symbol format.
 *
 * @param {Number} num num > 0
 * @param {Number} digits
 * @param {Boolean} convertToComma
 * @param {Boolean} ignoreFirstSixDigits if true and length of the `num` passed >= 4, num won't be affected
 * @return {String}
 */
export function symbolizeNumber(
    number: number,
    digits = 1,
    options: SymbolizeNumberOptions = { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true },
) {
    const { convertToComma, shouldRound, ignoreFirstSixDigits } = options;

    if (String(number).length <= 6 && ignoreFirstSixDigits) {
        return formatNumberWithCommasAfterThousands(number);
    }

    const lookup      = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx          = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item        = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return number >= item.value;
        });
    let formattedItem = '0';

    if (item) {
        if (shouldRound) {
            formattedItem = (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol;
        } else {
            formattedItem = (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol;
        }
    }

    return convertToComma ? formattedItem.replace('.', ',') : formattedItem;
}

export function formatNumberWithCommasAfterThousands(x) {
    const parts = String(x).split('.');

    parts[0]  = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}


export function capitalizeFirstLetter(str: string) {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
}
