import { IndepthClients } from 'constants/clients';

export function getShouldShowAuthorFeatures(client: string) {
    const unAllowedClients = [
        IndepthClients._7amleh,
        IndepthClients._cmcgaza,
        IndepthClients._alhudood,
        IndepthClients._nawa,
    ];

    return !unAllowedClients.includes(client);
}

export function getShouldShowLanguageFeatures(client: string) {
    const unAllowedClients = [
        IndepthClients._cmcgaza,
        IndepthClients._megaphone,
        IndepthClients._nawa,
    ];

    return !unAllowedClients.includes(client);
}

export function getShouldShowAnalyticsAndSocialMediaFeatures(client: string) {
    const allowedClients = [IndepthClients.inkyfada, IndepthClients.dev];

    return allowedClients.includes(client);
}

export function getIsClientAllowedBetaFeatures(client: string) {
    const allowedClients = [IndepthClients.inkyfada, IndepthClients.dev];

    return allowedClients.includes(client);
}
