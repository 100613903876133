
import { createSlice } from '@reduxjs/toolkit';


import { parse } from '@services/conversion/conversion';
import { DataStatus } from '@shared/enum/dataStatus';
import { SocialStatus } from '@shared/enum/socialStatus';
import { SocialMediaOverviewEngagement } from '@shared/interfaces/social-media';
import { RootState } from '@store/storeConfig';
import { isObjectLike } from 'lodash';
import { SocialMediaOverview, formatSocialMediaOverviewHighlights } from './socialMediaOverview.formatter';
import { SocialMediaOverviewProfiles, fetchSocialMediaOverview, fetchSocialMediaOverviewEngagement } from './socialMediaOverview.thunk';

export declare interface SocialMediaOverviewState {
    socialStatus: SocialStatus;
    engagement: SocialMediaOverviewEngagement;
    engagementError: string | null;
    engagementStatus: DataStatus;

    profiles: SocialMediaOverviewProfiles;
    overviewData: Omit<SocialMediaOverview, 'profiles'>;
    error: string | null;
    status: DataStatus;

}

const initialState: SocialMediaOverviewState = {
    socialStatus: SocialStatus.IDLE,
    engagement: null,
    engagementError: null,
    engagementStatus: DataStatus.IDLE,

    profiles: null,
    overviewData: null,
    error: null,
    status: DataStatus.IDLE,
};

export const socialMediaOverviewSlice = createSlice({
    name: 'socialMediaOverview',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            // all
            .addCase(fetchSocialMediaOverview.pending, (state) => {
                state.status = DataStatus.LOADING;
            })
            .addCase(fetchSocialMediaOverview.fulfilled, (state, action) => {
                const { profiles, ...result }           = formatSocialMediaOverviewHighlights(action.payload);

                state.profiles     = profiles;
                state.overviewData = result;
                state.status       = DataStatus.SUCCESS;
                state.socialStatus = SocialStatus.SIGNED;
            })
            .addCase(fetchSocialMediaOverview.rejected, (state, action) => {
                state.socialStatus = SocialStatus.UNSIGNED;
                state.error        = action.payload as unknown as string;
                state.status       = DataStatus.FAILED;
            })
            // engagement
            .addCase(fetchSocialMediaOverviewEngagement.pending, (state) => {
                state.engagementStatus = DataStatus.LOADING;
            })
            .addCase(fetchSocialMediaOverviewEngagement.fulfilled, (state, action) => {
                state.socialStatus     = SocialStatus.SIGNED;
                state.engagement       = {
                    chart: action.payload?.sparklines && isObjectLike(action.payload?.sparklines)
                        ? Object
                            .values(action.payload?.sparklines)
                            .map((n) => parse.intFloat(n, 2))
                        : [],
                    total: Number(action.payload?.total),
                    percentage: parse.intFloat(action.payload?.percentage || 0, 2),
                };
                state.engagementStatus = DataStatus.SUCCESS;
            })
            .addCase(fetchSocialMediaOverviewEngagement.rejected, (state, action) => {
                state.socialStatus     = String(action.payload).toLowerCase() !== 'cancel' ? SocialStatus.UNSIGNED : null;
                state.engagementError  = action.payload as unknown as string;
                state.engagementStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = socialMediaOverviewSlice.actions;
export const selectedFromSocialMediaOverview = {
    overviewEngagement: (state: RootState) => ({
        socialStatus: state.socialMediaOverviewReducer.socialStatus,
        data: state.socialMediaOverviewReducer.engagement ?? null,
        error: state.socialMediaOverviewReducer.engagementError,
        status: state.socialMediaOverviewReducer.engagementStatus,
    }),
    profiles: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.profiles ?? null,
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    highlights: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.highlights ?? [],
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    interactions: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.interactions ?? [],
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    posts: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.posts ?? [],
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    followers: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.followers ?? [],
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    engagement: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.engagement ?? null,
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
    reach: (state: RootState) => ({
        data: state.socialMediaOverviewReducer.overviewData?.reach ?? null,
        error: state.socialMediaOverviewReducer.error,
        status: state.socialMediaOverviewReducer.status,
    }),
};

const socialMediaOverviewReducer = socialMediaOverviewSlice.reducer;

export default socialMediaOverviewReducer;
