import { ReactRef, useGSAP } from '@gsap/react';
import { useRef } from 'react';

/**
 * useUpdateGsap runs only when the dependencies change, not on mount.
 */
export function useUpdateGsap(
    callback: (...args: any[]) => void,
    {
        dependencies,
        scope,
        revertOnUpdate
    }: {
        dependencies?: unknown[];
        scope?: ReactRef | Element | string;
        revertOnUpdate?: boolean;
    }
) {
    const firstRenderRef = useRef(true);

    useGSAP(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;

            return;
        }

        return callback();
    }, { dependencies, scope, revertOnUpdate });
}
