import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { queryBuilder } from '@services/url/url';
import { FetchAllDomainsSig, FetchAllMentionsSig, FetchMentionsSig, FetchOverviewMentionsSig } from '@store/mentions/mentions.thunk';
import { getStoreState } from '@store/storeConfig';
import Config from 'config/config';

import { BaseClientAuthConfig } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

class CInkyScrapperAPIClient {
    protected _session: AxiosInstance;
    protected _config: BaseClientAuthConfig = {};

    private constructor(enforcer) {
        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._config  ={};
            this._session = axios.create({ baseURL: api.inkyScrapper });
            // this._session.interceptors.response.use(
            //     (response) => response,
            //     (error: { response: Record<string, any> }) => {
            //         if (axios.isCancel(error)) {
            //             console.log('[interceptors.response]: request canceled');

            //             return;
            //         }

            //         return Promise.reject(error);
            //     }
            // );
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): CInkyScrapperAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CInkyScrapperAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.get<T>(url, config);
    }

    post<T = any, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this._session?.post<T>(url, data, config);
    }

    getClientWebsite() {
        const state         = getStoreState();
        const clientWebsite = state.authenticationReducer?.user?.site?.website;

        return clientWebsite;
    }

    getOverviewMentions<T>(
        { from, to, compareFrom, compareTo }: FetchOverviewMentionsSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<T>> {
        // const website = this.getClientWebsite();

        return this.get<T>(`api/get_mentions/${from}/${to}/${compareFrom}/${compareTo}`, {
            ...this._config,
            ...config,
        });
    }

    getMentions<AxiosReturn>(
        { from, to, compareFrom, compareTo }: FetchMentionsSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>(`api/get_mention2/${website}/${from}/${to}/${compareFrom}/${compareTo}`, {
            ...this._config,
            ...config,
        });
    }

    getAllMentions<AxiosReturn>(
        { from, to, limit, offset, order, sort, search, domain }: FetchAllMentionsSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();
        const url     = queryBuilder('api/get_mention_table/', {
            startDate: from,
            endDate: to,
            domainInput: website,
            limit,
            offset,
            search,
            sort,
            order,
            domainFilter: domain,
        });

        return this.get<AxiosReturn>(url, {
            ...this._config,
            ...config,
        });
    }

    //https://inkyscraper.inkylab.com/api/get_domain_table/
    //?startDate=2024-05-06&endDate=2024-05-12&domainInput=inkyfada.com&limit=30&offset=0
    getAllDomains<AxiosReturn>(
        { from, to, limit, offset, order, sort, search }: FetchAllDomainsSig,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('api/get_domain_table', {
            ...this._config,
            ...config,
            params: {
                startDate: from,
                endDate: to,
                domainInput: website,
                limit,
                offset,
                search,
                sort,
                order,
            }
        });
    }

    deleteMention<AxiosReturn>(link: string, config?: AxiosRequestConfig): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('api/add_remove_link', {
            ...this._config,
            ...config,
            params: {
                link,
                domain_to_mention: website,
            }
        });
    }

    blockMentionDomain<AxiosReturn>(domain: string, config?: AxiosRequestConfig): Promise<AxiosResponse<AxiosReturn>> {
        const website = this.getClientWebsite();

        return this.get<AxiosReturn>('api/add_blocked_domain', {
            ...this._config,
            ...config,
            params: {
                domain_to_mention: website,
                blocked_domain: domain
            }
        });
    }
}

export const InkyScrapperAPIClient = CInkyScrapperAPIClient.instance;
