export const Content = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_6322_7521)">
            <path d="M12 4L4 8L12 12L20 8L12 4Z" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 12L12 16L20 12" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 16L12 20L20 16" stroke="#E3E3E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_6322_7521">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
