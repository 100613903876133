import moment from 'moment';

import type { DatePickerItem } from 'shared/interfaces/store/datePicker';

import logger from 'middleware/logger';
import DateInstance from 'services/date/date';
import { PeriodOptions } from 'shared/enum/periodOptions';

export interface GenerateTooltipAndChartAxisPerDayDTO {
    currentAxisBase: string[];
    pastAxisBase: string[];
    currentAxis: string[];
    pastAxis: string[];
    currentTooltipAxis: string[];
    pastTooltipAxis: string[];
    tooltipHeaders: string[];
}

export const generateTooltipAndChartAxisPerDay = (selectedDates: DatePickerItem, pastSelectedDates: DatePickerItem): GenerateTooltipAndChartAxisPerDayDTO => {
    const axis               = getAxis(String(selectedDates[0]), String(selectedDates[1]));
    const pastAxis           = getAxis(String(pastSelectedDates[0]), String(pastSelectedDates[1]));
    const currentTooltipAxis = axis.map((date) => DateInstance.formatDate({
        date: DateInstance.newDate(date),
        format: 'iii dd MMM'
    }));
    const pastTooltipAxis    = pastAxis.map((date) => DateInstance.formatDate({
        date: DateInstance.newDate(date),
        format: 'iii dd MMM'
    }));

    return {
        currentAxisBase: axis,
        currentAxis: axis.map((date) => DateInstance.formatDate({
            date: DateInstance.newDate(date),
            format: 'dd MMM'
        })),
        pastAxisBase: pastAxis,
        pastAxis: pastAxis.map((date) => DateInstance.formatDate({
            date: DateInstance.newDate(date),
            format: 'dd MMM'
        })),
        currentTooltipAxis,
        pastTooltipAxis,
        tooltipHeaders: currentTooltipAxis.map((item, index) => `${item} vs ${pastTooltipAxis[index]}`)
    };
};

export const getAxis = (startDate: string, endDate: string) => {
    const start = DateInstance.newDate(startDate);
    const end   = DateInstance.newDate(endDate);
    const axis  = DateInstance.getDatesInBetween(start, end);

    return axis;
};

/**
 * @todo refactor the below code & remove moment
 */
export const generateChartXAxis = (dateArray: (string | number)[], period: PeriodOptions, year?: number) => {
    switch (period){
        case PeriodOptions.Day:
            return dateArray;
        case PeriodOptions.Week:
            return dateArray.map((yearAndWeek) => {
                const str      = String(yearAndWeek);
                let weekNumber = yearAndWeek;

                if (str.length > 4) {
                    weekNumber = str.slice(4, str.length);
                    year       = Number(str.slice(0, 4));
                }

                return (
                    moment().day('Monday')
                        .week(Number(weekNumber))
                        .year(year)
                        .format('DD MMM')
                    + ' to ' +
                    moment().day('Saturday')
                        .week(Number(weekNumber))
                        .year(year)
                        .format('DD MMM')
                );
            });
        case PeriodOptions.Month:
            return dateArray.map((yearAndMonth) => {
                const str       = String(yearAndMonth);
                let monthNumber = Number(yearAndMonth);

                if (str.length > 4) {
                    monthNumber = Number(str.slice(4, str.length));
                    year        = Number(str.slice(0, 4));
                }

                if (monthNumber > 12) {
                    return null;
                }

                return moment()
                    .month(monthNumber - 1)
                    .year(year)
                    .format('MMMM');
            }).filter(Boolean);
        default:
            logger.error('period must be of type `PeriodOptions`');
    }
};

export const getCompareXAxis = (currAxis: (number | string)[], prevAxis: (number | string)[]) => {
    if (!prevAxis?.[0] && currAxis?.[0]) {
        for (let i = 0; i < currAxis.length; i++) {
            prevAxis[i] = DateInstance.subtractDate({
                date: DateInstance.newDate(currAxis[i]),
                subtractCount: 7,
                subtractUnit: 'days',
                newFormat: 'yyyy-MM-dd',
            }) as string;
        }
    }

    return prevAxis;
};
