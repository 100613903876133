import { gsap } from 'gsap';
import { useRef } from 'react';
import { connect } from 'react-redux';

import type { RootState } from 'shared/interfaces/store/store';

import Loader from '@components/widgets/loader/Loader';
import { useGSAP } from '@gsap/react';
import { SiteLoaderState } from 'store/site-loader/siteLoader.reducer';
import './SiteLoader.sass';

declare interface SiteLoaderProps {
    loader: SiteLoaderState;
    label?: string;
    position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
}

const SiteLoader = ({ loader, label, position }: SiteLoaderProps) => {
    const loaderRef = useRef<HTMLDivElement>();

    useGSAP(() => {
        const el = loaderRef.current;

        loader.show ? animateLoaderIn(el) : animateLoaderOut(el);
    }, { dependencies: [loader.show], scope: loaderRef });

    return (
        <div className="sloader-wrapper" ref={loaderRef} style={{ position }}>
            <div className="sloader-container">
                <Loader nbDots={3} width={8} height={8} />
            </div>

            {(loader.label || label) && <p dangerouslySetInnerHTML={{ __html: loader.label || label }} />}
        </div>
    );
};

function animateLoaderIn(el: HTMLDivElement) {
    const tl = gsap.timeline();

    tl.to(el, { duration: 0.5, opacity: 1 }).fromTo(
        el,
        { right: '-100px' },
        { right: '30px' },
        '-=.5',
    );
}

function animateLoaderOut(el: HTMLDivElement) {
    const tl = gsap.timeline();

    tl.to(el, { duration: 0.5, opacity: 0 }).fromTo(
        el,
        { right: '30px' },
        { right: '-100px' },
        '-=.5',
    );
}

SiteLoader.defaultProps = {
    label: '',
    position: 'fixed',
};

const mapStateToProps = (state: RootState) => ({
    loader: state.siteLoaderReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SiteLoader);
