import React, { useCallback, useMemo } from 'react';

declare interface DatePickerCustomizeShortcutProps {
    callback?: () => void;
    isActive?: boolean;
    children?: React.ReactNode;
    isDisabled?: boolean;
    id?: string;
}

function DatePickerCustomizeShortcut({ callback, isActive, children, isDisabled, id }: DatePickerCustomizeShortcutProps) {
    const onClick        = useCallback(() => {
        !isDisabled && callback();
    },[callback, isDisabled]);

    const classNames = useMemo(() => {
        const active   = (isActive && !isDisabled ? ' active' : '');
        const disabled = (isDisabled ? ' disabled' : '');

        return `dp-shortcut${active}${disabled}`;
    }, [isActive, isDisabled]);

    return (
        <div
            role="button"
            onClick={onClick}
            className={classNames}
            id={id}
        >
            {children || 'untitled'}
        </div>
    );
}

export default DatePickerCustomizeShortcut;
