import { SVGAttributes } from 'react';

export const Copy = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none">
        <g clipPath="url(#clip0_8025_21862)">
            <path d="M6.125 8.45862C6.125 7.83971 6.37086 7.24614 6.8085 6.8085C7.24614 6.37086 7.83971 6.125 8.45862 6.125H16.0414C16.3478 6.125 16.6513 6.18536 16.9344 6.30264C17.2175 6.41991 17.4748 6.59181 17.6915 6.8085C17.9082 7.0252 18.0801 7.28246 18.1974 7.56559C18.3146 7.84871 18.375 8.15217 18.375 8.45862V16.0414C18.375 16.3478 18.3146 16.6513 18.1974 16.9344C18.0801 17.2175 17.9082 17.4748 17.6915 17.6915C17.4748 17.9082 17.2175 18.0801 16.9344 18.1974C16.6513 18.3146 16.3478 18.375 16.0414 18.375H8.45862C8.15217 18.375 7.84871 18.3146 7.56559 18.1974C7.28246 18.0801 7.0252 17.9082 6.8085 17.6915C6.59181 17.4748 6.41991 17.2175 6.30264 16.9344C6.18536 16.6513 6.125 16.3478 6.125 16.0414V8.45862Z" stroke="#5079E4" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5105 14.6449C3.24217 14.4919 3.01899 14.2708 2.8635 14.0039C2.70802 13.7371 2.62574 13.4339 2.625 13.125V4.375C2.625 3.4125 3.4125 2.625 4.375 2.625H13.125C13.7812 2.625 14.1382 2.96187 14.4375 3.5" stroke="#5079E4" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_8025_21862">
                <rect width="21" height="21" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
