import { CSSProperties } from 'react';

import Img from '@components/common/img/Img';
import alert from 'assets/icons/alert.svg';
import classNames from 'classnames';
import './ErrorMessage.sass';

declare interface ErrorMessageProps {
    errorHeader?: string;
    message: string;
    wrapperStyle?: CSSProperties;
    messageStyle?: CSSProperties;
    wrapperClassName?: string;
}

const ErrorMessage = ({ errorHeader, message, wrapperStyle, wrapperClassName, messageStyle }: ErrorMessageProps) =>
    message ? (
        <article
            style={wrapperStyle}
            data-testid="error-message"
            className={classNames('error-msg-container', {
                [wrapperClassName]: !!wrapperClassName
            })}
        >
            <Img src={alert} alt="alert icon" className="error-msg-icon" />
            <article className="error-msg-content">
                {errorHeader && <h3>{errorHeader}</h3>}
                <p style={messageStyle}>{message}</p>
            </article>
        </article>
    ) : null;

ErrorMessage.defaultProps = {
    errorHeader: null,
    wrapperClassName: ''
};

export default ErrorMessage;
