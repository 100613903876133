/* eslint-disable @typescript-eslint/naming-convention */
import classNames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
// import { withRouter } from 'react-router-dom';

import type { SinglePageMeta } from 'shared/interfaces/store/singlePageMeta';
import type { RootState } from 'shared/interfaces/store/store';

import Config from 'config/config';
import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { DefinedRoutes } from 'routes/definedRoutes';

import { useAppDispatch, useAppSelector } from '@hooks/redux/useRedux';
import { getAllSingleMeta, resetSearch } from '@store/single-meta/singleMeta.actions';
import { useNavigate } from 'react-router';
import './AppHeaderSearch.sass';
import AppHeaderSearchInput from './input/AppHeaderSearchInput';
import AppHeaderSearchResults from './results/AppHeaderSearchResults';

declare interface AppHeaderSearchProps {
    className?: string;
    onInputBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const { search }                  = Config;
const SiteSearchPreventedKeywords = search.preventedKeywords.split(',');

const AppHeaderSearch = ({ className, onInputBlur }: AppHeaderSearchProps) => {
    const [search, setSearch]                           = useState(false);
    const [searchWaitingString, setSearchWaitingString] = useState<React.ReactNode>(<p>Searching...</p>);
    const [result, setResult]                           = useState<SinglePageMeta[] | null>(null);
    const { searchResult, requestSource }               = useAppSelector((state: RootState) => ({
        searchResult: state.singleMetaReducer.search,
        requestSource: state.singleMetaReducer.requestSource,
    }));
    const dispatch                                      = useAppDispatch();
    const navigate                                      = useNavigate();

    useUpdateEffect(() => {
        if (isEmpty(searchResult)) {
            setResult(null);
            setSearchWaitingString(<p>No result!</p>);
        } else {
            setResult(searchResult);
        }

        return () => {
            requestSource && requestSource.cancel();
        };
    }, [searchResult]);

    const [url, setUrl]                           = useState('');
    const [inputFocusStatus, setInputFocusStatus] = useState(false);

    const debounceResult: any = useMemo(
        () =>
            debounce((event) => {
                const { value: targetUrl } = event.target;

                setUrl(targetUrl);

                if (!targetUrl) {
                    dispatch(resetSearch());

                    return;
                }

                if (targetUrl.length < 3) {
                    setSearchWaitingString(<p>Type at least 3 characters...</p>);

                    return;
                }

                const l = targetUrl.toLowerCase();

                // prettier-ignore
                if (
                    !l.startsWith('https://') &&
                    !l.startsWith('http://') &&
                    SiteSearchPreventedKeywords.filter((word) => l.startsWith(word)).filter(Boolean)[0]
                ) {
                    // prettier-ignore
                    setSearchWaitingString(<p> Search cannot start with {' ' + SiteSearchPreventedKeywords.map(item => `"${item}"`).join(' or ')}</p>);
                    setResult(null);

                    return;
                }

                dispatch(getAllSingleMeta({ pageUrl: targetUrl, search: true }));
                setSearch(true);
            }, 300),
        [url],
    );

    const handleKeyUp = (e) => {
        const { value: targetUrl } = e.target;

        // prettier-ignore
        if (!targetUrl.startsWith('https://') && !targetUrl.startsWith('http://')) {
            return;
        }

        if (e.keyCode === 13) {
            navigate(`${DefinedRoutes.pages.page.path}?pageUrl=${targetUrl}`);
            setSearch(false);
        }
    };

    return (
        <div className={classNames('app-header-search', { '--active': url && inputFocusStatus, [className]: !!className })}>
            <AppHeaderSearchInput
                searchOn={search}
                handleChange={debounceResult}
                handleKeyUp={handleKeyUp}
                handleFocus={(e) => {
                    setInputFocusStatus(true);
                    debounceResult(e);
                }}
                handleBlur={(e) => {
                    setTimeout(() => setInputFocusStatus(false), 200);

                    onInputBlur?.(e);
                }}
                setSearch={setSearch}
            />

            {url && inputFocusStatus && (
                <div
                    data-testid="header-search-result"
                    className={classNames('app-header-search-result-wrapper', {
                        'focus-lost': !inputFocusStatus
                    })}
                >
                    <AppHeaderSearchResults
                        items={result}
                        waitingString={searchWaitingString} />
                </div>
            )}
        </div>
    );
};

export default AppHeaderSearch;
