import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { AllContent } from 'shared/interfaces/store/allContent';

import {
    ALL_CONTENT_DATA_IS_LOADING,
    ALL_CONTENT_GET_ALL_FAILURE,
    ALL_CONTENT_GET_ALL_SUCCESS
} from './allContent.types';

export declare interface AllContentState {
    content: AllContent;
    requestSource: CancelTokenSource;
    error: string;
    isLoading: boolean;
}

const initialState: AllContentState = {
    content: null,
    requestSource: null,
    error: null,
    isLoading: false,
};

const allContentReducer = function (state: AllContentState = initialState, action: ReducerAction): AllContentState {
    switch (action.type) {
        case ALL_CONTENT_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case ALL_CONTENT_GET_ALL_SUCCESS: {
            return {
                content: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case ALL_CONTENT_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default allContentReducer;
