import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import type { APIError } from 'shared/interfaces/apiError';

import { doesRequestDataHasErrors, handleCatchBlock } from 'services/error-handler/errorHandler';
import { queryBuilder } from 'services/url/url';
import { ActionError } from 'shared/enum/actionError';
import { isInstanceOfStoreDataStatus } from 'shared/enum/storeDataStatus';
import axiosService from './axios';

export const generateNewCancelTokenSource = () => axios.CancelToken.source();
export const isRequestCanceled            = (error) => axios.isCancel(error);

export function handleRequestErrorChecking(response: AxiosResponse<any>) {
    const error      = doesRequestDataHasErrors(response.data);
    const dataStatus = isInstanceOfStoreDataStatus(error);

    if (!dataStatus && error){
        throw new Error(error);
    }

    return error;
}

interface GetWithControllerAndCatchReturnType<R> {
    data: AxiosResponse<R>['data'] | undefined;
    source?: CancelTokenSource;
    success: boolean;
    error: APIError | string | undefined;
}

function handleRequestCatchBlock<R = any>(error: unknown): Omit<GetWithControllerAndCatchReturnType<R>, 'source'> {
    const result = handleCatchBlock(error, true);

    if (typeof result !== 'string') {
        const data = result?.data;

        return {
            data: null,
            error: {
                ...data,
                message: result?.error?.response?.status === 404 ? ActionError.AuthorNotFound : ''
            } satisfies APIError,
            success: false
        };
    }

    return { data: null, error: result as string, success: false };
}

export async function getWithControllerAndCatch<R = any, O = Record<string, any>>(
    endpoint: string,
    options?: O,
): Promise<GetWithControllerAndCatchReturnType<R>> {
    const source   = generateNewCancelTokenSource();

    try {
        const url      = queryBuilder(endpoint, options);
        const response = await axiosService.get<R>(url, {
            cancelToken: source.token,
        });
        const error    = handleRequestErrorChecking(response);

        return { data: response.data, source, error, success: true };
    } catch (error: unknown) {
        return handleRequestCatchBlock(error);
    }
}

interface CreateReduxAPIActionParams<R, O> {
    endpoint: string;
    options?: O;
    dispatchedTypes: {
        loader?: string;
        success: string;
        failure: string;
    };
    dataFormatter?: <T = any>(data: R) => T;
}

export function createReduxAPIAction<O = Record<string, any>, R = any>({
    endpoint,
    options,
    dispatchedTypes,
    dataFormatter
}: CreateReduxAPIActionParams<R, O>) {
    return async (dispatch) => {
        if (dispatchedTypes.loader) {
            dispatch({
                type: dispatchedTypes.loader
            });
        }

        const response = await getWithControllerAndCatch<R, O>(endpoint, options);

        if (response.success) {
            let data = response?.data;

            if (dataFormatter && typeof dataFormatter === 'function') {
                data = dataFormatter(data);
            }

            dispatch({
                type: dispatchedTypes.success,
                payload: {
                    data,
                    requestSource: response.source
                },
            });
        } else {
            dispatch({
                type: dispatchedTypes.failure,
                payload: response.error,
            });
        }
    };
}
