import { SET_MOBILE_SIDENAV_STATUS, SET_SIDENAV_STATUS } from './sideBar.type';

export const toggleSideNavStatus = (status, hasSideNavStatusBeenReset = false) => (dispatch) => {
    dispatch({
        type: SET_SIDENAV_STATUS,
        payload: {
            status: status != null && status !== undefined ? status : 0,
            hasSideNavStatusBeenReset,
        }
    });
};

export const toggleMobileSideNavStatus = (status) => (dispatch) => {
    dispatch({
        type: SET_MOBILE_SIDENAV_STATUS,
        payload: status != null && status !== undefined ? status : 0
    });
};
