export const VIEWS_GET_ALL = 'VIEWS_GET_ALL';
export const VIEWS_GET_ALL_FAILURE = 'VIEWS_GET_ALL_FAILURE';
export const VIEWS_DATA_IS_LOADING = 'VIEWS_DATA_IS_LOADING';

export const AUDIENCE_GET_ALL = 'AUDIENCE_GET_ALL';
export const AUDIENCE_GET_ALL_FAILURE = 'AUDIENCE_GET_ALL_FAILURE';
export const AUDIENCE_DATA_IS_LOADING = 'AUDIENCE_DATA_IS_LOADING';

export const AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE = 'AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE';
export const AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE_FAILURE = 'AUDIENCE_GET_ALL_BY_TRAFFIC_SOURCE_FAILURE';

export const TRAFFIC_SOURCE_GET_ALL = 'TRAFFIC_SOURCE_GET_ALL';
export const TRAFFIC_SOURCE_GET_ALL_FAILURE = 'TRAFFIC_SOURCE_GET_ALL_FAILURE';
export const TRAFFIC_SOURCE_DATA_IS_LOADING = 'TRAFFIC_SOURCE_DATA_IS_LOADING';
