import { useEffect } from 'react';

import MainLayout from 'layouts/main/MainLayout';

import withClearCache from './withClearCache';

const App = () => {
    // THIS IS THE OPTION THAT YOU CAN OPT, BUT DIFFERENT BROWSERS WILL
    // HAVE THEIR DIFFERENT MESSAGES FOR PROMPTING BEFORE RELOAD. ELSE
    // IF YOU ARE USING BROWSER-ROUTER IN YOUR CODE. GIVE A SHOT FOR
    // THIS. https://v5.reactrouter.com/web/api/BrowserRouter (forceRefresh prop)

    useEffect(() => {
        window.addEventListener('beforeunload', () => true);

        return () => window.removeEventListener('beforeunload',  () => false);
    }, []);

    return (
        <MainLayout />
    );
};

export default withClearCache(App);
