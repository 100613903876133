import { ECharts, EChartsOption, EChartsType, SetOptionOpts, getInstanceByDom, init } from 'echarts';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { CSSProperties, useEffect, useRef } from 'react';

import { ThemeColors } from '@constants/colors';
import useSidebar from '@hooks/sidebar/useSidebar';
import { sleep } from '@services/cache/cache';

type RendererType = 'canvas' | 'svg';

export interface ChartBaseRenderOptions {
    locale?: string;
    renderer?: RendererType;
    devicePixelRatio?: number;
    useDirtyRect?: boolean;
    useCoarsePointer?: boolean;
    pointerSize?: number;
    ssr?: boolean;
    width?: number;
    height?: number;
}

export interface ChartBaseProps {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    loading?: boolean;
    theme?: 'light' | 'dark';
    renderOptions?: ChartBaseRenderOptions;
    className?: string;
    id?: string;
    reRenderer?: boolean; // if passed, the chart will rerender whenever this variable changes
    getInstance?: (instance: EChartsType) => void;
}

function ChartBase({
    option,
    style,
    settings,
    loading,
    theme,
    renderOptions,
    className,
    id,
    reRenderer,
    getInstance
}: ChartBaseProps): JSX.Element {
    const chartRef = useRef<HTMLDivElement>(null);
    const sidebar = useSidebar();

    useEffect(() => {
        // Initialize chart
        /** @type ECharts */
        let chart: ECharts | undefined;

        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme, {
                // useDirtyRect: false,
                ...renderOptions
            });
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        const resizeChart = debounce(() => {
            if (chart) {
                chart?.resize();
            }
        }, 200);

        window.addEventListener('resize', resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, [theme]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            /** @type ECharts */
            const chart = getInstanceByDom(chartRef.current);

            getInstance?.(chart);

            const currentOptions = chart.getOption();

            // only update when the new options are not identical to the current options
            if (!isEqual(currentOptions, option)){
                chart.clear();
                chart.setOption(option, settings);
            }
        }
    }, [option, settings, theme, reRenderer]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            /** @type ECharts */
            const chart = getInstanceByDom(chartRef.current);

            if (loading === true) {
                chart.showLoading(LoadingEffect);
            } else {
                chart.hideLoading();
            }
        }
    }, [loading, theme]);


    // update chart on sidebar change to rerender chart and take the full width
    useEffect(() => {
        sleep(450).then(() => {
            // updateChart()
            /** @type ECharts */
            const chart = getInstanceByDom(chartRef.current);

            chart?.resize();
        })
    }, [sidebar.state?.sideNavStatus]); 


    return (
        <div
            className={'chart-base ' + className}
            id={id}
            ref={chartRef}
            style={{
                display: 'grid',
                placeItems: 'center',
                ...style
            }}
        ></div>
    );
}

const LoadingEffect = {
    text: 'loading...',
    color: ThemeColors.themeHighlightColor,
    textColor: ThemeColors.black,
    maskColor: 'rgba(255, 255, 255, 0.8)',
    zlevel: 0,

    // Font size. Available since `v4.8.0`.
    fontSize: '.8rem',
    // Font family. Available since `v5.0.1`.
    fontFamily: 'Poppins, Cairo, Roboto, sans-serif',
    // Show an animated "spinner" or not. Available since `v4.8.0`.
    showSpinner: true,
    // Radius of the "spinner". Available since `v4.8.0`.
    spinnerRadius: 15,
    // Line width of the "spinner". Available since `v4.8.0`.
    lineWidth: 5,
    // Font thick weight. Available since `v5.0.1`.
    fontWeight: 500,
    // Font style. Available since `v5.0.1`.
    fontStyle: 'normal',
};

ChartBase.propTypes = {
    option: PropTypes.object.isRequired,
    style: PropTypes.object,
    settings: PropTypes.object,
    loading: PropTypes.bool,
    theme: PropTypes.oneOf(['light', 'dark']),
    renderOptions: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
};

ChartBase.defaultProps = {
    style: {},
    settings: {},
    loading: false,
    theme: 'light',
    renderOptions: { renderer: 'canvas' },
    className: '',
    id: '',
};

export default ChartBase;
