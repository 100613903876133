export enum Pages {
    Overview = 'overview',
    Realtime = 'realtime',
    Audience = 'audience',
    Content = 'content',
    SingleContent = 'single page',
    SingleAuthor = 'single author',
    SingleCountry = 'single country',
    SingleCategory = 'single category',
    SocialMediaOverview = 'social media overview',
    SocialMediaDetails = 'social media details',
    Mentions = 'mentions',
    Settings = 'settings',
}
