import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';

import { ACQUISITION_TABLE_DATA_IS_LOADING, ACQUISITION_TABLE_GET_ALL_FAILURE, ACQUISITION_TABLE_GET_ALL_SUCCESS } from './acquisition.types';

export declare type AcquisitionFormatted  = {
    title: string;
    visits: number;
    visitors: number;
    sessions: number;
    readingTime: number;
    bounceRate: number;
    children: {
        title: string;
        aggReferrer: string;
        bounceRate: number;
        readingTime: string;
        referrer: string;
        sessions: string;
        visitors: string;
        visits: string;
    }[];
}[];

export declare interface AcquisitionState {
    acquisition: AcquisitionFormatted;
    requestSource: CancelTokenSource | null;
    error: string | null;
    isLoading: boolean;
}

const initialState: AcquisitionState = {
    acquisition: null,
    requestSource: null,
    error: null,
    isLoading: false,
};

const acquisitionReducer = function (state: AcquisitionState = initialState, action: ReducerAction): AcquisitionState {
    switch (action.type) {
        case ACQUISITION_TABLE_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case ACQUISITION_TABLE_GET_ALL_SUCCESS: {
            return {
                ...state,
                acquisition: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case ACQUISITION_TABLE_GET_ALL_FAILURE: {
            return {
                ...initialState,
                error: action.payload.message,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default acquisitionReducer;

