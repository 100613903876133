import { CLEANER_CLEAN_REDUCERS, CLEANER_CLEAN_STORE } from './cleaner.types';

export const cleanStore = () => (dispatch) => {
    dispatch({
        type: CLEANER_CLEAN_STORE
    });
};

export enum ClearableReducers {
    VIEWS = 'viewsReducer',
    IMPACT_SCORE = 'impactScoreReducer',
    CONVERSION = 'conversionsReducer',
    DEPTH_RATE = 'depthRateReducer',
    ACQUISITION = 'acquisitionReducer',
    COUNTRIES = 'countriesReducer',
    DEVICES = 'devicesReducer',
    BROWSERS = 'browsersReducer',
    DATEPICKER = 'datePickerReducer',
    MENTIONS = 'overviewMentionsReducer',
}

export const cleanStoreReducers = (reducers: ClearableReducers[]) => (dispatch) => {
    dispatch({
        type: CLEANER_CLEAN_REDUCERS,
        payload: {
            reducers
        }
    });
};
