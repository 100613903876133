export const AUTHOR_GET_TOP_AUTHOR_SUCCESS = 'AUTHOR_GET_TOP_AUTHOR_SUCCESS';
export const AUTHOR_GET_TOP_AUTHOR_FAILURE = 'AUTHOR_GET_TOP_AUTHOR_FAILURE';
export const AUTHOR_GET_TOP_AUTHOR_IS_LOADING = 'AUTHOR_GET_TOP_AUTHOR_IS_LOADING';

export const AUTHOR_GET_HIGHLIGHTS_SUCCESS = 'AUTHOR_GET_HIGHLIGHTS_SUCCESS';
export const AUTHOR_GET_HIGHLIGHTS_FAILURE = 'AUTHOR_GET_HIGHLIGHTS_FAILURE';
export const AUTHOR_GET_HIGHLIGHTS_IS_LOADING = 'AUTHOR_GET_HIGHLIGHTS_IS_LOADING';

export const AUTHOR_GET_ARTICLES_SUCCESS = 'AUTHOR_GET_ARTICLES_SUCCESS';
export const AUTHOR_GET_ARTICLES_FAILURE = 'AUTHOR_GET_ARTICLES_FAILURE';
export const AUTHOR_GET_ARTICLES_IS_LOADING = 'AUTHOR_GET_ARTICLES_IS_LOADING';

export const AUTHOR_GET_ALL_AUTHORS_SUCCESS = 'AUTHOR_GET_ALL_AUTHORS_SUCCESS';
export const AUTHOR_GET_ALL_AUTHORS_FAILURE = 'AUTHOR_GET_ALL_AUTHORS_FAILURE';
export const AUTHOR_GET_ALL_AUTHORS_IS_LOADING = 'AUTHOR_GET_ALL_AUTHORS_IS_LOADING';
