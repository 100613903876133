/* eslint-disable @typescript-eslint/naming-convention */
import classNames from 'classnames';

import Loader from '@components/widgets/loader/Loader';

import './SecondaryButton.scss';

declare interface SecondaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    children: React.ReactNode;
    isLoading?: boolean;
    className?: string;
}

const SecondaryButton = ({ isLoading = false, type = 'button', children, className, ...rest }: SecondaryButtonProps) => (
    <button
        {...rest}
        type={type}
        className={classNames('app-secondary-button', {
            [className]: !!className,
            'btn-is-loading': isLoading
        })}
        disabled={rest?.disabled || isLoading}
    >
        {isLoading ? <Loader nbDots={3} /> : children}
    </button>
);

export default SecondaryButton;
