import ToastMessage, { ToastMessageProps } from '@components/widgets/toast-message/ToastMessage';
import { addDays } from 'date-fns';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import logger from 'middleware/logger';
import { getSelectedSiteStartDay } from 'services/client-config';
import DateInstance from 'services/date/date';
import { DatepickerShortcuts } from 'shared/enum/datepickerShortcuts';
import { setActiveShortcut, setError, setRangeSelection } from 'store/date-picker/datePicker.actions';
import DatePickerCustomizeShortcut from './DatePickerCustomizeShortcut';

const shortcuts      = [
    { title: DatepickerShortcuts.Last7Days },
    { title: DatepickerShortcuts.Last14Days },
    { title: DatepickerShortcuts.Last30Days },
    { title: DatepickerShortcuts.Last3Months },
    { title: DatepickerShortcuts.Last12Months },
];
const clientStartDay = getSelectedSiteStartDay();

function DatePickerCustomizeShortcuts() {
    const [toastConfig, setToastConfig]                 = useState<ToastMessageProps>({
        trigger: false,
        toastType: 'error',
        toastText: '',
    });

    const activeShortcut = useAppSelector(state => state.datePickerReducer.activeShortcut);
    const dispatch       = useAppDispatch();

    useEffect(() => {
        // automate selection last 3 months
        // if (Config.isDevelopment && activeShortcut !== 'Last 3 Months'){
        //     sleep(1000).then(() => {
        //         (document.getElementById('last-3-months') as HTMLDivElement).click();

        //         sleep(500).then(() => {
        //             if(!isMobileSm){
        //                 (document.querySelector('.dp-wrapper .dp-controls .btn-primary') as HTMLButtonElement).click();
        //             }else {
        //                 (document.querySelector('.dp-wrapper .dp-mobile-controls .dp-mobile-save') as HTMLButtonElement).click();
        //             }
        //         });
        //     });
        // }
    }, []);

    return (
        <>
            {
                shortcuts.map(shortcut => {
                    const selection                   = getMappedDate(shortcut.title, setToastConfig);

                    if (!selection){
                        dispatch(setError("couldn't apply selected shortcut!"));

                        setToastConfig((prev) => ({
                            ...prev,
                            trigger: true,
                            toastText: "Couldn't apply selected shortcut"
                        }));

                        return;
                    }

                    const isStartDayInRangeOfShortcut = DateInstance.isDateInRange(DateInstance.newDate(clientStartDay), [
                        selection.selection1.startDate,
                        selection.selection1.endDate
                    ]);

                    return (
                        <DatePickerCustomizeShortcut
                            key={shortcut.title}
                            id={
                                shortcut.title
                                    .split(' ')
                                    .join('-')
                                    .toLocaleLowerCase()
                            }
                            isActive={shortcut.title === activeShortcut}
                            isDisabled={isStartDayInRangeOfShortcut}
                            callback={() => {
                                dispatch(setActiveShortcut(shortcut.title));
                                dispatch(setRangeSelection(selection));
                            }}
                        >
                            {shortcut.title}
                        </DatePickerCustomizeShortcut>
                    );
                })
            }

            <ToastMessage {...toastConfig} />
        </>
    );
}

const getDatesByDay = (difference) => {
    const endDate          = addDays(DateInstance.newDate(), -1);
    const startDate        = addDays(endDate, 1-difference);
    const compareStartDate = addDays(startDate, -difference);
    const compareEndDate   = addDays(endDate, -difference);

    return [startDate, endDate, compareStartDate, compareEndDate];
};

function getMappedDate (shortcut: DatepickerShortcuts, setToastConfig: React.Dispatch<React.SetStateAction<ToastMessageProps>>) {
    let startDate;
    let endDate;
    let compareStartDate;
    let compareEndDate;

    switch (shortcut){
        case DatepickerShortcuts.Last7Days:
            [startDate, endDate, compareStartDate, compareEndDate] = getDatesByDay(7);

            break;
        case DatepickerShortcuts.Last14Days:
            [startDate, endDate, compareStartDate, compareEndDate] = getDatesByDay(13);

            break;
        case DatepickerShortcuts.Last30Days:
            [startDate, endDate, compareStartDate, compareEndDate] = getDatesByDay(29);

            break;
        case DatepickerShortcuts.Last3Months:
            [startDate, endDate, compareStartDate, compareEndDate] = getDatesByDay(90);

            break;
        case DatepickerShortcuts.Last12Months:
            [startDate, endDate, compareStartDate, compareEndDate] = getDatesByDay(365);

            break;
        default:
            break;
    }


    if (!startDate){
        setToastConfig((prev) => ({
            ...prev,
            trigger: true,
            toastText: "Couldn't select shortcut"
        }));
        logger.error("Couldn't select shortcut");

        return;
    }

    return {
        selection1: {
            startDate,
            endDate,
            key: 'selection1',
        },
        selection2: {
            startDate: compareStartDate,
            endDate: compareEndDate,
            key: 'selection2',
        },
    };
}

export default DatePickerCustomizeShortcuts;
