import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import DateInstance from 'services/date/date';
import { setRangeSelection } from 'store/date-picker/datePicker.actions';
import DatePickerCustomizeInput from '../input/DatePickerCustomizeInput';

function DatePickerCustomizeInputForm() {
    const selectionState         = useAppSelector(state => state.datePickerReducer.rangePickerSelection);
    const dispatch               = useAppDispatch();
    const [inputStart, inputEnd] = useMemo(() => [
        DateInstance.formatDate({ date: selectionState.selection1.startDate, format: 'dd.MM.yy' }),
        DateInstance.formatDate({ date: selectionState.selection1.endDate, format: 'dd.MM.yy' })
    ], [selectionState.selection1]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>, from: 'start' | 'end') => {
        const { value } = e.target;
        const format    = DateInstance.parseDate(value, 'dd.MM.yy', DateInstance.newDate());

        dispatch(
            setRangeSelection({
                ...selectionState,
                selection1: {
                    key: 'selection1',
                    startDate: from === 'start' ? format : selectionState.selection1.startDate,
                    endDate: from === 'end' ? format : selectionState.selection1.endDate,
                }
            })
        );
    };

    return (
        <div className="dp-current-date">
            <div className="dp-from">
                <p>From</p>
                <DatePickerCustomizeInput
                    id="dp-lg-start"
                    btnType="primary"
                    placeholder="dd.mm.yy"
                    value={inputStart}
                    onBlur={(e) => handleBlur(e, 'start')}
                />
            </div>
            <div className="dp-to">
                <p>To</p>
                <DatePickerCustomizeInput
                    id="dp-lg-end"
                    btnType="primary"
                    placeholder="dd.mm.yy"
                    value={inputEnd}
                    onBlur={(e) => handleBlur(e, 'end')}
                />
            </div>
        </div>
    );
}

export default DatePickerCustomizeInputForm;
