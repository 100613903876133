import { GBTAPIClient } from '@services/axios/clients/gbtAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { ActionProps, TableActionProps } from '@shared/interfaces/store/action/actionProps';
import { GTArticlesTable, GTBalancingScore, GTCompareArticles, GTCompareAuthors, GTOverallScore, GTSingleArticle, GTUniqueLanguages } from '@shared/interfaces/store/gender-tracker';

interface MuteArticleLinkGTReturnType {
    status: string;
    message: string;
}
export const muteArticleLinkGT = createAsyncThunkWithClient<MuteArticleLinkGTReturnType, string>({
    requestFn: (link, config) => GBTAPIClient.muteLinks(link, config),
    typePrefix: 'genderTracker/muteLink'
});

export interface CalculateBalancingScoreGTSig { text: string; author: string }
export const calculateBalancingScoreGT = createAsyncThunkWithClient<GTBalancingScore, CalculateBalancingScoreGTSig>({
    requestFn: (data, config) => GBTAPIClient.calculateGenderBalancingScore(data, config),
    typePrefix: 'genderTracker/muteLink'
});

export const fetchSingleArticleGT = createAsyncThunkWithClient<GTSingleArticle, string>({
    requestFn: (link, config) => GBTAPIClient.getSingleArticle(link, config),
    typePrefix: 'genderTracker/getSingleArticle'
});

export type FetchTopWorstArticlesGTSig = Pick<ActionProps, 'from'|'to'>;
export const fetchTopWorstArticlesGT = createAsyncThunkWithClient<GTCompareArticles, FetchTopWorstArticlesGTSig>({
    requestFn: (data, config) => GBTAPIClient.getTopWorstArticles(data, config),
    typePrefix: 'genderTracker/getTopWorstArticles',
});

export type FetchTopWorstAuthorsGTSig = Pick<ActionProps, 'from'|'to'>;
export const fetchTopWorstAuthorsGT = createAsyncThunkWithClient<GTCompareAuthors, FetchTopWorstAuthorsGTSig>({
    requestFn: (data, config) => GBTAPIClient.getTopWorstAuthors(data, config),
    typePrefix: 'genderTracker/getTopWorstAuthors',
});

export type FetchOverallScoreGTSig = Pick<ActionProps, 'from'|'to'|'compareFrom'|'compareTo'>;
export const fetchOverallScoreGT = createAsyncThunkWithClient<GTOverallScore, FetchOverallScoreGTSig>({
    requestFn: (link, config) => GBTAPIClient.getOverallScore(link, config),
    typePrefix: 'genderTracker/getOverallScore',
});

export type FetchUniqueLanguagesGTSig = Pick<ActionProps, 'from'|'to'>;
export const fetchUniqueLanguagesGT = createAsyncThunkWithClient<GTUniqueLanguages, FetchUniqueLanguagesGTSig>({
    requestFn: (domain, config) => GBTAPIClient.getUniqueLanguages(domain, config),
    typePrefix: 'genderTracker/getUniqueLanguages',
});

export type FetchArticlesTableGTSortKeys = 'Publication_date' | 'Gender_balanced_final_adjsuted_score';
export type FetchArticlesTableGTSig = Pick<TableActionProps<FetchArticlesTableGTSortKeys>, 'from' | 'to' | 'limit' | 'offset' | 'sort' | 'search'> & {
    Language?: string;
    order?: 'asc' | 'desc';
};
export const fetchArticlesTableGT = createAsyncThunkWithClient<GTArticlesTable, FetchArticlesTableGTSig>({
    requestFn: (data, config) => GBTAPIClient.getArticlesTable(data, config),
    typePrefix: 'genderTracker/getArticlesTable',
});
