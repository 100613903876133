import { Children, ReactNode } from 'react';

interface ShowProps {
    children: JSX.Element | JSX.Element[];
}

function Show({ children }: ShowProps) {
    let when      = null;
    let otherwise = null;

    Children.forEach(children, child => {
        if (child.props.isTrue === undefined) {
            otherwise = child;
        } else if (!when && child.props.isTrue === true) {
            when = child;
        }
    });

    return when || otherwise;
}

interface ShowWhenProps {
    isTrue: boolean | undefined;
    children: ReactNode;
}
Show.When = ({ isTrue, children }: ShowWhenProps) => isTrue && children;

interface ShowElseProps {
    render?;
    children?: ReactNode;
}
Show.Else = ({ render, children }: ShowElseProps) => render || children;

export default Show;
