import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const KeyboardArrowDownSmall = ({ className, style, ...rest }: BaseSvgComponentProps) => (
    <svg
        className={className}
        style={style}
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        id="arrow-down"
        {...rest}
    >
        <path
            d="M6.03433 9.09778C6.14861 9.09778 6.2629 9.04827 6.35433 8.94922L10.5143 4.91303C10.72 4.71493 10.7429 4.3435 10.56 4.12065C10.3772 3.89779 10.0343 3.87302 9.82862 4.07112L6.03433 7.78541L2.24004 4.07112C2.03433 3.87302 1.69147 3.87303 1.50861 4.12065C1.32576 4.36827 1.32576 4.71493 1.55433 4.91303L5.69147 8.94922C5.76004 9.04827 5.89719 9.09778 6.03433 9.09778Z"
            fill="black"
        />
    </svg>
);
