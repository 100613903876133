import React, { useEffect, useState } from 'react';
import packageJson from '../../package.json';

const REOAD_KEY = 'ia-reload-times';

const buildDateGreaterThan = (latestDate, currentDate) => {
    if (latestDate > currentDate) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

        useEffect(() => {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersionDate  = meta.buildDateTime;
                    const currentVersionDate = packageJson.buildDateTime;

                    const shouldForceRefresh = buildDateGreaterThan(
                        latestVersionDate,
                        currentVersionDate
                    );

                    if (shouldForceRefresh) {
                        setIsLatestBuildDate(false);
                        refreshCacheAndReload();
                    } else {
                        setIsLatestBuildDate(true);
                    }
                });
        }, []);

        const refreshCacheAndReload = () => {
            // prevent infinite reload
            const reloadTimes = Number(localStorage.getItem(REOAD_KEY) || 0);

            localStorage.setItem(REOAD_KEY, String(reloadTimes + 1));

            if (reloadTimes > 2) {
                setIsLatestBuildDate(true);

                return;
            }


            // clear caches
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }

            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} shouldRerender={true} /> : null}
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;
