import { AuthToken } from '@services/auth-token/authToken';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from 'config/config';
import { doesRequestDataHasErrors, handleCatchBlock } from 'services/error-handler/errorHandler';
import { queryBuilder } from 'services/url/url';
import { ActionError } from 'shared/enum/actionError';
import { isInstanceOfStoreDataStatus } from 'shared/enum/storeDataStatus';
import { APIError } from 'shared/interfaces/apiError';
import { BaseClient } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

class CCountriesAPIClient extends BaseClient {
    private constructor(enforcer) {
        super();

        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    initConfig(tokenHandler: AuthToken) {
        this.initializeConfig(api.countriesMicro, tokenHandler);
    }


    static get instance(): CCountriesAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CCountriesAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    handleRequestErrorChecking(response: AxiosResponse<any>) {
        const error      = doesRequestDataHasErrors(response.data);
        const dataStatus = isInstanceOfStoreDataStatus(error);

        if (!dataStatus && error){
            throw new Error(error);
        }

        return error;
    }

    handleRequestCatchBlock(error: unknown) {
        const result = handleCatchBlock(error, true);

        if (typeof result !== 'string') {
            const data = result?.data ?? {};

            return {
                data: null,
                error: {
                    ...data,
                    message: result?.error?.response?.status === 404 ? ActionError.AuthorNotFound : ''
                } as APIError,
                success: false
            };
        }

        return { data: null, error: result, success: false };
    }



    getCountriesChart<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        return this._session.get<AxiosReturn>('/views/topCountriesVisits', {
            ...this._config,
            ...config,
            params: options
        });
    }
    getTopSessionsByCountry<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        return this._session.get<AxiosReturn>(options.pageUrl ? '/views/topCountriesSessionsPageUrl' : '/views/topCountriesSessions', {
            ...this._config,
            ...config,
            params: options,
        });
    }
    getCountriesTopContent<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        return this._session.get<AxiosReturn>('/views/topCountriesMetrics', {
            ...this._config,
            ...config,
            params: options
        });
    }



    getHighlights<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const url      = queryBuilder('/views', options);

        return this._session.get<AxiosReturn>(url, {
            ...this._config,
            ...config,
        });
    }

    async getTopContent<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const url      = queryBuilder('/views/articles', options);

        return this._session.get<AxiosReturn>(url, {
            ...this._config,
            ...config,
        });
    }

    async getTopCategories<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const url      = queryBuilder('/views/topCategory', options);

        return this._session.get<AxiosReturn>(url, {
            ...this._config,
            ...config,
        });
    }

    async getDepthRate<Params extends Record<string, any> = any, AxiosReturn extends Record<string, any> = any>(
        options: Params,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<AxiosReturn>> {
        const url      = queryBuilder('/views/depthRate', options);

        return this._session.get<AxiosReturn>(url, {
            ...this._config,
            ...config,
        });
    }
}

export const CountriesAPIClient = CCountriesAPIClient.instance;
