import { AnyObject } from 'shared/types/anyObject';


interface FilterObjectPropertiesOptions<Obj> {
    object: Obj;
    properties: (keyof Obj)[];
    reverse?: boolean;
}

/**
 *
 * Filters a given object's properties and returns only the onces
 * provided in the `properties` array. However, if you chose to reverse the
 * process, consider setting `reverse: true`.
 *
 * @param {Object} options.object the object to filter.
 * @param {Array} options.properties properties to filter the object with.
 * @param {Boolean} options.reverse whether to reverse the process or not(meaning, instead of selecting properties, you'll remove them.).
 * @returns {Object} the new filtered object
 */
export const filterObjectProperties = <Obj extends Record<string, any> = AnyObject> ({
    object,
    properties,
    reverse,
}: FilterObjectPropertiesOptions<Obj>) => {
    const result = Object.fromEntries(
        Object.entries(object).filter(([k, v]) => {
            if (reverse) {
                if (!properties.includes(k)) {return v;}
            } else {
                if (properties.includes(k)) {return v;}
            }
        }),
    );

    type OmitReturn = Omit<Obj, keyof typeof properties>;
    type PickReturn = Extract<Obj, keyof typeof properties>;

    if (reverse) {
        return result as OmitReturn;
    }

    return result as PickReturn;
};

export const createFormData = (dataObject: Record<string, any>, keepNullValues = false) => {
    if (!Object.values(dataObject)?.length) {
        return;
    }

    const formData = new FormData();

    Object.entries(dataObject).map(
        ([key, value]) => {
            if (keepNullValues) {
                formData.append(key, value);

                return;
            }

            if (value != null) {
                formData.append(key, value);
            }
        },
    );

    return formData;
};

