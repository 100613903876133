import { useMemo, useRef, useState } from 'react';

import type { DatepickerRangeSelection } from 'shared/interfaces/datepicker/datepickerRangeSelection';

import DatePicker from '@components/widgets/date-picker/DatePicker';
import classNames from 'classnames';
import { Calendar } from 'components/svg/Calendar';
import { useAppSelector } from 'hooks/redux/useRedux';
import DateInstance from 'services/date/date';
import useAnimateCalendar from './useAnimateCalendar';

function PageFilterCalendar() {
    const [datePickerStatus, toggleDatePicker] = useState(false);
    const wrapperRef                           = useRef<HTMLDivElement>();
    const datePickerRef                        = useRef<HTMLDivElement>();
    const isDisabled                           = useAppSelector(state => state.datePickerReducer.disabled);

    useAnimateCalendar({ datePickerStatus, toggleDatePicker, wrapperRef, datePickerRef });

    const { dates: [startDate, endDate], dataIsLoading } = useAppSelector(state => ({
        dates: state.datePickerReducer.current,
        dataIsLoading: state.viewsReducer.audienceIsLoading,
    }));

    const dates = useMemo(() => ({
        from: DateInstance.formatDate({ date: DateInstance.newDate(startDate), format: 'dd.MM.yy' }),
        to: DateInstance.formatDate({ date: DateInstance.newDate(endDate), format: 'dd.MM.yy' }),
    }), [startDate, endDate]);

    const handleChange = (state: DatepickerRangeSelection) => null;

    return (
        <div className={classNames('filter-datepicker-wrapper', { disabled: isDisabled })} ref={wrapperRef}>
            <div
                className={classNames('filter-datepicker-btn', {
                    _disabled: dataIsLoading || isDisabled
                })}
                onClick={() => toggleDatePicker((prev) => !prev)}
            >
                <div className="svg-wrapper">
                    <Calendar />
                </div>
                <div>
                    {dates.from} - {dates.to}
                </div>
            </div>
            <div className="filter-datepicker" ref={datePickerRef}>
                <DatePicker
                    onClose={() => toggleDatePicker(false)}
                    onSave={handleChange}
                />
            </div>
        </div>
    );
}

export default PageFilterCalendar;
