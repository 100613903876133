import { InkyScrapperAPIClient } from '@services/axios/clients/inkyScrapperAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { ActionProps, TableActionProps } from '@shared/interfaces/store/action/actionProps';
import { AllDomains, AllMentions } from '@shared/interfaces/store/mentions';
import { Mentions } from '@shared/interfaces/store/mentions/mentions';
import { OverviewMentions } from '@shared/interfaces/store/mentions/overviewMentions';

export type FetchOverviewMentionsSig = Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'>;
export const fetchOverviewMentions = createAsyncThunkWithClient<OverviewMentions, FetchOverviewMentionsSig>({
    requestFn: (data, config) => InkyScrapperAPIClient.getOverviewMentions(data, config),
    typePrefix: 'mentions/getOverviewMentions'
});

export type FetchMentionsSig = Pick<ActionProps, 'from'|'to'|'compareFrom' |'compareTo'>;
export const fetchMentions = createAsyncThunkWithClient<Mentions, FetchMentionsSig>({
    requestFn: (data, config) => InkyScrapperAPIClient.getMentions(data, config),
    typePrefix: 'mentions/getMentions',
});

export type FetchAllMentionsSortKeys = 'publicationTitle' | 'lead' | 'domainName' | 'date' | 'mentionsTotal';
export type FetchAllMentionsSig = Pick<TableActionProps<FetchAllMentionsSortKeys>, 'from' | 'to' | 'limit' | 'offset' | 'order' | 'sort' | 'search'> & {
    domain?: string;
};
export const fetchAllMentions = createAsyncThunkWithClient<AllMentions, FetchAllMentionsSig>({
    requestFn: (data, config) => InkyScrapperAPIClient.getAllMentions(data, config),
    typePrefix: 'mentions/allMentions',
});

type DeleteMentionLink = string;
export const deleteMention = createAsyncThunkWithClient<any, DeleteMentionLink>({
    requestFn: (link, config) => InkyScrapperAPIClient.deleteMention(link, config),
    typePrefix: 'mentions/deleteOne',
});

type BlockMentionDomain = string;
export const blockMentionDomain = createAsyncThunkWithClient<any, BlockMentionDomain>({
    requestFn: (domain, config) => InkyScrapperAPIClient.blockMentionDomain(domain, config),
    typePrefix: 'mentions/blockDomain',
});

export type FetchAllDomainsSortKeys = 'domain' | 'count';
export type FetchAllDomainsSig = Pick<TableActionProps<FetchAllDomainsSortKeys>, 'from' | 'to' | 'limit' | 'offset' | 'order' | 'sort' | 'search'>;
export const fetchAllDomains = createAsyncThunkWithClient<AllDomains, FetchAllDomainsSig>({
    requestFn: (data, config) => InkyScrapperAPIClient.getAllDomains(data, config),
    typePrefix: 'mentions/allDomains',
});
