import { Each, Img } from '@components/common';
import { Trash } from '@components/svg';

import './SocialMediaProfileInProgress.scss';

interface Props {
    items: ItemProps[];
}

const SocialMediaProfileInProgress = ({ items }: Props) => (
    <div className='smpip-wrapper'>
        <Each of={items} render={(item) => (
            <Item {...item}/>
        )}/>
    </div>
);

interface ItemProps {
    icon: string;
    url: string;
    onDelete: () => void;
}

const Item = ({ icon, url, onDelete }: ItemProps) => (
    <div className="smpip-item">
        <Img src={icon} alt={url}/>
        <p>{url}</p>
        <Trash onClick={onDelete}/>
    </div>
);

export default SocialMediaProfileInProgress;
