import LoaderWrapper from '@components/widgets/loader/loader-wrapper/LoaderWrapper';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { sleep } from 'services/cache/cache';

export declare interface ImgProps {
    src: string;
    alt?: string;
    className?: string;

    width?: number;
    height?: number;

    role?: string;
    style?: React.CSSProperties;
    visibleByDefault?: boolean;

    shouldConvertImageToDataUrl?: boolean;
    loaderProps?: {
        wrapperStyle?: React.CSSProperties;
        nbDots?: number;
        width?: number;
        height?: number;
    };
}

export default function Img({
    src,
    alt,
    className,
    style,
    width,
    height,
    role,
    visibleByDefault = false,
    shouldConvertImageToDataUrl = false,
    loaderProps = {},
}: ImgProps) {
    const [imageUrl, setImageUrl]   = useState(!shouldConvertImageToDataUrl ? src : '');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!src) {
            return;
        }

        if (!shouldConvertImageToDataUrl) {
            setImageUrl(src);

            return;
        }

        setIsLoading(true);
        convertImageUrlToDataUrl(src, (uri) => {
            setImageUrl(uri);
            setIsLoading(false);
        });

        // if conversion fails, update loading state
        sleep(5000).then(() => {
            // setImageUrl(src);
            setIsLoading(false);
        });
    }, [src, shouldConvertImageToDataUrl]);

    return (
        isLoading ? <LoaderWrapper {...loaderProps} /> : (
            !isEmpty(imageUrl) && (
                <LazyLoadImage
                    className={className}
                    src={imageUrl}
                    alt={alt || 'image alt'}
                    id='my-image'

                    width={width}
                    height={height}

                    role={role}
                    style={style}

                    threshold={100}
                    placeholder={<span></span>}
                    // eslint-disable-next-line
                    // @ts-ignore
                    wrapperProps={{ identifier: 'lazy-image' }}
                    effect={'blur'}
                    visibleByDefault={visibleByDefault}
                />
            )
        )
    );
}

function convertImageUrlToDataUrl(
    imageSource: string,
    onFinish: (dataUri: string) => void
) {
    const img = new Image();
    let uri;

    img.crossOrigin = 'Anonymous';
    img.onload      = function () {
        const canvas = document.createElement('canvas');
        const ctx    = canvas.getContext('2d');

        canvas.height = img.naturalHeight;
        canvas.width  = img.naturalWidth;
        ctx.drawImage(img, 0, 0);

        // Unfortunately, we cannot keep the original image type, so all images will be converted to PNG
        // For this reason, we cannot get the original Base64 string
        uri = canvas.toDataURL('image/png');

        return onFinish?.(uri);
        // const b64 = uri.replace(/^data:image.+;base64,/, '');
    };

    img.src = imageSource;
}
