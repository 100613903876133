import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { LanguagesByPageView } from 'shared/interfaces/store/language/languagesByPageView';
import type { LanguagesPerDay } from 'shared/interfaces/store/language/languagesPerDay';
import type { LanguagesTable } from 'shared/interfaces/store/language/languagesTable';

import * as types from './languages.types';

export declare interface LanguagesState {
    languagesTable: LanguagesTable;
    languagesTableRequestSource: CancelTokenSource;
    languagesTableError: string;
    languagesTableIsLoading: boolean;

    languagesPerDay: LanguagesPerDay;
    languagesPerDayRequestSource: CancelTokenSource;
    languagesPerDayError: string;
    languagesPerDayIsLoading: boolean;

    languagesByPageView: LanguagesByPageView;
    languagesByPageViewRequestSource: CancelTokenSource;
    languagesByPageViewError: string;
    languagesByPageViewIsLoading: boolean;
}

const initialState: LanguagesState = {
    languagesTable: null,
    languagesTableRequestSource: null,
    languagesTableError: null,
    languagesTableIsLoading: false,

    languagesPerDay: null,
    languagesPerDayRequestSource: null,
    languagesPerDayError: null,
    languagesPerDayIsLoading: false,

    languagesByPageView: null,
    languagesByPageViewRequestSource: null,
    languagesByPageViewError: null,
    languagesByPageViewIsLoading: false,
};

const languagesReducer = function (state: LanguagesState = initialState, action: ReducerAction): LanguagesState {
    switch (action.type) {
        // languages table
        case types.LANGUAGES_GET_TABLE_IS_LOADING:{
            return {
                ...state,
                languagesTableIsLoading: true,
            };
        }

        case types.LANGUAGES_GET_TABLE_SUCCESS: {
            return {
                ...state,
                languagesTable: action.payload.data,
                languagesTableRequestSource: action.payload.requestSource,
                languagesTableError: null,
                languagesTableIsLoading: false,
            };
        }

        case types.LANGUAGES_GET_TABLE_FAILURE: {
            return {
                ...state,
                languagesTableError: action.payload.message,
                languagesTableIsLoading: false,
            };
        }

        // languages per day
        case types.LANGUAGES_GET_PER_DAY_IS_LOADING:{
            return {
                ...state,
                languagesPerDayIsLoading: true,
            };
        }

        case types.LANGUAGES_GET_PER_DAY_SUCCESS: {
            return {
                ...state,
                languagesPerDay: action.payload.data,
                languagesPerDayRequestSource: action.payload.requestSource,
                languagesPerDayError: null,
                languagesPerDayIsLoading: false,
            };
        }

        case types.LANGUAGES_GET_PER_DAY_FAILURE: {
            return {
                ...state,
                languagesPerDayError: action.payload.message,
                languagesPerDayIsLoading: false,
            };
        }

        // languages by page view
        case types.LANGUAGES_GET_BY_PAGE_VIEW_IS_LOADING:{
            return {
                ...state,
                languagesByPageViewIsLoading: true,
            };
        }

        case types.LANGUAGES_GET_BY_PAGE_VIEW_SUCCESS: {
            return {
                ...state,
                languagesByPageView: action.payload.data,
                languagesByPageViewRequestSource: action.payload.requestSource,
                languagesByPageViewError: null,
                languagesByPageViewIsLoading: false,
            };
        }

        case types.LANGUAGES_GET_BY_PAGE_VIEW_FAILURE: {
            return {
                ...state,
                languagesByPageViewError: action.payload.message,
                languagesByPageViewIsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default languagesReducer;
