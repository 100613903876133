interface Props {
    background?: string;
    barHeight?: string;
    width: string;
    percentageSize?: string;
    isRounded: boolean;
}

const ProgressBar = ({
    background,
    barHeight,
    width,
    percentageSize,
    isRounded,
}: Props) => (
    <div
        className="progress-container"
        style={{
            height: barHeight,
            borderRadius: isRounded ? 50 : 0
        }}>
        <div
            style={{
                width,
                background,
                height: '100%',
                fontSize: percentageSize,
                borderRadius: isRounded ? 50 : 0
            }}
            className="progress-reach"
            data-testid="progress-reach"
        ></div>
    </div>
);

ProgressBar.defaultProps = {
    background: '',
    barHeight: '10px',
    percentageSize: '',
};

export default ProgressBar;
