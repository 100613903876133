import type { ActionProps } from 'shared/interfaces/store/action/actionProps';

import { AuthAPIClient } from '@services/axios/clients/authAPIClient';
import { createAsyncThunkWithClient } from '@services/redux/createAsyncThunkWithClient';
import { ImpactScore } from '@shared/interfaces/store/impactScore';
import axios from 'services/axios/axios';
import { queryBuilder } from 'services/url/url';

export const fetchImpactScore = createAsyncThunkWithClient<ImpactScore, ActionProps>({
    requestFn: (params, config) => {
        const { category = null, ...rest } = params;
        const url                          = queryBuilder(
            category ? `impact/${category}` : 'impact',
            rest,
        );

        return axios.get<ImpactScore>(url, config);
    },
    typePrefix: 'impactScore/score'
});

export interface ImpactScoreMetrics {
    metrics: {
        name: string;
        coef: number;
        target: number;
        type: 'single' | 'site';
    }[];
}

export type UpdateImpactScoreMetricsReturnType = any;

export const updateImpactScoreMetrics = createAsyncThunkWithClient<UpdateImpactScoreMetricsReturnType, ImpactScoreMetrics>({
    requestFn: ({ metrics }, config) => AuthAPIClient.post<UpdateImpactScoreMetricsReturnType>('impact-score', {
        metrics
    }, config),
    typePrefix: 'impactScore/updateMetrics'
});

export const getImpactScoreMetrics = createAsyncThunkWithClient<ImpactScoreMetrics>({
    requestFn: (data, config) => AuthAPIClient.get<ImpactScoreMetrics>('impact-score', {
        ...config,
    }),
    typePrefix: 'impactScore/getMetrics'
});
