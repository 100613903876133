/* eslint-disable no-case-declarations */
import { AnyAction } from '@reduxjs/toolkit';
import { SET_MOBILE_SIDENAV_STATUS, SET_SIDENAV_STATUS } from './sideBar.type';

export declare interface SideBarState {
    hasSideNavStatusBeenReset?: boolean;
    sideNavStatus: boolean;
    mobileSideNavStatus?: boolean;
}

const initState: SideBarState = {
    hasSideNavStatusBeenReset: false,
    sideNavStatus: true,
    mobileSideNavStatus: false,
};

export default (state: SideBarState = initState, action: AnyAction): SideBarState => {
    switch (action.type) {
        case SET_SIDENAV_STATUS:
            const { hasSideNavStatusBeenReset, status } = action.payload;
            let sideNavStatus = {};

            if (action.payload.status !== 0) {

                sideNavStatus = {
                    sideNavStatus: status,
                    hasSideNavStatusBeenReset
                };
            }

            return {
                ...state,
                ...sideNavStatus,
            };
        case SET_MOBILE_SIDENAV_STATUS:
            let mobileSideNavStatus = {};

            if (action.payload !== 0) {
                mobileSideNavStatus = { mobileSideNavStatus: action.payload };
            }

            return {
                ...state,
                ...mobileSideNavStatus,
            };
        default:
            return state;
    }
};
