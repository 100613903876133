import { createFormData } from '@services/object/object';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import Config from 'config/config';
import { BaseClient } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;

class CAuthAPIClient extends BaseClient {
    private constructor(enforcer) {
        super();

        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._baseURL = api.authMicro;
            this._config  = {};
            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): CAuthAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CAuthAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    updateProfile<T>(
        { removeAvatar, ...data }: { firstname?: string; lastname?: string; avatar: File; removeAvatar: boolean },
        config?: AxiosRequestConfig
    ) {
        const formData = createFormData(data);

        return this._session.post<T>(`/users/profile?removeImage=${removeAvatar}`, formData, { ...this._config, ...config });
    }

    updatePassword<T>(
        { currentPassword, newPassword }: { currentPassword: string; newPassword: string },
        config?: AxiosRequestConfig
    ) {
        return this._session.post<T>('/users/password', {
            current_password: currentPassword,
            new_password: newPassword,
        }, { ...this._config, ...config });
    }
}

export const AuthAPIClient = CAuthAPIClient.instance;
