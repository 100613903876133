import { useMemo } from 'react';

import Img from '@components/common/img/Img';
import { isFloat } from '@services/validation/validation';
import { ComparisonColors } from 'constants/comparison';
import { getColorBase, getLogoBase } from 'services/chart/tooltip/tooltip';
import { symbolizeNumber } from 'services/conversion/conversion';
import './CompareStats.sass';

export declare interface CompareStatsProps {
    wrapperClassName?: string;
    isAugmentLogoReversed?: boolean;
    percentage: number;
    diff?: number;
    hideDiff?: boolean;
    pastPercentageOrValue?: number;
    percentageStyle?: React.CSSProperties;
    diffStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    iconHeight?: number;
    iconWidth?: number;
    wrapperStyle?: React.CSSProperties;
}

const CompareStats = ({
    wrapperClassName,
    isAugmentLogoReversed,
    percentage,
    pastPercentageOrValue,
    diff,
    hideDiff = false,
    percentageStyle,
    diffStyle,
    iconStyle,
    iconHeight,
    iconWidth,
    wrapperStyle
}: CompareStatsProps) => {
    /**
     * if `compare percentage` is 0
     *  - show increase logo with its color
     *  - show `-` in percentage
     *  - show difference with its color
     *
     * if `compare percentage - percentage = 0`
     *  - show `-` instead of increase/decrease logo
     *  - show `0%` for percentage and `0` for difference
     */
    const { augmentLogo, augmentColor, percent }  = useMemo(() => {
        const augmentResults = {
            augmentLogo: getLogoBase(isAugmentLogoReversed ? -percentage : percentage),
            augmentColor: getColorBase(isAugmentLogoReversed ? -percentage : percentage)
        };

        if (pastPercentageOrValue === 0){
            return {
                ...augmentResults,
                percent: <span style={{ color: ComparisonColors.gray, transform: 'translateY(1px)' }}>-</span>
            };
        }

        const upDownPercent       = percentage > 0 ? `+${percentage}%` : `${percentage}%`;

        return {
            ...augmentResults,
            percent: percentage ? upDownPercent : '0%'
        };
    }, [percentage]);

    const difference   = useMemo(() => {
        const result: string = isFloat(diff)
            ? String(Math.abs(diff))
            : symbolizeNumber(Math.abs(diff) as number, 2, { convertToComma: true, shouldRound: false, ignoreFirstSixDigits: false });

        return diff >= 0 ? `(+${result})` : `(-${result})`;
    }, [diff]);

    return (
        <div data-testid="csi-compare" className={`compare-stats-item ${wrapperClassName ? wrapperClassName : ''}`} style={wrapperStyle}>
            <div data-testid="csi-augment-icon-container" className="csi-icon">
                {
                    percentage === 0 ? (
                        <span  role="csi-icon-neutral" className="csi-icon-neutral" style={{ background: augmentColor }}>
                            <span>
                                -
                            </span>
                        </span>
                    ) : (
                        <Img
                            style={{
                                ...iconStyle,
                                transform: isAugmentLogoReversed ? 'rotate(180deg)' : ''
                            }}
                            className="csi-icon-badge"
                            height={iconHeight}
                            width={iconWidth}
                            src={augmentLogo}
                            role="csi-icon"
                        />
                    )
                }
            </div>
            <span
                data-testid="csi-percent"
                style={{
                    ...percentageStyle,
                    color: augmentColor,
                }}
            >
                {percent}
                {!hideDiff && diff != null ? <small data-testid="csi-diff" style={diffStyle}>{diff ? difference : '(0)'}</small> : null}
            </span>
        </div>
    );
};

export default CompareStats;
