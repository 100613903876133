import React from 'react';

import { Search } from '@components/svg/Search';
import { triggerFocusEvent } from '@services/event/event';

import './AppHeaderSearchInput.sass';

declare interface AppHeaderSearchInputProps {
    searchOn: boolean;
    handleChange: any; //React.FormEventHandler<HTMLInputElement>
    handleKeyUp: React.FormEventHandler<HTMLInputElement>;
    handleBlur?: React.FormEventHandler<HTMLInputElement>;
    handleFocus?: React.FormEventHandler<HTMLInputElement>;
    setSearch: (setter: boolean) => void;
}

const AppHeaderSearchInput = ({ searchOn, handleChange, handleKeyUp, handleBlur, handleFocus, setSearch }: AppHeaderSearchInputProps) => (
    <div className="app-header-search-input">
        <input
            data-testid="header-search"
            className={searchOn ? 'active' : ''}
            id="site-search"
            type="text"
            autoComplete="off"
            defaultValue={''}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onBlurCapture={(e) => handleBlur?.(e)}
            onFocusCapture={(e) => handleFocus?.(e)}
            onBlur={() => setSearch(false)}
            onFocus={() => setSearch(true)}
            placeholder="Search anything here..."
        />

        <Search
            className='header-search-icon'
            onClick={() => {
                const els: NodeListOf<HTMLElement> = document.querySelectorAll('.app-header-search-input>input');

                els.forEach(element => triggerFocusEvent(element));
            }}
        />
    </div>
);

export default AppHeaderSearchInput;
