import {
    addDays, format as formatDate, formatISO,
    isValid,
    isWithinInterval,
    parse, sub as subtractFrom
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { DateUnits } from 'shared/types/date/dateUnits';

// const TimeZone              = 'Africa/Tunis';
// const ClientTimeZone        = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const timezoneOffsetInHours = (new Date().getTimezoneOffset() / 60);

declare interface BaseDateNewDateOptions {
    convertDashToSlash?: boolean;
}


class BaseDate {
    private static _instance: BaseDate;

    static get Instance() {
        if (!BaseDate._instance) {
            BaseDate._instance = new BaseDate();
        }

        return BaseDate._instance;
    }

    /**
     * Create a new date
     * @param {Number|String|Date} date
     * @param {?Boolean} convertDashToSlash - defaults to true
     * @returns {Date}
     */
    newDate(date?: number | string | Date, { convertDashToSlash = true }: BaseDateNewDateOptions = {}) {
        let newDate = new Date();

        if (convertDashToSlash && typeof date === 'string' && date.includes('-')){
            newDate = new Date(date.split('-').join('/'));
        }

        return newDate;
    }

    /**
     * Parse a given date format to a specific format
     */
    parseDate: typeof parse = (dateString, formatString, referenceDate, options) => parse(dateString, formatString, referenceDate, {
        locale: enUS,
        ...options,
        useAdditionalDayOfYearTokens: false,
        useAdditionalWeekYearTokens: false,
    });

    /**
     * Format a given date to a specific format
     */
    formatDate = ({ date, format }: { date: number | Date; format: string }): ReturnType<typeof formatDate> => formatDate(date, format, {
        weekStartsOn: 0,
        locale: enUS
    });

    /**
     * Find if a date is in a given range
     */
    isDateInRange = (date: Date, range: [Date, Date]): ReturnType<typeof isWithinInterval> => {
        const [rangeStart, rangeEnd] = range;
        const isInRange              = isWithinInterval(date, {
            start: rangeStart,
            end: rangeEnd
        });

        return isInRange;
    };

    /**
     *
     * @param date date to validate
     * @param format date format
     * @returns {Boolean}
     */
    isValidDate(date: any, format = 'P'): boolean {
        const parsed =  this.parseDate(date, format, new Date(), { locale: enUS });

        return isValid(parsed);
    }

    /**
     * Get dates between two given dates
     */
    getDatesInBetween = (startDate: Date, stopDate: Date): string[] => {
        const dateArray: string[] = [];
        let currentDate           = startDate;

        while (currentDate <= stopDate) {
            dateArray.push(formatISO(currentDate, { representation: 'date' }));
            currentDate = addDays(currentDate, 1);
        }

        return dateArray;
    };

    /**
     * Subtract a given unit from a date and format it accordingly
     */
    subtractDate ({
        date,
        newFormat,
        subtractCount,
        subtractUnit
    }: {
        date: number | Date;
        newFormat?: string;
        subtractCount: number;
        subtractUnit: DateUnits;
    }) {
        const parsedDate = this.newDate(date);
        const newDate    = subtractFrom(parsedDate, {
            [subtractUnit]: subtractCount,
        });

        return newFormat ? DateInstance.formatDate({
            date: newDate,
            format: newFormat,
        }) : newDate;
    }

    /**
     * Add a given unit to a date and format it accordingly
     */
    addDate ({
        date,
        newFormat,
        addCount,
        addUnit,
    }: {
        date: number | Date;
        newFormat?: string;
        addCount: number;
        addUnit: DateUnits;
    }) {
        const parsedDate = this.newDate(date);
        const newDate    = subtractFrom(parsedDate, {
            [addUnit]: addCount,
        });

        return newFormat ? DateInstance.formatDate({
            date: newDate,
            format: newFormat,
        }) : newDate;
    }
}

const DateInstance = BaseDate.Instance;

export default DateInstance;
