/* eslint-disable @typescript-eslint/naming-convention */
import { TopCategoriesItem } from '@shared/interfaces/store/categories/topCategories';
import { CompareDeviceRecord } from '@shared/interfaces/store/depthRate';
import { FetchCountryDepthRateReturn, FetchCountryTopCategoriesReturnItem } from './country.thunk';

export function formatCountryDepthRate(data: FetchCountryDepthRateReturn): CompareDeviceRecord {
    if (!data) {
        return null;
    }

    return {
        0: {
            percentage: null,
            percentageDiff: data?.[0]?.diff || 0,
            total: null,
            current: {
                percentage: data?.[0]?.percentageOfVistorsByTotalVisitors || 0,
                total: data?.[0]?.visits || 0,
            },
            past: {
                percentage: null,
                total: null,
            },
        },
        25: {
            percentage: null,
            percentageDiff: data?.[25]?.diff || 0,
            total: null,
            current: {
                percentage: data?.[25]?.percentageOfVistorsByTotalVisitors || 0,
                total: data?.[25]?.visits || 0,
            },
            past: {
                percentage: null,
                total: null,
            },
        },
        50: {
            percentage: null,
            percentageDiff: data?.[50]?.diff || 0,
            total: null,
            current: {
                percentage: data?.[50]?.percentageOfVistorsByTotalVisitors || 0,
                total: data?.[50]?.visits || 0,
            },
            past: {
                percentage: null,
                total: null,
            },
        },
        75: {
            percentage: null,
            percentageDiff: data?.[75]?.diff || 0,
            total: null,
            current: {
                percentage: data?.[75]?.percentageOfVistorsByTotalVisitors || 0,
                total: data?.[75]?.visits || 0,
            },
            past: {
                percentage: null,
                total: null,
            },
        },
        100: {
            percentage: null,
            percentageDiff: data?.[100]?.diff || 0,
            total: null,
            current: {
                percentage: data?.[100]?.percentageOfVistorsByTotalVisitors || 0,
                total: data?.[100]?.visits || 0,
            },
            past: {
                percentage: null,
                total: null,
            },
        },
    };
}

export function formatCountryTopCategories(data: FetchCountryTopCategoriesReturnItem[]): TopCategoriesItem[] {
    if (!data || !data?.length) {
        return [];
    }

    const categories  = data.splice(0, 6); // get only top 6
    const totalVisits = categories.reduce((acc, next) => acc + Number(next.visits), 0);

    return categories.map((item) => ({
        name: item?.category ?? 'Unknown',
        value: item?.visits ?? 0,
        pastValue: null,
        percentage: (Number(item?.visits ?? 0) / totalVisits) * 100,
        compare: {
            diff: item?.compare?.diff || 0,
            percentage: item?.compare?.percentage || 0,
        }
    }));
}
