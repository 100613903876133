import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import facebookFilled from '@assets/icons/facebook-filled.png';
import facebook from '@assets/icons/facebook.png';

import instagramFilled from '@assets/icons/instagram-filled.png';
import instagram from 'assets/icons/instagram.png';

import tiktok from 'assets/icons/tiktok.png';
import twitter from 'assets/icons/twitter.png';
import youtube from 'assets/icons/youtube.png';

export const SocialMediaLogos = {
    [SocialMediaTypes.FACEBOOK]: {
        filled: facebookFilled,
        normal: facebook,
    },
    [SocialMediaTypes.INSTAGRAM]: {
        filled: instagramFilled,
        normal: instagram,
    },
    [SocialMediaTypes.YOUTUBE]: {
        filled: youtube,
        normal: youtube
    },
    [SocialMediaTypes.TIKTOK]: {
        filled: tiktok,
        normal: tiktok
    },
    [SocialMediaTypes.TWITTER]: {
        filled: twitter,
        normal: twitter
    },
};
