import Show from '@components/common/condition/Show';
import { UserDefaultAvatar } from '@components/common/headers/app-header/avatar/AppHeaderAvatar';
import Img from '@components/common/img/Img';
import { Trash } from '@components/svg/common';
import { AlertError } from '@components/widgets/alert';
import useGetCurrentUser from '@hooks/user/useGetCurrentUser';
import { useEffect, useRef, useState } from 'react';
import './ProfileSettingsEditAvatar.scss';

interface Props {
    firstName: string;
    lastName: string;
    avatar: File;
    setAvatar: React.Dispatch<React.SetStateAction<File>>;
    onUrlChange?: (url: string) => void;
    onDeleteAvatar: () => void;
}

export const ImageMimeType = /image\/(png|jpg|jpeg)/gm;

const ProfileSettingsEditAvatar = ({ firstName, lastName, avatar, setAvatar, onUrlChange, onDeleteAvatar }: Props) => {
    const user                          = useGetCurrentUser();
    const [fileDataURL, setFileDataURL] = useState(user?.avatar || null);
    const fileInput                     = useRef<HTMLInputElement>(null);
    const [error, setError]             = useState('');

    // use file reader to display image
    useEffect(() => {
        let fileReader;
        let isCancel = false;

        if (avatar) {
            fileReader        = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;

                if (result && !isCancel) {
                    setFileDataURL(result);
                    onUrlChange?.(result);
                }
            };

            fileReader.readAsDataURL(avatar);
        }

        return () => {
            isCancel = true;

            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [avatar]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files.length > 0) {
            const file = files?.[0];

            if (!file.type.match(ImageMimeType)) {
                setError('Wrong file type! Allowed file types: png, jpg and jpeg.');

                return;
            }

            setError('');
            setAvatar(file);
        }
    };

    const onDelete = () => {
        setFileDataURL(null);
        setAvatar(null);
        onDeleteAvatar();
    };

    return (
        <>
            <div className='ps-edit-avatar'>
                <Show>
                    <Show.When isTrue={!!fileDataURL}>
                        <Img src={fileDataURL} alt="profile thumbnail" />
                    </Show.When>
                    <Show.Else>
                        <UserDefaultAvatar
                            firstName={firstName}
                            lastName={lastName}
                        />
                    </Show.Else>
                </Show>

                <input ref={fileInput} onChange={onChange} type="file" accept="image/*" hidden />
                <button onClick={() => fileInput.current.click()} type="button" className="ps-ea-change">
                    Change picture
                </button>
                <button type="button" onClick={onDelete} className="ps-ea-remove">
                    <Trash/>
                    <p>Remove</p>
                </button>
            </div>

            <Show.When isTrue={!!error}>
                <AlertError style={{ marginTop: '1rem' }} message={error} />
            </Show.When>
        </>
    );
};

export default ProfileSettingsEditAvatar;
