import { useAppSelector } from 'hooks/redux/useRedux';
import { isEmpty } from 'lodash';

export default function useGetUserSelectedSite() {
    const selectedSite = useAppSelector((state) => {
        if (isEmpty(state.authenticationReducer.user)) {
            return null;
        }

        const { site } = state.authenticationReducer.user;

        return {
            ...site,
            clientId: (site?.name === 'dev' ? 'inkyfada' : site?.name) ?? 'inkyfada'
        };
    });

    return selectedSite;
}
