
import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import {
    type SocialMediaSingleHighlight,
    type SocialMediaSinglePostActivity,
    type SocialMediaSinglePostFormat,
    type SocialMediaSinglePostsList,
    type SocialMediaSingleTopAndWeakPosts
} from '@shared/interfaces/social-media';
import {
    type SocialMediaSingleActiveProfiles,
    type SocialMediaSingleEngagement,
    type SocialMediaSingleInteraction,
    type SocialMediaSingleInteractionByPost,
    type SocialMediaSingleReach
} from '@shared/interfaces/social-media/socialMediaSingle';

import { DataStatus } from '@shared/enum/dataStatus';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { DatePickerItem } from '@shared/interfaces/store/datePicker';

import { RootState } from '@store/storeConfig';
import { formatSingleInteraction, formatSinglePostActivity } from './SocialMediaSingle.formatter';
import { fetchSocialMediaSingle, fetchSocialMediaSingleActiveProfiles } from './SocialMediaSingle.thunk';

export declare interface SocialMediaOverviewState {
    activeProfiles: SocialMediaSingleActiveProfiles;
    activeProfilesStatus: DataStatus;
    activeProfilesError: string | null;

    networkType: SocialMediaTypes;
    profileName: string;
    url: string;
    followers: SocialMediaSingleHighlight;
    likes: SocialMediaSingleHighlight;
    posts: SocialMediaSingleHighlight;
    postInteraction: SocialMediaSingleHighlight;
    postPerDay: SocialMediaSingleHighlight;
    engagementRate: SocialMediaSingleHighlight;
    followerGrowth: SocialMediaSingleHighlight;
    postFormat: SocialMediaSinglePostFormat;
    postActivity: SocialMediaSinglePostActivity;
    interaction: SocialMediaSingleInteraction;
    interactionByPost: SocialMediaSingleInteractionByPost;
    topAndWeakPosts: SocialMediaSingleTopAndWeakPosts;
    postList: SocialMediaSinglePostsList;
    engagement: SocialMediaSingleEngagement;
    reach: SocialMediaSingleReach;

    status: DataStatus;
    error: string | null;
}

const convertHighlightToNumber = (highlight: {
    total: string;
    percentage: string;
    difference: string;
    sparklines?: string[];
}): SocialMediaSingleHighlight => {
    const chart = highlight?.sparklines?.length ? {
        chart: highlight.sparklines.map((value) => Number(value))
    } : { chart: [] };

    return {
        total: Number(highlight?.total ?? 0),
        pastTotal: null,
        percentage: Number(highlight?.percentage ?? 0),
        difference: Number(highlight?.difference ?? 0),
        ...chart
    };
};

const initialHighlight                       = {
    total: 0,
    pastTotal: null,
    percentage: 0,
    difference: 0
};
const initialPostFormat                      = {
    total: 0,
};
const initialState: SocialMediaOverviewState = {
    activeProfiles: [],
    activeProfilesStatus: DataStatus.IDLE,
    activeProfilesError: null,

    networkType: SocialMediaTypes.UNKNOWN,
    profileName: '',
    url: '',
    followers: initialHighlight,
    likes: initialHighlight,
    posts: initialHighlight,
    postInteraction: {
        ...initialHighlight,
        chart: [],
    },
    interactionByPost: null,
    postPerDay: {
        ...initialHighlight,
        chart: [],
    },
    engagementRate: {
        ...initialHighlight,
        chart: [],
    },
    followerGrowth: {
        ...initialHighlight,
        chart: [],
    },
    postFormat: initialPostFormat,
    postActivity: null,
    interaction: null,
    topAndWeakPosts: {
        top: [],
        weak: []
    },
    postList: [],
    engagement: null,
    reach: null,

    error: null,
    status: DataStatus.IDLE,
};

export const socialMediaSingleSlice = createSlice({
    name: 'socialMediaSingle',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            // all single
            .addCase(fetchSocialMediaSingle.pending, (state) => {
                state.status = DataStatus.LOADING;
                state.error  = null;
            })
            .addCase(fetchSocialMediaSingle.fulfilled, (state, action) => {
                state.networkType     = action.payload.networkType ?? SocialMediaTypes.UNKNOWN;
                state.profileName     = action.payload.profileName ?? 'N/A';
                state.url             = action.payload.url ?? '';
                state.followers       = convertHighlightToNumber(action.payload?.followers);
                state.likes           = convertHighlightToNumber(action.payload?.likes);
                state.posts           = convertHighlightToNumber(action.payload?.posts);
                state.postInteraction = convertHighlightToNumber(action.payload?.postInteraction);
                state.postPerDay      = convertHighlightToNumber(action.payload?.postPerDay);
                state.engagementRate  = convertHighlightToNumber(action.payload?.engagementRate);
                state.followerGrowth  = convertHighlightToNumber(action.payload?.followerGrowth);
                state.postFormat      = isEmpty(action.payload?.postFormat) ? initialPostFormat : {
                    total: Number(action.payload.postFormat?.total) ?? 0,
                    ...action.payload.postFormat,
                };

                const dates     = [action.meta.arg.data.start_date, action.meta.arg.data.end_date] as DatePickerItem;
                const pastDates = [action.meta.arg.data.compareFrom, action.meta.arg.data.compareTo] as DatePickerItem;

                state.postActivity      = formatSinglePostActivity(action.payload?.postActivity, dates, pastDates);
                state.interaction       = formatSingleInteraction(action.payload?.interaction, dates, pastDates);
                state.interactionByPost = action.payload?.interactionByPost ?? null;
                state.topAndWeakPosts   = action.payload.topAndWeakPosts ?? initialState.topAndWeakPosts;
                state.postList          = action.payload.postList ?? [];
                state.engagement        = action.payload.engagement ?? null;
                state.reach             = action.payload.reach ?? null;
                state.status            = DataStatus.SUCCESS;
                state.error             = null;
            })
            .addCase(fetchSocialMediaSingle.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.status = DataStatus.IDLE;

                    return;
                }

                state.error  = action.payload as unknown as string;
                state.status = DataStatus.FAILED;
            })
            // active profiles
            .addCase(fetchSocialMediaSingleActiveProfiles.pending, (state) => {
                state.activeProfilesStatus = DataStatus.LOADING;
                state.activeProfilesError  = null;
            })
            .addCase(fetchSocialMediaSingleActiveProfiles.fulfilled, (state, action) => {
                state.activeProfiles       = action.payload;
                state.activeProfilesStatus = DataStatus.SUCCESS;
                state.activeProfilesError  = null;
            })
            .addCase(fetchSocialMediaSingleActiveProfiles.rejected, (state, action) => {
                if (String(action.payload) === 'Cancel') {
                    state.status = DataStatus.IDLE;

                    return;
                }

                state.activeProfilesError  = action.payload as unknown as string;
                state.activeProfilesStatus = DataStatus.FAILED;
            });
    },
});

export const {
    clearState
} = socialMediaSingleSlice.actions;
export const selectFromSocialMediaSingle = {
    activeProfiles: (state: RootState) => ({
        activeProfiles: state.socialMediaSingleReducer.activeProfiles,
        status: state.socialMediaSingleReducer.activeProfilesStatus,
        error: state.socialMediaSingleReducer.activeProfilesError,
    }),
    all: (state: RootState) => state.socialMediaSingleReducer,
    info: (state: RootState) => ({
        networkType: state.socialMediaSingleReducer.networkType,
        profileName: state.socialMediaSingleReducer.profileName,
        url: state.socialMediaSingleReducer.url,
    }),
    status: (state: RootState) => state.socialMediaSingleReducer.status,
    error: (state: RootState) => state.socialMediaSingleReducer.error,
    followers: (state: RootState) => state.socialMediaSingleReducer.followers,
    likes: (state: RootState) => state.socialMediaSingleReducer.likes,
    posts: (state: RootState) => state.socialMediaSingleReducer.posts,
    postInteraction: (state: RootState) => state.socialMediaSingleReducer.postInteraction,
    postPerDay: (state: RootState) => state.socialMediaSingleReducer.postPerDay,
    engagementRate: (state: RootState) => state.socialMediaSingleReducer.engagementRate,
    followerGrowth: (state: RootState) => state.socialMediaSingleReducer.followerGrowth,
    postFormat: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postFormat,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    postActivity: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postActivity,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    interaction: (state: RootState) => ({
        data: state.socialMediaSingleReducer.interaction,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    interactionByPost: (state: RootState) => ({
        data: state.socialMediaSingleReducer.interactionByPost,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    topAndWeakPosts: (state: RootState) => ({
        data: state.socialMediaSingleReducer.topAndWeakPosts,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    postList: (state: RootState) => ({
        data: state.socialMediaSingleReducer.postList,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    engagement: (state: RootState) => ({
        data: state.socialMediaSingleReducer.engagement,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
    reach: (state: RootState) => ({
        data: state.socialMediaSingleReducer.reach,
        status: state.socialMediaSingleReducer.status,
        error: state.socialMediaSingleReducer.error,
    }),
};

const socialMediaSingleReducer = socialMediaSingleSlice.reducer;

export default socialMediaSingleReducer;
